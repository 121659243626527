import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { IconLockCancel } from "@tabler/icons-react";
import { IconEye } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { IconLoaderQuarter } from "@tabler/icons-react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";

import { IconCopy } from "@tabler/icons-react";


function Home() {

    const copy = async (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Address copied");
    };

    const navigate = useNavigate();
    const [Merchantdetails, SetMerchantdetails] = useState([]);
    const [buttonLoader, setbuttonLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [currentpage, Setcurrentpage] = useState(1);
    const [totalpage, Settotalpage] = useState(0);
    useEffect(() => {
        getmerchantdetails();
    }, [0]);

    const recordpage = 5;
    const pagerange = 5;
    const historychanges = (pagenumber) => {
        getmerchantdetails(pagenumber);
        Setcurrentpage(pagenumber);
    };
    const getmerchantdetails = async (page) => {
        try {
            setLoader(true);
            var data = {
                apiUrl: apiService.Invoicehistory,
                payload: {
                    FilPerpage: 5,
                    FilPage: page,
                },
            };
            var resp = await postMethod(data);
            console.log("getmerchantdetails===", resp);


            if (resp) {
                setLoader(false);
                console.log(resp.obj, "=-=-respobj-=-=");
                console.log(resp, "-=-=-resp=-=-=-resp==-resp");
                SetMerchantdetails(resp.obj);
                Settotalpage(resp.total);
            } else {
                console.log(resp, "-=-=-resp=-=-=-resp==-resp");
            }
        } catch (error) {
            console.log(error, "=-=error=-=-=");
        }
    };



    return (
        <div className="header">
            <Header />
            <div className="css-1wr4jig">
                <div className="css-1o43t2y">
                    <div className="css-1t8cbiy">
                        <Sidebar />
                    </div>
                    <div className="css-1h690ep">
                        <main className="main_heinght">
                            {loader == true ? (
                                <div class="spinner css-ezm1fk">
                                    <div class="css-1qoumk2"></div>
                                    <div class="css-1v19680"></div>
                                    <div class="css-1v90jie"></div>
                                    <div class="css-19p0rvp"></div>
                                </div>
                            ) : (
                                <>
                                    <section className="deposut_section">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div class="col-lg-12 d-flex justify-content-between align-items-center pt-3">
                                                    <div className="headin_deposur_s">
                                                        <h3>Invoice History</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="tavle_transactio">
                                        <div className="padding_section">
                                            <div className="container-fluid">
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12 shadow">
                                                        <div className="market_tabel1 w-100 mt-0 px-3">
                                                            <div className="market_section dashcard1 p-3 bg_none">
                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                {/* <th>S.NO</th> */}
                                                                                <th className="leftborder">Date & Time</th>
                                                                                <th>Project Name</th>
                                                                                <th>Customer Name</th>
                                                                                <th>Price</th>
                                                                                <th className="rightborder">Transaction ID</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Merchantdetails &&
                                                                                Merchantdetails.length > 0 ? (
                                                                                Merchantdetails.map((item, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            <tr>

                                                                                                <td className="td_amount">
                                                                                                    {" "}
                                                                                                    <p>
                                                                                                        {moment(
                                                                                                            item.createdAt
                                                                                                        ).format("lll")}
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td className="td_amount">
                                                                                                    {" "}
                                                                                                    <p>{item.Product}</p>
                                                                                                </td>

                                                                                                <td className="td_amount">
                                                                                                    {" "}
                                                                                                    <p className="hash_code">{item.customer}</p>
                                                                                                </td>
                                                                                                <td className="td_amount">
                                                                                                    {" "}
                                                                                                    <p>
                                                                                                        {item.amount} {" "} {item.currency}
                                                                                                    </p>
                                                                                                </td>

                                                                                                <td className="td_amount d-flex">
                                                                                                    <p className="hash_code">{item.Hashid}</p>
                                                                                                    <IconCopy onClick={() => copy(item.Hashid)} className="ml-3" />
                                                                                                </td>


                                                                                            </tr>
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            ) : (
                                                                                <tr>
                                                                                    <td
                                                                                        className="text-center"
                                                                                        colSpan="5"
                                                                                    >
                                                                                        <img src={require('../Image/nodata.png')} className="mt-5" />
                                                                                        <p className="mb-5 mt-3 nodatafound">No data Found</p>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                                {Merchantdetails.length > 0 ? (
                                                                    <Pagination
                                                                        itemClass="page-item"
                                                                        linkClass="page-link"
                                                                        activePage={currentpage}
                                                                        itemsCountPerPage={recordpage}
                                                                        totalItemsCount={totalpage}
                                                                        pageRangeDisplayed={pagerange}
                                                                        onChange={historychanges}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            )}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
