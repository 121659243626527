import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [buttonLoader1, setbuttonLoader1] = useState(false);

  const navigate = useNavigate();
  const { otp } = formValue;

  const formSubmit = async (payload) => {
    if (formValue.otp != "") {
      var obj = {
        emailOtp: otp,
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.emailotpverify,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setFormValue(initialFormValue);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const resendOTP = async (payload) => {
    var obj = {
      email: localStorage.getItem("useremail"),
    };

    var data = {
      apiUrl: apiService.resendCode,
      payload: obj,
    };
    setbuttonLoader1(true);
    var resp = await postMethod(data);
    setbuttonLoader1(false);
    if (resp.status) {
      toast.success(resp.Message);
    } else {
      toast.error(resp.Message);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    if (value.length < 5) {
      let formData = { ...formValue, ...{ [name]: value } };
      setFormValue(formData);
    }

  };
  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form h-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card_login shadow">
                  <h3>Enter OTP To Activate the account</h3>
                  <div>
                    <div class="form_login_input">
                      <label>Email OTP</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter OTP"
                        name="otp"
                        min={1000}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(
                            evt.key
                          ) && evt.preventDefault()
                        }
                        value={otp}
                        onChange={handleChange}
                      />
                    </div>

                    <div class="form_login_input mt-3">
                      {buttonLoader == false ? (
                        <Button
                          onClick={formSubmit}
                        >
                          Continue
                        </Button>
                      ) : (
                        <Button>
                          Loading...
                        </Button>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <p className="bottom_linnk my-4">
                        Did not receive the email? Check your spam or filter
                      </p>


                    </div>

                    <div class="form_login_input">

                      {buttonLoader1 == false ? (
                        <Button onClick={resendOTP}>Resend Code</Button>
                      ) : (
                        <Button>Loading...</Button>
                      )}

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
