import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import Select from "react-select";
import Sidebar from "./Sidebarlink";
import { Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { IconTrash, IconEye } from "@tabler/icons-react";
import { IconLoaderQuarter } from "@tabler/icons-react";
import { env } from ".././core/service/envconfig";
import { PhoneInput } from "react-contact-number-input";

const ShortUniqueId = require("short-unique-id");

function Invoice() {
  const navigate = useNavigate();

  const options = [
    { key: "ERC-20", value: "ERC-20", text: "ERC-20" },
    { key: "BEP-20", value: "BEP-20", text: "BEP-20" },
  ];

  const initialFormValue = {
    email: "",
    customerName: "",
    amount: "",
    memo: "",
    mobile: "",
    address: "",
    itemname: "",
    qty: "",
    due: "",
  };

  const [loader, setLoader] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationnErr, setvalidationnErr] = useState("");
  const [emailErr, setEmailErr, setEmailErrref] = useState(false);
  const [customerNameErr, setcustomerNameErr, setcustomerNameErrref] =
    useState(false);
  const [mobileErr, setmobileErr, setmobileErrref] = useState(false);
  const [addressErr, setaddressErr, setaddressErrref] = useState(false);
  const [itemnameErr, setitemnameErr, setitemnameErrref] = useState(false);
  const [qtyErr, setqtyErr, setqtyErrref] = useState(false);
  const [dueErr, setdueErr, setdueErrref] = useState(false);
  const [paymentErr, setpaymentErr, setpaymentErrref] = useState(false);
  const [amountErr, setamountErr, setamountErrref] = useState(false);
  const [memoErr, setmemoErr, setmemoErrref] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [allercCurrency, setallercCurrency, allercCurrencyref] = useState([]);
  const [allbepCurrency, setallbepCurrency, allbepCurrencyref] = useState([]);
  const [currency, setCurrency] = useState("");
  const [paymentmethod, setpaymentmethod, paymentmethodref] = useState("");
  const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
  const [link, setLink] = useState("");
  const [invoiceList, setinvoiceList] = useState([]);
  const [currentpage, Setcurrentpage] = useState(1);
  const [totalpage, Settotalpage] = useState(0);
  const [symbol, Setsymbol, symbolref] = useState("");
  const [mobileInput, setmobileInput] = useState("");

  const {
    email,
    customerName,
    amount,
    memo,
    mobile,
    address,
    itemname,
    qty,
    due,
  } = formValue;

  useEffect(() => {
    getAllcurrency();
    getInvoice();
  }, []);

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;

    if (name == "email") {
      console.log(name, "name1", value, "value");

      const sanitizedValue = value.replace(/\s/g, "");
      let Data = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(Data);
      vallidate(Data);
      // } else if (name == "customerName") {
      //   console.log(name, "name1", value, "value");

      //   if (value.startsWith(' ')) {

      //     const trimvalue = value.trimLeft();
      //     let Data = { ...formValue, ...{ [name]: trimvalue } };
      //     setFormValue(Data);
      //     vallidate(Data);
      //   }

    } else if (name == "mobile") {
      const sanitizedValue1 = value.replace(/\s/g, "");
      if (sanitizedValue1.length < 11) {
        let Data = { ...formValue, ...{ [name]: sanitizedValue1 } };
        setFormValue(Data);
        vallidate(Data);
      }
    } else if (name == "qty" || name == "amount") {
      const sanitizedValue1 = value.replace(/\s/g, "");
      if (sanitizedValue1.length < 6) {
        let Data = { ...formValue, ...{ [name]: sanitizedValue1 } };
        setFormValue(Data);
        vallidate(Data);
      }
    } else if (name == "due") {

      // Calculate the current date and the date one year from now
      const today = new Date();
      const oneYearFromToday = new Date();
      oneYearFromToday.setFullYear(today.getFullYear() + 1);

      // Convert dates to ISO format for comparison
      const todayISO = today.toISOString().split("T")[0];
      const oneYearFromTodayISO = oneYearFromToday.toISOString().split("T")[0];

      if (value < todayISO || value > oneYearFromTodayISO) {
        // Invalid date
        setdueErr(true);
        setvalidationnErr({ due: "Due Date must be within one year from today" });
      } else {
        // Valid date
        setdueErr(false);
        const sanitizedValue1 = value.replace(/\s/g, "");

        let Data = { ...formValue, ...{ [name]: sanitizedValue1 } };

        setFormValue(Data);
        vallidate(Data);
      }



    } else if (value.startsWith(" ")) {
      let sanitizedValue1 = value.trimLeft();
      let formData = { ...formValue, ...{ [name]: sanitizedValue1 } };
      setFormValue(formData);
      vallidate(formData);
    } else {
      console.log(name, "name2", value, "value");

      let Data = { ...formValue, ...{ [name]: value } };
      setFormValue(Data);
      vallidate(Data);
    }
  };

  const vallidate = (values) => {
    try {
      const errors = {};

      if (!values.customerName) {
        errors.customerName = "Customer Name is a required field ";
        setcustomerNameErr(true);
      } else if (!/^[a-zA-Z]/.test(values.customerName)) {
        errors.customerName = "Customer Name must start with a letter ";
        setcustomerNameErr(true);
      } else if (
        values.customerName.length < 3 ||
        values.customerName.length > 25
      ) {
        errors.customerName =
          "Customer Name must have an  3 to 25 characters ";
        setcustomerNameErr(true);
      } else if (!/^[a-zA-Z0-9_\s]+$/.test(values.customerName)) {
        errors.customerName =
          "CustomerName can only contain letters, numbers, and underscores ";
        setcustomerNameErr(true);
      } else if (!values.email) {
        setcustomerNameErr(false);
        errors.email = "Email is a required field ";
        setEmailErr(true);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address ";
        setEmailErr(true);
      } else if (!values.mobile.trim()) {
        setEmailErr(false);
        errors.mobile = "Mobile is required";
        setmobileErr(true);
        // } else if (values.mobile < 11) {
        //   errors.mobile = "Please enter a 10-digit number";
        //   setmobileErr(true);
      } else if (values.address == "") {
        setmobileErr(false);
        errors.address = "Address is required ";
        setaddressErr(true);
      } else if (values.address.length < 5 || values.address.length > 50) {
        errors.address = "Address should not below 5 above 50 letters ";
        setaddressErr(true);
      } else if (!/^[a-zA-Z0-9_\s]+$/.test(values.address)) {
        errors.address =
          "Address can only contain letters, numbers, and underscores ";
        setaddressErr(true);
      } else if (values.due == "") {
        setaddressErr(false);
        errors.due = "Due Date is required ";
        setdueErr(true);
      } else if (values.itemname == "") {
        setdueErr(false);
        errors.itemname = "Item name is required ";
        setitemnameErr(true);
      }else if (!/^[a-zA-Z0-9_\s]+$/.test(values.itemname) || !/[a-zA-Z]/.test(values.itemname)) {
        errors.itemname = "Invalid Item name.";
        setitemnameErr(true);
      }
       else if (values.itemname.length < 3 || values.itemname.length > 15) {
        errors.itemname =
          "Item name Number should not below 3 above 15 letters ";
        setitemnameErr(true);
      } else if (values.qty == "") {
        setitemnameErr(false);
        errors.qty = "Quantity Date is required ";
        setqtyErr(true);
      } else if (values.amount == "") {
        setqtyErr(false);

        errors.amount = "Amount is required ";
        setamountErr(true);
      } else if (values.memo == "") {
        setamountErr(false);

        errors.memo = "Memo is required ";
        setmemoErr(true);
      } else if (values.memo.length < 5 || values.memo.length > 150) {
        errors.memo = "Memo Number should not below 5 above 150 letters ";
        setmemoErr(true);
      } else if (!/^[a-zA-Z0-9_\s]+$/.test(values.memo)) {
        errors.memo =
          "Memo can only contain letters, numbers, and underscores ";
        setmemoErr(true);
      } else {
        setmobileErr(false);
        setdueErr(false);
        setEmailErr(false);
        setmemoErr(false);
        setitemnameErr(false);
        setqtyErr(false);
        setcustomerNameErr(false);
        setaddressErr(false);
        setamountErr(false);

      }
      setvalidationnErr(errors);
    } catch (error) { }
  };

  const handleOnChange = (type) => {
    console.log(type, "=-=-=-=-=");
    if (type.countryCode != null && type.phoneNumber != null) {
      var number = type.countryCode + " " + type.phoneNumber;
      setmobileInput(number);
      setdueErr(true);
      setvalidationnErr({ mobile: type.message });
      if (type.hasError == false) {
        setdueErr(false);
        formValue["mobile"] = mobileInput;
      }
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.getCurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCryptoerc = [];
      var currArrayCryptobep = [];

      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].erc20token == 1) {
          var obj = {
            value: data[i]._id,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].Currency_image,
            },
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
          };
          currArrayCryptoerc.push(obj);
        } else {
        }

        if (data[i].bep20token == 1) {
          var obj = {
            value: data[i]._id,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].Currency_image,
            },
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
          };
          currArrayCryptobep.push(obj);
        }
      }
      setallercCurrency(currArrayCryptoerc);
      setallbepCurrency(currArrayCryptobep);
    }
  };

  const recordpage = 5;
  const pagerange = 5;
  const historychanges = (pagenumber) => {
    getInvoice(pagenumber);
    Setcurrentpage(pagenumber);
  };
  const getInvoice = async (page) => {
    var data = {
      apiUrl: apiService.getInvoice,
      payload: {
        FilPerpage: 5,
        FilPage: page,
      },
    };

    var resp = await postMethod(data);
    if (resp.status == true) {
      console.log(resp.data.data, "INvoice data");
      setinvoiceList(resp.data.data);
      Settotalpage(resp.data.total);
    } else {
      toast.error(resp.message);
    }
  };

  const submit = async () => {
    vallidate(formValue);
    var code = await new ShortUniqueId({ length: 8 });
    var uid = code();
    console.log(uid);
    var Link = `${env.BaseURL}/invoicecharge/${uid}`;
    setLink(Link);
    formValue["currency"] = "USD";
    formValue["link"] = Link;
    formValue["invoiceid"] = uid;
    formValue["payment"] = paymentmethodref.current;
    formValue["Totalamount"] = formValue.qty * formValue.amount;

    console.log(formValue, "formValue");

    console.log(setEmailErrref.current,
      setcustomerNameErrref.current,
      setamountErrref.current,
      setmemoErrref.current,
      setmobileErrref.current,
      setaddressErrref.current,
      setdueErrref.current,
      setitemnameErrref.current,
      setqtyErrref.current)
    if (
      setEmailErrref.current == false &&
      setcustomerNameErrref.current == false &&
      setamountErrref.current == false &&
      setmemoErrref.current == false &&
      setmobileErrref.current == false &&
      setaddressErrref.current == false &&
      setdueErrref.current == false &&
      setitemnameErrref.current == false &&
      setqtyErrref.current == false
    ) {
      console.log(formValue, "formValue");
      var data = {
        apiUrl: apiService.invoice_create,
        payload: formValue,
      };
      var resp = await postMethod(data);
      console.log(resp, "resp-==-=resp");
      setFormValue(initialFormValue);
      if (resp.status == true) {
        toast.success(resp.message);
        navigate('/invoice');
        getInvoice();
      } else {
        toast.error(resp.message);
      }
    } else {
      console.log("require All fields");
    }
  };

  const deleteinvoice = async (deleteData) => {
    console.log(deleteData, "deleteData");
    try {
      var data = {
        id: deleteData,
      };
      var data = {
        apiUrl: apiService.deleteinvoice,
        payload: data,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        getInvoice();
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <div>
                    <section className="form-deposit">
                      <div className="section_selec_ash row">
                        <div className="col-lg-12 p-3 ">
                          <div className="headin_deposur_s ">
                            <h3>
                              <i
                                class="ri-arrow-left-line cusonr"
                                onClick={() => navigate("/invoice")}
                              ></i>
                              <span className="pl-3">Create an invoice</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row justify-content-center ">
                          <div className="col-lg-12 card_login mr17 shadow">
                            <div className="qr_section_class">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label className="">Customer Name</label>

                                    <div className="">
                                      <input
                                        type="text"
                                        placeholder="Customer Name"
                                        name="customerName"
                                        class="form-control"
                                        minLength={3}
                                        maxLength={25}
                                        value={customerName}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {setcustomerNameErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.customerName}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label>Email</label>

                                    <div className="">
                                      <input
                                        type="text"
                                        placeholder=" Email"
                                        maxLength={50}
                                        name="email"
                                        class="form-control"
                                        value={email}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {setEmailErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.email}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label className="">Mobile Number</label>

                                    <div className="phoneinput">
                                      <PhoneInput
                                        class="mobileInput"
                                        onChange={handleOnChange}
                                      />

                                    </div>


                                    {/* <div className="">
                                      <input
                                        class="form-control"
                                        type="number"
                                        placeholder="Mobile Number"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-"].includes(
                                            evt.key
                                          ) && evt.preventDefault()
                                        }
                                        name="mobile"
                                        value={mobile}
                                        onChange={handleChange}
                                      />
                                    </div> */}
                                    <div>
                                      {setmobileErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.mobile}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label>Address</label>

                                    <div className="">
                                      <input
                                        type="text"
                                        placeholder=" Address"
                                        name="address"
                                        value={address}
                                        onChange={handleChange}
                                        class="form-control"
                                        minLength={5}
                                        maxLength={50}
                                      />
                                    </div>
                                    <div>
                                      {setaddressErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.address}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label className=" ">Due in</label>

                                    <div className="">
                                      <input
                                        type="date"
                                        placeholder="Due Date"
                                        name="due"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
                                            evt.key
                                          ) && evt.preventDefault()
                                        }
                                        class="form-control"
                                        value={due}
                                        onChange={handleChange}
                                        min={new Date().toISOString().split("T")[0]}
                                        max={new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0]}
                                      />
                                    </div>
                                    <div>
                                      {setdueErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.due}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label className=" ">
                                      Item Name (Product Name)
                                    </label>

                                    <div className="">
                                      <input
                                        type="text"
                                        placeholder="Item Name"
                                        name="itemname"
                                        class="form-control"
                                        minLength={3}
                                        maxLength={15}
                                        value={itemname}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {setitemnameErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.itemname}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label>Quantity</label>
                                    <div className="">
                                      <input
                                        type="number"
                                        placeholder=" Quantity"
                                        class="form-control"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-"].includes(
                                            evt.key
                                          ) && evt.preventDefault()
                                        }
                                        name="qty"
                                        value={qty}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {setqtyErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.qty}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label className=" ">Amount (USD)</label>

                                    <div className="">
                                      <input
                                        type="number"
                                        placeholder="Amount"
                                        name="amount"
                                        min="1"
                                        class="form-control"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-"].includes(
                                            evt.key
                                          ) && evt.preventDefault()
                                        }
                                        maxLength={15}
                                        value={amount}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {setamountErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.amount}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="crypto_coin_selec form_login_input">
                                    <label className=" ">
                                      Memo
                                      <small>4/150 characters</small>
                                    </label>

                                    <div className="">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Memo"
                                        name="memo"
                                        minLength={5}
                                        maxLength={150}
                                        value={memo}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {setmemoErrref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.memo}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6  ">
                                  <div className=" form_login_input">
                                    <label className="  "></label>
                                    <div className="text-white py-3 px-2">
                                      Total Amount :{" "}
                                      {formValue.qty * formValue.amount} USD
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {buttonLoader == false ? (
                              <>
                                <div class="form_login_input pading_foem mx-auto col-lg-6 d-block">
                                  <Button
                                    data-toggle="modal"
                                    data-target="#myModal1"
                                    className="mt-5"
                                    onClick={submit}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <div class="form_login_input">
                                <Button className="mt-5">Loading...</Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Invoice;
