import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QRCode from "qrcode.react"; // Import the QRCode library
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Dropdown } from "semantic-ui-react";
import { IconCopy } from "@tabler/icons-react";
import "semantic-ui-css/semantic.min.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./Sidebarlink";
import UserSidebar from "./userslidbar";
import { env } from ".././core/service/envconfig";

function Home() {
  const navigate = useNavigate();
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [withdrawType, setwithdrawType] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");

  const [loader, setloader] = useState(false);

  const onSelect = async (e, option) => {

    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setSelectedCurrencySymbol(currencydata.currencySymbol);
    }
  };

  const textRef1 = useRef(null);

  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(null);

  const handleCopy1 = () => {
    const textToCopy = textRef1.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Code Copied");
      })
      .catch((error) => {
        toast.error("Failed to copy code");
      });
  };

  useEffect(() => {
    getAllcurrency1();
  }, []);

  const getAllcurrency1 = async () => {
    try {
      const datas = {
        apiUrl: apiService.depositcurrency,
      };
      const resp = await getMethod(datas);

      console.log(resp, "generative resp");

      if (resp && resp.data.length > 0) {
        if (resp) {
          setloader(false);
          var currArrayCrypto = [];
          var data = resp.data;
          setallCrypto(data);
          for (var i = 0; i < data.length; i++) {
            var obj = {
              value: data[i]._id,
              key: data[i].currencySymbol,
              text: data[i].currencySymbol,
              image: {
                avatar: true,
                src: data[i].Currency_image,
              },
              coinType: data[i].coinType,
              erc20token: data[i].erc20token,
              bep20token: data[i].bep20token,
              trc20token: data[i].trc20token,
              rptc20token: data[i].rptc20token,
            };
            currArrayCrypto.push(obj);
          }
          setallCurrency(currArrayCrypto);
        }
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const handleCurrencyClick = (e, currencySymbol) => {
    console.log(e, currencySymbol, "e, currencySymbol");
    setSelectedCurrencySymbol(currencySymbol);
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-lg-12">
                          <div className="headin_deposur_s">
                            <h3>Generate QR Code</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container">
                      <div className="row justify-content-center m-5">
                        <div className="col-lg-6 card_login">
                          <div className="crypto_coin_selec form_login_input">
                            <label>Select Coin</label>
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={allCurrencyref.current}
                              onChange={onSelect}
                            />
                          </div>

                          <div className="">
                            <div className="">
                              {/* <p
                                className="form-control d-flex justify-content-between"
                                ref={textRef1}
                              >
                                {" "}
                                {env.BaseURL}/qr/BTC{" "}
                                <IconCopy onClick={handleCopy1} />
                              </p> */}

                              <div className="qr_section_class">
                                {selectedCurrencySymbol &&
                                  selectedCurrencySymbol != "" ? (
                                  <div className="form_login_input">
                                    <label>QR Code for {selectedCurrencySymbol}
                                    </label>
                                    <QRCode
                                      value={`${window.location.origin}/qr/${selectedCurrencySymbol}`}
                                      className="d-block mx-auto mt-3"
                                      width="150%"
                                    />

                                    <div className="form_login_input">
                                      <label>Amount</label>

                                      <p
                                        className="p-3 d-flex justify-content-between form-control"
                                        ref={textRef1}
                                      >
                                        {window.location.origin}/qr/
                                        {selectedCurrencySymbol}{" "}
                                        <IconCopy onClick={handleCopy1} />
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form_login_input">
                                    <label>QR Code for BTC</label>
                                    <QRCode
                                      value={`${env.BaseURL}/qr/BTC`}
                                      className="d-block mx-auto mt-3"
                                      width="150%"
                                    />

                                    <div className="form_login_input">
                                      <label>Amount</label>
                                      <p
                                        className="d-flex justify-content-between form-control"
                                        ref={textRef1}
                                      >
                                        {" "}
                                        {env.BaseURL}/qr/BTC{" "}
                                        <IconCopy onClick={handleCopy1} />
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* <iframe src="http://localhost:3000/userpayment/663efd21e79b2aa023ffe115" height="200" width="300" title="Iframe Example"></iframe> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
