import { React, useState, useEffect, useLayoutEffect } from "react";
import logo from "./logo.svg";
// import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Refferal from "./pages/Refferal";
import Login from "./pages/Login";
import Invoicecharge from "./pages/invoicecharge";
import Register from "./pages/Register";
import Userregister from "./pages/Userregister";
import Forget from "./pages/Forget";
import ConfirmNewpass from "./pages/ConfirmNewpass";
import Dashboard from "./pages/Dashboard";
import UserDashboard from "./pages/Userdashboard";
import Commingsoon from "./pages/Commingsoon";
import ViewInvoice from "./pages/viewInvoice";
import Kyc from "./pages/Kyc";
import SessionHistory from "./pages/SesionHistory";
import Editprofile from "./pages/Editprofile";
import Deposit from "./pages/Deposit";
import Withdrew from "./pages/Withdrew";
import UserWithdrew from "./pages/Userwithdrew";
import Support from "./pages/support";
import UserSupport from "./pages/Usersupport";
import Notification from "./pages/notification";
import UserNotification from "./pages/Usernotification";
import Create_checkout from "./pages/Createcheckout";
import Checkout from "./pages/Checkout";
import Viewcheckout from "./pages/View_checkouts";
import Checkoutpay from "./pages/Checkoutpay";
import Widgets from "./pages/widgets";
import Verifybusiness from "./pages/Verifybusiness";
import Kycbusiness from "./pages/Kycbusiness";
import Merchantkyc from "./pages/MerchantKyc";
import ApiScript from "./pages/ApiScript";
import Accounttype from "./pages/Accounttype";
import Profile from "./pages/Merchantprofile";
import UserProfile from "./pages/Userprofile";
import Create_invoice from "./pages/createinvoice";
import Invoice from "./pages/invoice";
import GenerateQR from "./pages/generateqr";
import Apigenerate from "./pages/Apigenerate";
import SecurityNEW from "./pages/SecurityNew";
import Userpayment from "./pages/userpayment";
import Payonramp from "./pages/Payonramp";
import Uniramp from "./pages/Uniramp";
import CheckoutHistory from "./pages/Checkouthistory";
import InvoiceHistory from "./pages/invoiceHistory";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Webhooks from "./pages/webhook";

import Aboutus from "./pages/aboutus";
import Otp from "./pages/Otp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAuthToken } from "../src/core/lib/localStorage";
import Tfalogin from "./pages/Tfalogin";
import IdleTimeOutHandler from "./pages/IdleTimeOutHandler";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./pages/ScrollToTop";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/refferal"
            element={
              <RequireAuth>
                {" "}
                <Refferal />{" "}
              </RequireAuth>
            }
          />
          {/* <Route path="/applay" element={<Applayform />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/Accounttype" element={<Accounttype />} />
          <Route path="/register" element={<Register />} />
          <Route path="/userregister" element={<Userregister />} />
          <Route path="/forget/:id" element={<Forget />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/tfalogin" element={<Tfalogin />} />
          <Route path="/newpass" element={<ConfirmNewpass />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/aboutus" element={<Aboutus />} />

          <Route path="/viewInvoice/:id" element={<ViewInvoice />} />

          <Route path="/invoicecharge" element={<Invoicecharge />} />

          <Route path="/invoicecharge/:id" element={<Invoicecharge />} />

          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                {" "}
                <Dashboard />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/merchat_webhook"
            element={
              <RequireAuth>
                {" "}
                <Webhooks />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/userdashboard"
            element={
              <RequireAuth>
                {" "}
                <UserDashboard />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/Security_tfa"
            element={
              <RequireAuth>
                {" "}
                <SecurityNEW />{" "}
              </RequireAuth>
            }
          />
          <Route path="/sessionHistory" element={<SessionHistory />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/soon" element={<Commingsoon />} />
          <Route
            path="/Kyc"
            element={
              <RequireAuth>
                <Kyc />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/checkouthistory"
            element={
              <RequireAuth>
                <CheckoutHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/invoicehistory"
            element={
              <RequireAuth>
                <InvoiceHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/userprofile"
            element={
              <RequireAuth>
                <UserProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/Currencyqr"
            element={
              <RequireAuth>
                <GenerateQR />
              </RequireAuth>
            }
          />
          <Route
            path="/checkout"
            element={
              <RequireAuth>
                <Checkout />
              </RequireAuth>
            }
          />
          <Route
            path="/merchatAPI"
            element={
              <RequireAuth>
                <Apigenerate />
              </RequireAuth>
            }
          />
          <Route
            path="/create-cheeckout"
            element={
              <RequireAuth>
                <Create_checkout />
              </RequireAuth>
            }
          />
          <Route
            path="/create-invoices"
            element={
              <RequireAuth>
                <Create_invoice />
              </RequireAuth>
            }
          />
          <Route
            path="/checkouts/:id"
            element={
              <RequireAuth>
                <Viewcheckout />
              </RequireAuth>
            }
          />
          <Route path="/checkout_pay/:id" element={<Checkoutpay />} />

          <Route path="/userpayment/:id" element={<Userpayment />} />
          <Route
            path="/Dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/payonramp"
            element={
              <RequireAuth>
                <Payonramp />
              </RequireAuth>
            }
          />
          <Route
            path="/Uniramp"
            element={
              <RequireAuth>
                <Uniramp />
              </RequireAuth>
            }
          />
          <Route
            path="/editprofile"
            element={
              <RequireAuth>
                <Editprofile />
              </RequireAuth>
            }
          />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <Deposit />
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                <Withdrew />
              </RequireAuth>
            }
          />
          <Route
            path="/userwithdraw"
            element={
              <RequireAuth>
                <UserWithdrew />
              </RequireAuth>
            }
          />

          <Route
            path="/support"
            element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            }
          />
          <Route
            path="/usersupport"
            element={
              <RequireAuth>
                <UserSupport />
              </RequireAuth>
            }
          />
          <Route
            path="/invoice"
            element={
              <RequireAuth>
                <Invoice />
              </RequireAuth>
            }
          />

          <Route
            path="/widgets"
            element={
              <RequireAuth>
                <Widgets />
              </RequireAuth>
            }
          />
          <Route
            path="/Verifybusiness"
            element={
              <RequireAuth>
                <Verifybusiness />
              </RequireAuth>
            }
          />
          <Route
            path="/api-script"
            element={
              <RequireAuth>
                <ApiScript />
              </RequireAuth>
            }
          />
          <Route
            path="/kycbusiness"
            element={
              <RequireAuth>
                <Kycbusiness />
              </RequireAuth>
            }
          />
          <Route
            path="/merchantkyc"
            element={
              <RequireAuth>
                <Merchantkyc />
              </RequireAuth>
            }
          />

          <Route path="/notification" element={<Notification />} />
          <Route path="/usernotification" element={<UserNotification />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
