import React, { useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import UserSidebar from "./userslidbar";

import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import useState from "react-usestateref";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];

  const navigate = useNavigate();
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [getUserId, setGetUserId] = useState("");
  const [getUserlostlogintime, setGetUserlostlogintime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [perpage, setperpage] = useState(5);
  const [getUserBalance, setGetUserBalance] = useState([]);
  const [change, setChange] = useState([]);
  const [btcbalance, setBtcBalance] = useState();
  const [usdtbalance, setUsdtBalance] = useState();
  const [userDetails, setuserDetails] = useState("");
  const [reserverbalance, setReserveBalance] = useState(0);
  const [search, setSearch, searchref] = useState("");

  const [loader, Setloader] = useState(false);

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    console.log(token, "=-=-=-=-=-=-=-=--=-=-");
    if (token != null) {
      console.log("call here===");
      getUserDetails();
      getuserbalance(currentPage);
      getbalance();
      getdeposite()
    } else {
      navigate("/login");
    }
  }, [0]);



  var getdeposite = async () => {
    var data = {
      apiUrl: apiService.getTransaction,
    };
    var resp = await getMethod(data);

    console.log(resp, "respgeiurtbtaclal");
  };
  var getbalance = async () => {
    Setloader(true);
    var data = {
      apiUrl: apiService.get_Btc_Usdt_Balance,
    };
    var resp = await getMethod(data);
    Setloader(false);
    console.log(resp, "respgeiurtbtaclal");
    console.log(resp.balance.btc_balance, "respgeiurtbtaclal");
    console.log(resp.balance.total_balance_usdt, "respgeiurtbtaclal");
    setBtcBalance(resp.balance.btc_balance);
    setUsdtBalance(resp.balance.total_balance_usdt);
    setReserveBalance(resp.balance.total_reserve);
  };
  var getUserDetails = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var resp = await getMethod(data);
    console.log(resp, "resp");
    setGetUserId(resp.login_response.user_id);
    setuserDetails(resp.data);
    var time = moment(resp.login_response.createddate).format("lll");
    setGetUserlostlogintime(time);
  };
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getuserbalance(pageNumber);
  };
  var getuserbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };
    console.log(obj, "obj");
    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    console.log(resp, "respresp");
    setGetUserBalance(resp.Message);
    settotal(resp.total);
    setChange(resp.changes);
  };

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, '');
      setSearch(sanitizedValue);
      getuserbalance(1);
    } catch (error) { }
  };
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <UserSidebar />
          </div>
          <div className="css-1h690ep">
            {loader == true ? (
              <div class="spinner css-ezm1fk">
                <div class="css-1qoumk2"></div>
                <div class="css-1v19680"></div>
                <div class="css-1v90jie"></div>
                <div class="css-19p0rvp"></div>
              </div>
            ) : (
              <div className="section_bg_ash">
                <div className="section_selec_ash">
                  <div className="container-fluid">
                    <div className="mx-3">

                      <div className="profile_name">
                        <h3>{userDetails.name} </h3>
                      </div>
                      <div className="gap-12">
                        <div className="css-1osdvxh d-flex mt-16 ">
                          <div className="css-chqiix">
                            <p className="css-mx5ldy">User ID :  <span className="css-7nipyh">
                              {" "} {getUserId.substring(14)}
                            </span> </p>
                          </div>

                        </div>

                        <div className="css-1osdvxh d-flex mt-12 ">
                          <div className="css-chqiix">
                            <p className="css-mx5ldy">
                              Last login time : <span className="css-7nipyh">
                                {" "} {getUserlostlogintime}
                              </span>
                            </p>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="inner_conteten">
                  <div className="row justify-content-center my-2">
                    <div className="col-lg-12 px-2 ">
                      <div className="dashcard1">
                        <div className="contant_secion_balances">
                          <h3 className="mb-20">Estimated Balance</h3>
                          <h3 className="mb-20 raytes">
                            <span>
                              {parseFloat(usdtbalance && usdtbalance).toFixed(
                                4
                              )}{" "}
                              USD
                            </span>{" "}
                          </h3>
                          <p className="raytesp">
                          {usdtbalance > 0 ? "Your account is active and ready for transactions. You can make Deposits, withdrawals, and manage your assets." : "Your account does not currently have any assets, complete identity verification in order to makedeposits to your account."} 

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center my-3">
                    <div className="col-lg-12 px-2 ">
                      <div className="dashcard1 market_tabel w-100">
                        <div className="row align-item-center mb-20 px-4">
                          <div className="col-sm-9 p-0">
                            <h3 className="h3 Portfolio">Portfolio</h3>
                          </div>
                          <div className="col-sm-3 searchbcx d-flex">
                            <div className="">
                              <input
                                type="text"
                                placeholder="search"
                                name="search"
                                minLength={1}
                                maxLength={15}
                                onChange={handleChange}
                                value={search}
                              />
                            </div>
                            <div className="search">
                              <i class="ri-search-line text-center d-block"></i>
                            </div>
                          </div>
                        </div>

                        <div className="market_section overflow_hiden">
                          <div className=" row  justify-content-center">
                            <div className="table-responsive col-lg-12 shadow">
                              <table class="table w-100">
                                <thead>
                                  <tr className="text-center">
                                    <th className="leftborder">Symbol</th>
                                    <th>Name</th>
                                    <th>Amount</th>
                                    <th className=" rightborder"> Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getUserBalance && getUserBalance.length > 0 ? (
                                    getUserBalance.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            <div className="coin_section">
                                              <img
                                                src={item.currencyImage}
                                                className="blackshow"
                                              />
                                              <span>{item.currencysymbol}</span>{" "}
                                            </div>
                                          </td>
                                          <td>
                                            <h5>{item.currencyName}</h5>
                                          </td>
                                          <td>
                                            <h5>
                                              {parseFloat(
                                                item.currencyBalance
                                              ).toFixed(8)}
                                            </h5>
                                          </td>
                                          <td className="">
                                            <p> $ {item.EstimatedUSDT} </p>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan="4">
                                        <img src={require('../Image/nodata.png')} className="mt-5" />
                                        <p className="mb-5 mt-3 nodatafound">No data Found</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>

                            </div>
                            {getUserBalance && getUserBalance.length > 0 ? (

                              <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={currentPage}
                                itemsCountPerPage={recordPerPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={pageRange}
                                onChange={handlePageChange}
                              />) : ""}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
