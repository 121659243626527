import { React, useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";

function Footer() {
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  return (
    <>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="link_section">
                <Link to="/">
                  {" "}
                  <img
                    src={require("../Image/bitcoin-p2p/Logolight.png")}
                    className="blackshow"
                    width="55%"
                  />
                </Link>
                <div className="copywrigt">
                  <p>
                    Streamline your business with our hassle-free crypto payment
                    ecosystem. Benefit from the most secure crypto gateway
                    store, offering lightning-fast transactions and robust
                    security. Attract new customers and boost sales with the
                    future of payments.
                  </p>
                </div>

                <ul className="flex_sss_cloe">
                  <li>
                    <Link to="https://twitter.com/" target="_blank">
                      <img
                        src={require("../Image/Icons/twitter.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/?hl=en" target="_blank">
                      <img
                        src={require("../Image/Icons/instagram.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/" target="_blank">
                      <img
                        src={require("../Image/Icons/facebook.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/" target="_blank">
                      <img
                        src={require("../Image/Icons/mail.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/" target="_blank">
                      <img
                        src={require("../Image/Icons/telegram.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="https://medium.com/" target="_blank">
                      <img
                        src={require("../Image/Icons/medium.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="https://www.youtube.com/" target="_blank">
                      <img
                        src={require("../Image/Icons/youtube.webp")}
                        className="blackshow"
                        width="25px"
                      />
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row justify-content-between">
                <div className="col-lg-4 col-md-6">
                  <div className="link_section">
                    <h4>Features</h4>
                    <ul>
                      <li>
                        <Link to="">E-commerce & Plugins</Link>
                      </li>
                      <li>
                        <Link to="">Crypto Token Purchase</Link>
                      </li>
                      <li>
                        <Link to="">Freelancing Platforms</Link>
                      </li>
                      <li>
                        <Link to="">Donations</Link>
                      </li>
                      <li>
                        <Link to="">Ticketing and Event booking</Link>
                      </li>
                      <li>
                        <Link to="">Travel & Tourism Product</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="link_section">
                    <h4>Use case</h4>
                    <ul>
                      {loginCheck == false ? (
                        <li>
                          <a href="">E-commerce & Plugins</a>
                        </li>
                      ) : (
                        <li>
                          <a href="">Crypto Token Purchase</a>
                        </li>
                      )}
                      {loginCheck == false ? (
                        <li>
                          <a href="">Freelancing Platforms</a>
                        </li>
                      ) : (
                        <li>
                          <a href="">Donations</a>
                        </li>
                      )}
                      {loginCheck == false ? (
                        <li>
                          <a href="">Ticketing and Event booking</a>
                        </li>
                      ) : (
                        <li>
                          <a href="">Travel & Tourism Product</a>
                        </li>
                      )}
                      {/* <li>
                        <Link to="/checkout">Checkout</Link>
                      </li>

                      <li>
                        <Link to="/invoice">Invoice</Link>
                      </li>
                      <li>
                        <Link to="/checkout">Pay With Crypto</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="link_section">
                    <h4>Support</h4>
                    <ul>
                      {loginCheck == false ? (
                        <li>
                          <a href="/login">Support</a>
                        </li>
                      ) : (
                        <li>
                          <a href="/support">Support</a>
                        </li>
                      )}
                      {/* <li>
                        <Link to="/support">Support</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copywrigt-text">
          <p className="text-center">
            © 2024 Gatecryptopay. All rights reserved
          </p>
        </div>
      </footer>

      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Buy Ticket</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="ticket_section">
                <div className="input_form_tivket">
                  <p>
                    Buy<span>Ticket</span>
                  </p>
                  <input type="number" placeholder="" />
                  <div className="amount_bust">~ 0.001 BUSD</div>
                </div>
                <div className="amount_content">
                  <p>
                    Cost(BUSD)<span>0.001 BUSD</span>
                  </p>
                  <div className="youpay">
                    <p>
                      You Pay <span> ~ 0 BUSD</span>
                    </p>
                  </div>
                </div>
                <Button className="header_btn">Connect Wallet</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
