import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "./userslidbar";

function Home() {
  const initialFormValue = {
    subject: "",
    catagory: "",
    message: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [subjectErr, SetsubjectErr, subjectErrref] = useState(false);
  const [catagoryErr, SetcatagoryErr, catagoryErrref] = useState(false);
  const [messageErr, SetmessageErr, messageErrref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ticketdetails, setticketdetails] = useState([]);
  const [currentpage, Setcurrentpage] = useState(1);
  const [totalpage, Settotalpage] = useState(0);
  const { subject, catagory, message } = formValue;
  const [userDetails, setuserDetails] = useState("");
  const [pageno, Setpageno, pagenoref] = useState(1);

  useEffect(() => {
    getTicket();
    getUserDetails();
  }, []);
  const navigate = useNavigate();

  var getUserDetails = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var resp = await getMethod(data);
    console.log(resp, "resp");
    setuserDetails(resp.data);
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let sanitizedValue = value;

    // Check if the value starts with a white space
    if (sanitizedValue.startsWith(' ')) {
      // Remove the leading white space
      sanitizedValue = sanitizedValue.trimLeft();
    }

    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (formData) => {
    const errors = {};

    if (formData.subject == "") {
      errors.subject = " Subject is required ";
      SetsubjectErr(true);
    } else if (!/^[a-zA-Z]/.test(formData.subject)) {
      errors.subject = "Subject must start with a letter ";
      SetsubjectErr(true);
    } else if (formData.subject.length < 4 || formData.subject.length > 25) {
      errors.subject = "Subject must be between 4 to 25 characters ";
      SetsubjectErr(true);
    } else if (!/^[a-zA-Z0-9_ ]+$/.test(formData.subject)) {
      errors.subject = "Subject can only contain letters, numbers, and underscores ";
      SetsubjectErr(true);
    } else if (formData.catagory == "") {
      SetsubjectErr(false);
      errors.catagory = "Catagory is required ";
      SetcatagoryErr(true);
    } else if (!/^[a-zA-Z]/.test(formData.catagory)) {
      errors.catagory = "Catagory must start with a letter ";
      SetcatagoryErr(true);
    } else if (formData.catagory.length < 4 || formData.catagory.length > 25) {
      errors.catagory = "Catagory must be between 4 to 25 characters ";
      SetcatagoryErr(true);
    } else if (!/^[a-zA-Z0-9_ ]+$/.test(formData.catagory)) {
      errors.catagory = "Catagory can only contain letters, numbers, and underscores ";
      SetcatagoryErr(true);
    } else if (formData.message == "") {
      SetcatagoryErr(false);

      errors.message = "Message is required ";
      SetmessageErr(true);
    } else if (!/^[a-zA-Z]/.test(formData.message)) {
      errors.message = "Message must start with a letter ";
      SetmessageErr(true);
    } else if (formData.message.length < 3 || formData.message.length > 250) {
      errors.message = "Message must be between 3 to 250 characters ";
      SetmessageErr(true);
    } else {
      SetmessageErr(false);
      SetcatagoryErr(false);
      SetsubjectErr(false);


    }

    setvalidationnErr(errors);
    return errors;
  };

  const createTicket = async () => {
    validate(formValue);


    if (
      subjectErrref.current == false &&
      catagoryErrref.current == false &&
      messageErrref.current == false
    ) {
      var data = {
        apiUrl: apiService.createTicket,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue(initialFormValue);
      if (resp.status == true) {
        toast.success(resp.message);
        getTicket();
      } else {
        toast.error(resp.message);
      }
    } else {
    }
  };

  const recordpage = 5;
  const pagerange = 5;
  const historychanges = (pagenumber) => {
    getTicket(pagenumber);
    Setcurrentpage(pagenumber);
  };
  const getTicket = async (page) => {

    Setpageno(page);
    var data = {
      apiUrl: apiService.getTicket,
      payload: {
        FilPerpage: 5,
        FilPage: page,
      },
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setticketdetails(resp.data.data);
      Settotalpage(resp.data.total);
    } else {
      toast.error(resp.message);
    }
  };

  const deleteTicket = async (item) => {
    var obj = {
      id: item.id,
    };
    var data = {
      apiUrl: apiService.deleteTicket,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      toast.success(resp.message);
      getTicket();
    } else {
      toast.error(resp.message);
    }
  };
  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <div>
                    <section className="deposut_section">
                      <div className="container-fluid ">
                        <div className="row ">
                          <div className="col-lg-12">
                            <div className="headin_deposur_s">
                              <h3>Raise Your Support</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="form-deposit">
                      <div className="container">
                        <div className="row justify-content-center payment_burron">
                          <div className="col-lg-6 login_form cardb p20 card_login mr17">
                            <div className="qr_section_class">
                              <div className="crypto_coin_selec form_login_input">
                                <label>
                                  Subject
                                  <small>4/25characters</small>
                                </label>
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Subject"
                                    name="subject"
                                    class="form-control"

                                    value={subject}
                                    minLength={4}
                                    maxLength={25}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {subjectErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.subject}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="crypto_coin_selec form_login_input">
                                <label>
                                  Catagory
                                  <small>4/25characters</small>
                                </label>
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Catagory"
                                    name="catagory"
                                    class="form-control"

                                    value={catagory}
                                    minLength={4}
                                    maxLength={25}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {catagoryErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.catagory}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="crypto_coin_selec form_login_input">
                                <label>
                                  Message
                                  <small>3/250characters</small>
                                </label>
                                <div className=" p-0">
                                  <textarea
                                    placeholder="Message"
                                    name="message"
                                    class="form-control"

                                    value={message}
                                    minLength={3}
                                    maxLength={250}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                                <div>
                                  {messageErrref.current == true ? (
                                    <p className="text-danger top135" >
                                      {" "}
                                      {validationnErr.message}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            {buttonLoader == false ? (
                              <div class="form_login_input">
                                <Button className="mt-5" onClick={createTicket}>Submit</Button>
                              </div>
                            ) : (
                              <div class="form_login_input">
                                <Button className="mt-5">Loading...</Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="tavle_transactio mt-5">
                      <div className="padding_section">
                        <div className="container">
                          <div className="row justify-content-center dashcard1 mr17 mb-5 p-3 bg_none">
                            <div className="col-lg-12 shadow">
                              <div className="market_tabel1 w-100 mt-0 p-3">
                                <div className="market_section">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="leftborder">S.No</th>
                                          <th> Subject</th>
                                          <th>Catagory</th>
                                          <th>Message</th>
                                          <th className="rightborder">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {ticketdetails &&
                                          ticketdetails.length > 0 ? (
                                          ticketdetails.map((item, i) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{pagenoref.current ? ((pagenoref.current - 1) * 5) + i + 1 : i + 1}</td>
                                                  <td>{item.subject}</td>
                                                  <td>{item.catagory}</td>
                                                  <td>{item.message}</td>
                                                  <td>
                                                    {" "}
                                                    <a
                                                      className="text-red"
                                                      onClick={() => {
                                                        deleteTicket(item);
                                                      }}
                                                    >
                                                      {" "}
                                                      <i class="bi bi-trash-fill"></i>
                                                    </a>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td
                                              className="text-center"
                                              colSpan="5"
                                            >
                                              <img src={require('../Image/nodata.png')} className="mt-5" />
                                              <p className="mb-5 mt-3 nodatafound">No data Found</p>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>

                                  </div>
                                  {ticketdetails.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentpage}
                                      itemsCountPerPage={recordpage}
                                      totalItemsCount={totalpage}
                                      pageRangeDisplayed={pagerange}
                                      onChange={historychanges}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
