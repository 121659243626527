import axios from './axios';
import { respChanges } from './responseModify';
import { env } from './envconfig';
import CryptoJS from 'crypto-js';

const password = 'RJ23edrf';

const backendHost = env.apiHost



export const getMethod = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': backendHost + data.apiUrl
        });
        return respChanges(respData.data);
    }
    catch (err) {
        return {
            status: 'error',
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const decryptData = async (encryptedData) => {
    try {
        const key = CryptoJS.enc.Hex.parse(password);
        const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { mode: CryptoJS.mode.ECB });
        const plaintext = decrypted.toString(CryptoJS.enc.Utf8);
        return plaintext;

    } catch (error) {
        console.error('Decryption error:', error);
    }
}


export const postMethod = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': backendHost + data.apiUrl,
            data: data.payload ? data.payload : {}
        });
        return respChanges(respData.data);
    }
    catch (err) {
        return {
            status: 'error',
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const generatepostMethod = async (data) => {
    console.log(data, "data");
    try {
        // Calculate digital signature
        // const signature = calculateSignature(data.apiKey, data.secretKey, data.payload);
        // console.log(signature, "signature");
        // Send request to backend API
        
        let respData = await axios({
            method: 'post',
            url: backendHost + data.apiUrl,
            data: data.payload ? data.payload : {},
            headers: {
                'api-key': data.apiKey,
                // 'secret-key': data.secretKey,
                'digital-signature': data.signature ? data.signature : "",
                'Authorization': data.token
            }
        });

        if (!respData || !respData.data) {
            throw new Error('Empty response');
        }

        return respChanges(respData.data);
    } catch (err) {
        console.error(err);
        return {
            status: 'error',
            message: err.message || 'Internal server error',
            error: err.response && err.response.data && err.response.data.errors
        };
    }
};


function calculateSignature(apiKey, secretKey, payload) {

    console.log(apiKey, secretKey, payload,"apiKey, secretKey, payload");

    const hmac = CryptoJS.HmacSHA256(`${apiKey}${secretKey}${JSON.stringify(payload)}`, secretKey);
    console.log(hmac, "hmac");

    const signature = hmac.toString(CryptoJS.enc.Hex);

    console.log(signature, "signature");

    return signature;
    
}

export const fileUpload = async (data) => {
    try {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        let respData = await axios({
            'method': 'post',
            'url': data.apiUrl,
            data: data.payload ? data.payload : {}
        });
        return respChanges(respData.data);
    }
    catch (err) {
        return {
            status: 'error',
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

