import React, {useState, useEffect} from "react";
import Header from "./Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import Sidebar from "./Sidebarlink";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import Pagination from "react-js-pagination";
import moment from "moment";

function Home() {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    getSession(1);
  }, []);

  const [currentpage, setcurrentpage] = useState("");
  const [loginTotalpages, setloginTotalpages] = useState("");
  const [Login_History, setLogin_History] = useState("");

  const loginrecordpage = 5;
  const loginpagerange = 5;

  const handlepagelogin = (pageNumber) => {
    setcurrentpage(pageNumber);
    getSession(pageNumber);
  };

  const getSession = async (page) => {
    var payload = {
      perpage: 5,
      page: page,
    };
    var data = {
      apiUrl: apiService.getSessionHisotry,
      payload: payload,
    };

    var resp = await postMethod(data);
    setloginTotalpages(resp.data.total);
    setLogin_History(resp.data.data);
  };
  return (
    <div className="header">
      <Header />
      <div>
        <div className="css-1wr4jig">
          <div className="css-1o43t2y">
            <div className="css-1t8cbiy">
              <Sidebar />
            </div>
            <div className="css-1h690ep">
              <main className="inner_conteten pt-4">
                <section className="tow_refer p-0 w-100">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="reer_title">
                          <i class="ri-chat-history-fill"></i> Sesion History
                        </h2>
                        <div className="market_tabel w-100">
                          <div className="market_section">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Ip</th>
                                    <th>Browser</th>
                                    <th>Platform</th>
                                    <th>OS</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Login_History &&
                                    Login_History.map((data, i) => {
                                      return (
                                        <tr>
                                          <th>{i + 1}</th>
                                          <td>{data.ipAddress}</td>
                                          <td>{data.browser}</td>
                                          <td>{data.platform}</td>
                                          <td>{data.OS}</td>
                                          <td>
                                            {moment(data.createdDate).format(
                                              "lll"
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentpage}
                          itemsCountPerPage={loginrecordpage}
                          totalItemsCount={loginTotalpages}
                          pageRangeDisplayed={loginpagerange}
                          onChange={handlepagelogin}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        </div>
      </div>
       {/* <Footer /> */}
    </div>
  );
}

export default Home;
