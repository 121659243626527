import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import useState from "react-usestateref";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { env } from ".././core/service/envconfig";
import axios from "axios";
function Home() {
  const initialValues = {
    send: "",
  };

  const initialValues1 = {
    receive: 0,
  };

  const [loader, setLoader] = useState(true);
  const [formValue, setFormValue, formValueref] = useState(initialValues);
  const [formValue1, setFormValue1, formValue1ref] = useState(initialValues1);
  const [showDiv, setShowDiv] = useState(false);
  const [receiveshowDiv, setreceiveShowDiv] = useState(false);
  const { send } = formValue;
  const { receive } = formValue1;

  const [currency, setcurrency, currencyref] = useState("");
  const [decurrency, setdecurrency, decurrencyref] = useState("");
  const [SelectedCrypto, setSelectedCrypto, SelectedCryptoref] = useState("");
  const [exchangerate, setexchangerate, exchangerateref] = useState("");
  const [placeholder, setplaceholder, placeholderref] = useState();
  const [receiveValue, setReceiveValue, receiveValueref] = useState(0);
  const [walletstate, setwalletstate, walletstateref] = useState(false);
  const [afterwallet, setafterwallet, afterwalletref] = useState(false);
  const [afterPhoneno, setafterPhoneno, afterPhonenoref] = useState(false);
  const [Otpcomplete, setOtpcomplete, Otpcompleteref] = useState(false);

  const [wallet, setwallet, walletref] = useState("");
  const [phoneno, setphoneno, phonenoref] = useState("");
  const [Otp, setOtp, Otpref] = useState("");
  const [otpreference, setotpreference, otpreferenceref] = useState("");
  const [accesstoken, setaccesstoken, accesstokenref] = useState("");
  const [PayOnRampdata, setPayOnRampdata, PayOnRampdataref] = useState("");
  const [kycstatus, setkycstatus, kycstatusref] = useState("");
  const [paymentmethods, setpaymentmethods, paymentmethodsref] = useState("");
  const [QRdetails, setQRdetails, QRdetailsref] = useState("");


  const handleToggle = () => {
    setShowDiv(!showDiv);
  };
  const handleToggle1 = () => {
    setreceiveShowDiv(!receiveshowDiv);
  };

  const handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue, ...{ [name]: value } };

      console.log(formdata, "formdata");
      setFormValue(formdata);

      console.log(placeholderref.current, "placeholderref.current");

      if (formdata.send >= placeholderref.current) {
        const value = formdata.send / exchangerateref.current.buy;

        const receivamount =
          value -
          (SelectedCryptoref.current
            ? SelectedCryptoref.current.networkFee
            : decurrencyref.current.networkFee);

        setReceiveValue(receivamount);
      } else {
        setReceiveValue(0);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleChange2 = (e) => {
    try {
      e.preventDefault();
      setOtp(e.target.value);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleChange3 = (e) => {
    try {
      console.log(e, "ephone");

      e.preventDefault();
      setphoneno(e.target.value);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleChange1 = (e) => {
    try {
      e.preventDefault();

      console.log(e.target.value, "e.target.value");
      setwallet(e.target.value);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const Afterwallets = () => {
    if (walletref.current.length >= 20 && walletref.current.length <= 50) {
      setafterwallet(true);
    } else {
      toast.error("Enter a valid wallet address");
    }
  };
  const afterphone = async () => {
    console.log("ujbujeiwenewujkedsujnjewfnuewfjnwefjn");

    try {
      if (phonenoref.current && phonenoref.current.length >= 10) {
        var obj = {
          Phone: phonenoref.current,
        };

        var data = {
          apiUrl: apiService.Phonenumber,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "Respdata");

        if (resp.status == true) {
          setafterPhoneno(true);
          setotpreference(resp.data.data.otpReferenceKey);
          toast.success(resp.data.data.message);
        } else {
          console.log(resp.Message);
        }
      } else {
        toast.error("Enter a valid mobile number");
      }
    } catch (e) {
      console.log(e, "error ");
    }
  };

  const afterOTP = async () => {
    try {
      if (phonenoref.current.length >= 10) {
        var obj = {
          Phone: phonenoref.current,
          Otp: Otpref.current,
          Reference: otpreferenceref.current,
        };

        var data = {
          apiUrl: apiService.OTPverify,
          payload: obj,
        };

        console.log(obj, "obj");

        var resp = await postMethod(data);
        console.log(resp, "Respdata");

        if (resp.status == true) {
          setaccesstoken(resp.data.data);
          setkycstatus(resp.data);
          console.log(accesstokenref.current, "accesstokenref");
          setOtpcomplete(true);
          Qrcode();
        } else {
          console.log(resp.Errormessage);
          toast.error(resp.Errormessage);
        }
      } else {
        toast.error("Enter a valid mobile number");
      }
    } catch (e) {
      console.log(e, "error ");
    }
  };

  // const UserKycstatus = async () => {
  //   try {
  //     if (phonenoref.current.length >= 10) {
  //       var obj = {
  //         phoneNumber: phonenoref.current,
  //       };

  //       var data = {
  //         apiUrl: apiService.UserKycstatus,
  //         payload: obj,
  //       };

  //       console.log(obj, "obj");

  //       var resp = await postMethod(data);
  //       console.log(resp, "Respdata");

  //       if (resp.status == true) {
  //         setkycstatus(resp.data);

  //       } else {
  //         console.log(resp.Errormessage);
  //         toast.error(resp.Errormessage);
  //       }
  //     } else {
  //       toast.error("Enter a valid mobile number");
  //     }
  //   } catch (e) {
  //     console.log(e, "error ");
  //   }
  // };

  const Qrcode = async () => {
    try {
      if (phonenoref.current.length >= 10) {
        var obj = {
          amount: send,
          currency: SelectedCryptoref.current
            ? SelectedCryptoref.current.currency
            : decurrencyref.current.currency,
          blockchain: SelectedCryptoref.current
            ? SelectedCryptoref.current.blockchain
            : decurrencyref.current.blockchain,
          applicationId: "2e70206a-09fd-43cd-8f0c-f7b445ae2a41",
          referenceId: "6e2d2626-55bb-41d5-9892-318e48a45f4b",
          transferType: "BUY",
          phoneNumber: phonenoref.current,
          walletAddress: walletref.current,
          coinPrice: exchangerateref.current.buy,
          Token: accesstokenref.current.accessToken,
        };
        console.log(obj, "obj");
        var data = {
          apiUrl: apiService.getQrcode,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "Respdata");
        if (resp.status == true) {
          setpaymentmethods(resp.data.data);
          Getbankdetails();
        } else {
          console.log(resp.Errormessage);
          toast.error(resp.Errormessage);
        }
      } else {
        toast.error("Enter a valid mobile number");
      }
    } catch (e) {
      console.log(e, "error ");
    }
  };

  const Getbankdetails = async () => {
    try {
      console.log(paymentmethodsref.current, "paymentmethodsref");
      if (phonenoref.current.length >= 10) {
        var obj = {
          amount: send,
          Token: paymentmethodsref.current.accessToken,
        };

        console.log(obj, "obj");

        var data = {
          apiUrl: apiService.Getbankdetails,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status == true) {
          console.log(resp, "Respdata");

          setQRdetails(resp.data);
          console.log(QRdetailsref.current, "QRdetailsref.current");
        } else {
          console.log(resp.Errormessage);
          toast.error(resp.Errormessage);
        }
      } else {
        toast.error("Enter a valid mobile number");
      }
    } catch (e) {
      console.log(e, "error ");
    }
  };

  const walletsubmit = () => {
    setwalletstate(true);
  };

  const submitpayment = async () => {
    const formData = new FormData();

    const obj1 = {
      utrNumber: "QWQWZXDWEDOO",
      bankId: QRdetailsref.current.bankId,
      transferType: "UPI",
    };

    formData.append("data", JSON.stringify(obj1));

    console.log(formData, "formData");

    try {
      const response = await axios.post(
        "https://api-dev.payonramp.com/transactions/auth/submitUTR",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${paymentmethodsref.current.accessToken}`,
          },
        }
      );

      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error.response.data.message);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    onRamp();
    Getpayonrampkyc();
  }, [0]);

  var Getpayonrampkyc = async () => {
    var data = {
      apiUrl: apiService.getpayonrampkyc,
    };
    var resp = await getMethod(data);
    console.log(resp, "Respdata");

    if (resp.status == true) {
      setPayOnRampdata(resp.data);
    } else {
      console.log(resp.Message);
    }
  };

  var onRamp = async () => {
    var data = {
      apiUrl: apiService.payoncurrency,
    };

    setLoader(true);

    var resp = await getMethod(data);

    setLoader(false);

    console.log(resp, "Respdata");
    if (resp.status == true) {
      setcurrency(resp.data.data.currecies);
      setdecurrency(resp.data.data.currecies[0]);
      exchangeRate(resp.data.data.currecies[0]);
    } else {
      console.log(resp.Message);
    }
  };

  var exchangeRate = async (data) => {
    console.log(data, "data");
    var obj = {
      currency: data.currency,
      blockchain: data.blockchain,
      source: data.source,
    };

    var data = {
      apiUrl: apiService.exchangeRate,
      payload: obj,
    };
    var resp = await postMethod(data);
    console.log(resp, "Respdata");

    if (resp.status == true) {
      setexchangerate(resp.data.data);

      var total =
        exchangerateref.current.buy *
        (SelectedCryptoref.current
          ? SelectedCryptoref.current.minimumBuyLimit
          : decurrencyref.current.minimumBuyLimit) +
        1;

      setplaceholder(Math.ceil(total));
    } else {
      console.log(resp.Message);
    }
  };

  const handleCryptoSelect = (data) => {
    console.log(data, "data");
    setSelectedCrypto(data);
    exchangeRate(data);
  };

  const navigate = useNavigate();

  const options = [
    { key: "Male", value: "Male", text: "Male" },
    { key: "Female", value: "Female", text: "Female" },
    { key: "Others", value: "Others", text: "Others" },
  ];

  const initialformValue43 = {
    email: "",
    customerName: "",
    pannumber: "",
    address: "",
    aadhar: "",
    state: "",
    Dob: "",
  };

  const [formValue4, setformValue4] = useState(initialformValue43);
  const [validationnErr, setvalidationnErr] = useState("");
  const [emailErr, setEmailErr, setEmailErrref] = useState(false);
  const [customerNameErr, setcustomerNameErr, setcustomerNameErrref] =
    useState(false);
  const [addressErr, setaddressErr, setaddressErrref] = useState(false);
  const [paymentErr, setpaymentErr, setpaymentErrref] = useState(false);
  const [paymentmethod, setpaymentmethod, paymentmethodref] = useState("");
  const [PanNumberErr, setPanNumberErr, setPanNumberErrref] = useState(false);
  const [aadharErr, setaadharErr, setaadharErrref] = useState(false);
  const [DobErr, setDobErr, setDobErrref] = useState(false);
  const [stateErr, setstateErr, setstateErrref] = useState(false);
  const { email, customerName, pannumber, address, aadhar, state, Dob } =
    formValue4;

  const handleChange10 = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue4, ...{ [name]: value } };
    console.log("formData====", formData);
    setformValue4(formData);
    validate(formValue4);
  };

  const validate = () => {
    try {
      const errors = {};

      if (email == "") {
        errors.email = "Email is required !";
        setEmailErr(true);
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        errors.email = "Invalid Email ";
        setEmailErr(true);
      } else {
        setEmailErr(false);
      }

      if (customerName == "") {
        errors.customerName = "Name is required !";
        setcustomerNameErr(true);
      } else if (customerName.length < 3 || customerName.length > 15) {
        errors.customerName =
          "Name should be between 3 and 15 characters long!";
        setcustomerNameErr(true);
      } else {
        setcustomerNameErr(false);
      }

      if (pannumber == "") {
        errors.pannumber = "Pan Number is required!";
        setPanNumberErr(true);
      } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pannumber)) {
        errors.pannumber = "Invalid PAN format!";
        setPanNumberErr(true);
      } else {
        setPanNumberErr(false);
      }

      if (aadhar == "") {
        errors.aadhar = "Aadhar Number is required!";
        setaadharErr(true);
      } else if (!/^\d{12}$/.test(aadhar)) {
        errors.aadhar = "Aadhar number should be 12 digits!";
        setaadharErr(true);
      } else {
        setaadharErr(false);
      }

      if (state == "") {
        errors.state = "State is required !";
        setstateErr(true);
      } else if (state.length < 5 || state.length > 25) {
        errors.state = "State should be between 5 and 25 characters long!";
        setstateErr(true);
      } else {
        setstateErr(false);
      }

      if (address == "") {
        errors.address = "Address is required !";
        setaddressErr(true);
      } else if (address.length < 5 || address.length > 50) {
        errors.address = "Address should be between 5 and 50 characters long!";
        setaddressErr(true);
      } else {
        setaddressErr(false);
      }

      if (Dob == "") {
        errors.Dob = "Date of birth is required !";
        setDobErr(true);
      } else {
        setDobErr(false);
      }

      if (paymentmethod == "") {
        errors.payment = "Gender is required !";
        setpaymentErr(true);
      } else {
        setpaymentErr(false);
      }
      setvalidationnErr(errors);
    } catch (error) { }
  };

  const onpaymentSelect = async (e, option) => {
    console.log(option.value);
    setpaymentmethod(option.value);
  };

  const submit = async () => {
    validate(formValue4);
    validate1(formValue45);

    formValue4["Panimage"] = panImage;
    formValue4["Userimage"] = userPhoto;
    formValue4["Aadharfront"] = aadharFrontImage;
    formValue4["Aadharback"] = aadharBackImage;
    formValue4["Gender"] = paymentmethodref.current;
    formValue4["phone"] = phonenoref.current;
    formValue4["accesstoken"] = accesstokenref.current.accessToken;

    const hasErrors =
      setEmailErrref.current ||
      setcustomerNameErrref.current ||
      setaddressErrref.current ||
      setDobErrref.current ||
      setaadharErrref.current ||
      setPanNumberErrref.current ||
      setstateErrref.current ||
      setpaymentErrref.current;

    if (
      !hasErrors &&
      formValue4.panImage != "" &&
      formValue4.userPhoto != "" &&
      formValue4.aadharFrontImage != "" &&
      formValue4.aadharBackImage != ""
    ) {
      console.log(formValue4, "formValue4");

      var data = {
        apiUrl: apiService.payonrampkyc,
        payload: formValue4,
      };
      var resp = await postMethod(data);
      console.log(resp, "resp-==-=resp");
      if (resp.status === true) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    } else {
      console.log("Require all fields");
    }
  };

  const cancelData = async () => {
    setformValue4(initialformValue43);
    setaadharFrontImage("");
    setpanImage("");
    setuserPhoto("");
    setaadharBackImage("");
  };

  const initialformValue45 = {
    address_proof: "",
    id_proof: "",
    photo_proof: "",
    addressback_proof: "",
  };
  const [formValue45, setformValue45] = useState(initialformValue45);

  const [addressProof, setaddressProof, addressProofref] = useState(false);
  const [idProof, setidProof, idProofref] = useState(false);
  const [photoProof, setphotoProof, photoProofref] = useState(false);
  const [addressbackProof, setaddressbackProof, addressbackProofref] =
    useState(false);

  const [validationnErr1, setvalidationnErr1] = useState("");
  const [aadharFrontImage, setaadharFrontImage] = useState("");
  const [panImage, setpanImage] = useState("");
  const [userPhoto, setuserPhoto] = useState("");
  const [aadharBackImage, setaadharBackImage] = useState("");

  const [load1, setload1, load1ref] = useState(false);
  const [load2, setload2, load2ref] = useState(false);
  const [load3, setload3, load3ref] = useState(false);
  const [load4, setload4, load4ref] = useState(false);

  const imageUpload = (type, val) => {
    try {
      if (type == "address_proof") {
        setload1(true);
      } else if (type == "id_proof") {
        setload2(true);
      } else if (type == "photo_proof") {
        setload3(true);
      } else if (type == "address_back") {
        setload4(true);
      }
      console.log(type, "type");
      console.log(val, "val");
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
        return false;
      } else if (fileSize > 1000000) {
        toast.error("Please upload a file smaller than 1 MB");
        return false;
      } else {
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.preset);
        data.append("cloud_name", env.cloud_name);
        console.log("formdata===", data);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          {
            method: "post",
            body: data,
          }
        )
          .then((resp) => resp.json())
          .then((data) => {
            console.log("cloudinary upload===", data.secure_url);
            if (type == "address_proof") {
              setload1(false);
              setaddressProof(false);
              setaadharFrontImage(data.secure_url);
            } else if (type == "id_proof") {
              setload2(false);
              setidProof(false);
              setpanImage(data.secure_url);
            } else if (type == "photo_proof") {
              setload3(false);
              setphotoProof(false);
              setuserPhoto(data.secure_url);
            } else if (type == "address_back") {
              setload4(false);
              setaddressbackProof(false);
              setaadharBackImage(data.secure_url);
            }
          });
      }
    } catch (error) { }
  };
  const validate1 = async (values) => {
    const errors = {};

    if (aadharFrontImage == "") {
      errors.address_proof = "Aadhar front is required!";
      setaddressProof(true);
    }

    if (panImage == "") {
      errors.id_proof = "Pan card is required!";
      setidProof(true);
    }

    if (userPhoto == "") {
      errors.photo_proof = "User profile is required!";
      setphotoProof(true);
    }

    if (aadharBackImage == "") {
      errors.aadharBackImage = "Aadhar back is required!";
      setaddressbackProof(true);
    }
    setvalidationnErr1(errors);
    return errors;
  };
  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">

            {loader == true ? (
              <div class="spinner css-ezm1fk">
                <div class="css-1qoumk2"></div>
                <div class="css-1v19680"></div>
                <div class="css-1v90jie"></div>
                <div class="css-19p0rvp"></div>
              </div>
            ) : (
              <main className="main_heinght">
                <div>
                  <section className="form-deposit uniramp">
                    <div className="section_selec_ash row">
                      <div className="col-lg-12 p-3 ">
                        <div className="headin_deposur_s ">
                          <h3>
                            <i
                              class="ri-arrow-left-line cusonr"
                              onClick={() => navigate("/userdashboard")}
                            ></i>
                            <span className="pl-3">Buy Crypto</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row text-white justify-content-center ">
                        <div className="col-lg-6 newform mb-5  shadow">
                          <div className="qr_section_class">
                            {afterwalletref.current == true ? (
                              afterPhonenoref.current == true ? (
                                Otpcompleteref.current == true ? (
                                  kycstatusref.current.error == false ? (
                                    kycstatusref.current.data.kycStatus ==
                                      false ? (
                                      kycstatusref.current.data.kycStatus ==
                                        "BASIC_KYC_REJECTED" ? (
                                        <>
                                          <div className="row completeKYC">
                                            <div className="col-lg-12">
                                              <h4>Complete your KYC</h4>
                                              <h6>
                                                Please ensure you have the
                                                following documents handy for a
                                                quick annd easy completion of your
                                                KYC requirements with us.
                                              </h6>
                                              <ul className="my-5">
                                                <li> i Pan Card</li>
                                                <li>
                                                  ii Access to Digilocker/Aadhar
                                                  Card
                                                </li>
                                              </ul>
                                              <h6 className="text-danger">
                                                Note :
                                              </h6>

                                              <h6>
                                                Once you have your documents ready
                                                the initial step is Facial
                                                Verification. Please make sure you
                                                have a camera enabled device to
                                                make sure a hassle free KYC
                                                experience.
                                              </h6>
                                            </div>

                                            <div className="col-lg-12 mt-5">
                                              <div class="form_login_input">
                                                <Button
                                                  className="priomary"
                                                  data-toggle="modal"
                                                  data-target="#myModal"
                                                >
                                                  Start KYC
                                                </Button>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="crypto_coin_selec pa-_opo mt-3 ">
                                                <label className="mt-5">
                                                  KYC Under Verification
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    ) : kycstatusref.current.data.kycApproved ==
                                      true ? (
                                      <>
                                        <h5 className="text-center h3">
                                          Buy Crypto
                                        </h5>

                                        {/* <button><Link href="upi://pay?pa=abcmoney%40indpay&pn=Blockforce%20India%20Private%20Limited&am=200&tr=00goQZh2s0ds&tn=00goQZh2s0ds">Click hefre</Link></button> */}

                                        <div className="card_login py-3">
                                          <p className="mt-4">Scan QR</p>

                                          {QRdetailsref.current != "" ? (
                                            <img
                                              src={QRdetailsref.current.UPIQR}
                                              width="100px"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>



                                        <div className="card_login py-0">
                                          <div className="form_login_input">
                                            <label className="mt-4">
                                              UPI ID (PhonePe, GPay, Paytm, BHIM UPI)*
                                            </label>

                                            {/* <p>{QRdetailsref.current.upiId}</p> */}
                                            <input
                                              type="text"
                                              value={QRdetailsref.current.upiId}
                                              className="form-control"
                                              disabled
                                            />
                                          </div>
                                        </div>



                                        <div className="col-lg-12 mt-2 p-0">
                                          <div class="form_login_input card_login py-0">
                                            <Button
                                              className="priomary"
                                              onClick={submitpayment}
                                            >
                                              I have made the payment
                                            </Button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="row completeKYC">
                                          <div className="col-lg-12">
                                            <h4>Complete your KYC</h4>
                                            <h6>
                                              Please ensure you have the following
                                              documents handy for a quick annd
                                              easy completion of your KYC
                                              requirements with us.
                                            </h6>
                                            <ul className="my-5">
                                              <li> i Pan Card</li>
                                              <li>
                                                ii Access to Digilocker/Aadhar
                                                Card
                                              </li>
                                            </ul>
                                            <h6 className="text-danger">
                                              Note :
                                            </h6>

                                            <h6>
                                              Once you have your documents ready
                                              the initial step is Facial
                                              Verification. Please make sure you
                                              have a camera enabled device to make
                                              sure a hassle free KYC experience.
                                            </h6>
                                          </div>

                                          <div className="col-lg-12 mt-5">
                                            <div class="form_login_input">
                                              <Button
                                                className="priomary"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                              >
                                                Start KYC
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  ) : (
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="crypto_coin_selec pa-_opo mt-3 ">
                                          <label className="mt-5">
                                            Something Went Wrong
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  <div className="row card_login">
                                    <div className="col-lg-12">
                                      <div className="crypto_coin_selec form_login_input mt-3 ">
                                        <label className="mt-1">
                                          Enter your OTP
                                        </label>
                                      </div>
                                      <div>
                                        <div className="">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter OTP"
                                            maxLength="6" // Updated attribute to restrict input length
                                            value={Otpref.current}
                                            name="Otp"
                                            onChange={handleChange2}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-12 mt-2">
                                      <div class="form_login_input">
                                        <Button onClick={afterOTP}>
                                          Continue
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 mt-5">
                                      <div class="form_login_input">
                                        <Button onClick={afterphone}>
                                          Resend Code
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div className="row card_login">
                                  <div className="col-lg-12">
                                    <div className="crypto_coin_selec form_login_input mt-3 ">
                                      <label className="mt-1">
                                        Enter your Phone Number
                                      </label>
                                    </div>
                                    <div>
                                      <div className="">
                                        <input
                                          type="text"
                                          placeholder="Mobile Number"
                                          maxLength="10"
                                          class="form-control"
                                          value={phonenoref.current}
                                          name="phoneno"
                                          onChange={handleChange3}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div class="form_login_input">
                                      <Button onClick={afterphone}>Buy</Button>
                                    </div>
                                  </div>
                                </div>
                              )
                            ) : (
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="crypto_coin_selec pa-_opo ">
                                    <label className="mt-3">Spend</label>
                                  </div>

                                  <div className="form_swap">

                                    <div className="select_drobdoan justify-content-between">
                                      <div className="">
                                        {/* <img
                                        src="https://static.uniramp.dev/fiat/india.svg"
                                        width="25%"
                                      /> */}
                                        <p className="selectp">INR</p>
                                      </div>

                                      <i class="ri-arrow-drop-right-line px35 text-white"></i>

                                    </div>

                                    <div className="input_send">
                                      <input
                                        type="number"
                                        value={send}
                                        min="0"
                                        placeholder={`Min ${placeholderref.current}`}
                                        name="send"
                                        onChange={handleChange}
                                      />
                                    </div>


                                    <div
                                      className={`custom-div dropdown_mnyw   ${showDiv ? "show" : "hide"
                                        }`}
                                    >
                                      <div className="header">
                                        <span>
                                          <i class="ri-search-line"></i>
                                        </span>
                                        <input type="text" placeholder="Search" />
                                        <button>
                                          <i
                                            class="ri-close-fill"
                                            onClick={handleToggle}
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {(placeholderref.current > send) &
                                    (send != "") ? (
                                    <>
                                      <p className="text-danger text-right mt-2">
                                        {" "}
                                        {placeholderref.current} rupees is the
                                        minimum amount to spend to buy{" "}
                                        {SelectedCryptoref.current
                                          ? SelectedCryptoref.current.currency
                                          : "USDT"}
                                        .
                                      </p>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-lg-12">
                                  <div className="crypto_coin_selec pa-_opo ">
                                    <label className="mt-3">Receive</label>
                                  </div>
                                  <div className="form_swap">
                                    <div
                                      className="select_drobdoan justify-content-between"
                                      onClick={handleToggle1}
                                    >
                                      <div>
                                        {SelectedCryptoref.current ? (
                                          <p className="selectp ">
                                            {SelectedCryptoref.current
                                              ? SelectedCryptoref.current.currency
                                              : ""}
                                          </p>
                                        ) : (
                                          <p className="selectp ">
                                            {decurrencyref.current
                                              ? decurrencyref.current.currency
                                              : ""}
                                          </p>
                                        )}
                                      </div>

                                      <i class="ri-arrow-drop-right-line px35 text-white"></i>
                                    </div>
                                    <div className="input_send">
                                      <input
                                        type="number"
                                        placeholder=""
                                        value={receiveValueref.current.toFixed(4)}
                                        name="receiveValue"
                                      />
                                    </div>

                                    <div
                                      className={`custom-div dropdown_mnyw   ${receiveshowDiv ? "show" : "hide"
                                        }`}
                                    >
                                      <div className="header">
                                        <span>
                                          {/* <i class="ri-search-line"></i> */}
                                        </span>
                                        {/* <input type="text" placeholder="Search" /> */}
                                        <button>
                                          <i
                                            class="ri-close-fill"
                                            onClick={handleToggle1}
                                          ></i>
                                        </button>
                                      </div>
                                      <div className="body_slect">
                                        {currencyref.current.length > 0 ? (
                                          currencyref.current.map(
                                            (dataItem, i) => {
                                              return (
                                                <div
                                                  className="coin_slect"
                                                  key={i}
                                                  onClick={() =>
                                                    handleCryptoSelect(dataItem)
                                                  }
                                                >
                                                  <div
                                                    className="coin_name"
                                                    onClick={handleToggle1}
                                                  >
                                                    <div className="d-flex justify-content-between w-100">
                                                      <div className="d-flex flex-col">
                                                        <span>
                                                          {dataItem
                                                            ? dataItem.currency
                                                            : ""}
                                                        </span>
                                                        <span className="fullname">
                                                          {dataItem.tokenName}
                                                        </span>
                                                      </div>

                                                      <span className="chains">
                                                        {dataItem
                                                          ? dataItem.blockchain
                                                          : ""}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p>No --Data-- Found</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="d-flex justify-content-between mt-3 ">
                                    <label className="mt-3 mr-2">
                                      {SelectedCryptoref.current
                                        ? SelectedCryptoref.current
                                          ? `${SelectedCryptoref.current.currency} on ${SelectedCryptoref.current.tokenType} `
                                          : ""
                                        : decurrencyref.current
                                          ? `${decurrencyref.current.currency} on  ${decurrencyref.current.tokenType} `
                                          : ""}
                                    </label>
                                    <label className="mt-3 mr-2">
                                      {SelectedCryptoref.current
                                        ? `1 ${SelectedCryptoref.current.currency} =  ${exchangerateref.current.buy} ₹`
                                        : `1 ${decurrencyref.current.currency} =  ${exchangerateref.current.buy} ₹`}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="d-flex justify-content-between mt-3 ">
                                    <label className="mt-3 mr-2">
                                      INR Net Amount
                                    </label>
                                    <label className="mt-3 mr-2">
                                      {exchangerateref.current.buy *
                                        receiveValueref.current.toFixed(4)}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="d-flex justify-content-between mt-3 ">
                                    <label className="mt-3 mr-2">
                                      Network Fee
                                    </label>
                                    <label className="mt-3 mr-2">
                                      (
                                      {SelectedCryptoref.current
                                        ? SelectedCryptoref.current.networkFee
                                        : decurrencyref.current.networkFee}{" "}
                                      {SelectedCryptoref.current
                                        ? SelectedCryptoref.current.currency
                                        : decurrencyref.current.currency}
                                      )
                                    </label>
                                  </div>
                                </div>

                                {walletstateref.current == true ? (
                                  <div className="col-lg-12">
                                    <div className="pa-_opo mt-3 ">
                                      <label className="mt-5">
                                        Enter your wallet address
                                      </label>
                                    </div>
                                    <div>
                                      <div className=" mt-2 card_login p-0 mb-0">
                                        <input
                                          type="text"
                                          placeholder="EX : 0x6F65fEcA162f808EC********************"
                                          class="form-control"
                                          value={walletref.current}
                                          name="wallet"
                                          onChange={handleChange1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {walletstateref.current == true ? (
                                  <div className="col-lg-12 mt-5">
                                    <div class="form_login_input">
                                      <Button onClick={Afterwallets}>Buy</Button>
                                    </div>
                                  </div>
                                ) : send >= placeholderref.current ? (
                                  <div className="col-lg-12 mt-5">
                                    <div class="form_login_input">
                                      <Button onClick={walletsubmit}>Buy</Button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-lg-12 mt-5">
                                    <div class="form_login_input">
                                      <Button disabled>Buy</Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div
                    class="modal fade bd-example-modal-lg"
                    id="myModal"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                      <div class="modal-content select_coin">
                        <div class="modal-header">
                          <div className="headin_deposur_s ">
                            <h3>
                              <span className="pl-3">Complete your KYC</span>
                            </h3>
                          </div>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div class="modal-body pt-0">
                          <section className="form-deposit">
                            <div className="container">
                              <div className="row justify-content-center ">
                                <div className="col-lg-12 card_login login_form shadow">
                                  <div className="qr_section_class">
                                    <div className="row dashcard1">
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label className="mt-3">Name</label>

                                          <div className="">
                                            <input
                                              type="text"
                                              placeholder="Name"
                                              name="customerName"
                                              minLength={3}
                                              maxLength={15}
                                              className="form-control"
                                              value={customerName}
                                              onChange={handleChange10}
                                            />
                                          </div>
                                          <div>
                                            {setcustomerNameErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.customerName}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label>Email</label>

                                          <div className="">
                                            <input
                                              type="text"
                                              placeholder=" Email"
                                              className="form-control"
                                              name="email"
                                              value={email}
                                              onChange={handleChange10}
                                            />
                                          </div>
                                          <div>
                                            {setEmailErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.email}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label className="mt-3">
                                            Pan Number
                                          </label>

                                          <div className="">
                                            <input
                                              type="text"
                                              placeholder="Pan Number "
                                              name="pannumber"
                                              minLength={10}
                                              className="form-control"

                                              maxLength={15}
                                              value={pannumber}
                                              onChange={handleChange10}
                                            />
                                          </div>
                                          <div>
                                            {setPanNumberErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.pannumber}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label className="mt-3">
                                            Aadhar Number
                                          </label>

                                          <div className="">
                                            <input
                                              type="text"
                                              placeholder="Aadhar Number"
                                              name="aadhar"
                                              minLength={12}
                                              className="form-control"

                                              maxLength={12}
                                              value={aadhar}
                                              onChange={handleChange10}
                                            />
                                          </div>
                                          <div>
                                            {setaadharErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.aadhar}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label className="mt-3">
                                            Date Of Birth
                                          </label>

                                          <div className="">
                                            <input
                                              type="date"
                                              className="form-control"

                                              placeholder="Date of Birth"
                                              name="Dob"
                                              value={Dob}
                                              onChange={handleChange10}
                                            />
                                          </div>
                                          <div>
                                            {setDobErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.Dob}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label>Gender</label>
                                          <Dropdown
                                            placeholder="Gender"
                                            fluid
                                            selection
                                            options={options}
                                            onChange={onpaymentSelect}
                                            search
                                          />
                                          <div>
                                            {setpaymentErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.payment}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label>State</label>
                                          <div className="">
                                            <input
                                              type="text"
                                              placeholder=" State"
                                              name="state"
                                              className="form-control"

                                              value={state}
                                              onChange={handleChange10}
                                            />
                                          </div>
                                          <div>
                                            {setstateErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.state}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form_login_input crypto_coin_selec">
                                          <label>Address</label>

                                          <div className="">
                                            <input
                                              type="text"
                                              placeholder="Enter Your Address"
                                              name="address"
                                              value={address}
                                              className="form-control"

                                              onChange={handleChange10}
                                              minLength={5}
                                              maxLength={50}
                                            />
                                          </div>
                                          <div>
                                            {setaddressErrref.current ? (
                                              <p className="text-danger">
                                                {" "}
                                                {validationnErr.address}{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-6 mt-4 ">
                                        <h3 className="input_labele">
                                          Pan Card{" "}
                                        </h3>
                                        <div className="card_kyc11">
                                          <span>
                                            {panImage == "" && load2 == false ? (
                                              <i class="ri-article-fill"></i>
                                            ) : load2 == true ? (
                                              <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            ) : (
                                              <img
                                                src={panImage}
                                                className="input_section_kyc"
                                              />
                                            )}
                                          </span>
                                          <div className="input_file11">
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) =>
                                                imageUpload(
                                                  "id_proof",
                                                  e.target.files[0]
                                                )
                                              }
                                            />
                                            <p>
                                              <i class="ri-upload-2-line"></i>{" "}
                                              Upload File
                                            </p>
                                          </div>

                                          {idProofref.current == true ? (
                                            <p className="text-danger warningicon text-center">
                                              {" "}
                                              <i class="bi bi-exclamation-diamond"></i>
                                              {validationnErr1.id_proof}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mt-4 ">
                                        <h3 className="input_labele">
                                          User Profile
                                        </h3>
                                        <div className="card_kyc11">
                                          <span>
                                            {userPhoto == "" && load3 == false ? (
                                              <i class="ri-article-fill"></i>
                                            ) : load3 == true ? (
                                              <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            ) : (
                                              <img
                                                src={userPhoto}
                                                className="input_section_kyc"
                                              />
                                            )}
                                          </span>

                                          <div className="input_file11">
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) =>
                                                imageUpload(
                                                  "photo_proof",
                                                  e.target.files[0]
                                                )
                                              }
                                            />
                                            <p>
                                              <i class="ri-upload-2-line"></i>{" "}
                                              Upload File
                                            </p>
                                          </div>

                                          {photoProofref.current == true ? (
                                            <p className="text-danger text-center">
                                              {" "}
                                              <i class="bi bi-exclamation-diamond"></i>
                                              {validationnErr1.photo_proof}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mt-4 ">
                                        <h3 className="input_labele">
                                          Aadhar Front Image
                                        </h3>
                                        <div className="card_kyc11">
                                          <span>
                                            {aadharFrontImage == "" &&
                                              load1 == false ? (
                                              <i class="ri-article-fill"></i>
                                            ) : load1 == true ? (
                                              <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            ) : (
                                              <img
                                                src={aadharFrontImage}
                                                className="input_section_kyc"
                                              />
                                            )}
                                          </span>
                                          <div className="input_file11">
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) =>
                                                imageUpload(
                                                  "address_proof",
                                                  e.target.files[0]
                                                )
                                              }
                                            />
                                            <p>
                                              <i class="ri-upload-2-line"></i>{" "}
                                              Upload File
                                            </p>
                                          </div>

                                          {addressProofref.current == true ? (
                                            <p className="text-danger text-center">
                                              {" "}
                                              <i class="bi bi-exclamation-diamond"></i>
                                              {validationnErr1.address_proof}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mt-4 ">
                                        <h3 className="input_labele">
                                          Aadhar Back Image
                                        </h3>
                                        <div className="card_kyc11">
                                          <span>
                                            {aadharBackImage == "" &&
                                              load4 == false ? (
                                              <i class="ri-article-fill"></i>
                                            ) : load4 == true ? (
                                              <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                                            ) : (
                                              <img
                                                src={aadharBackImage}
                                                className="input_section_kyc"
                                              />
                                            )}
                                          </span>
                                          <div className="input_file11">
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) =>
                                                imageUpload(
                                                  "address_back",
                                                  e.target.files[0]
                                                )
                                              }
                                            />
                                            <p>
                                              <i class="ri-upload-2-line"></i>{" "}
                                              Upload File
                                            </p>
                                          </div>

                                          {addressbackProofref.current == true ? (
                                            <p className="text-danger text-center">
                                              {" "}
                                              <i class="bi bi-exclamation-diamond"></i>
                                              {validationnErr1.aadharBackImage}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-md-6 pb-5">
                                        <div className="form_login_input">
                                          <Button className="discardbtn" data-dismiss="modal"
                                            onClick={cancelData}>
                                            Discard
                                          </Button>
                                        </div>
                                      </div>

                                      <div className="col-md-6 pb-5 ">
                                        <div className="form_login_input">
                                          <Button onClick={submit}>Save</Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div class="form_login_input pading_foem mx-auto col-lg-4 d-block">
                                  <Button
                                    data-toggle="modal"
                                    data-target="#myModal1"
                                    onClick={submit}
                                  >
                                    Submit
                                  </Button>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        <div class="modal-footer">
                          <div>
                            <Button
                              className="mr-2"
                              data-dismiss="modal"
                              onClick={cancelData}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={submit}
                            // data-dismiss="modal"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
