import React, { useEffect, useRef } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Sidebar from "./Sidebarlink";
import { toast } from "react-toastify";
import { IconCopy } from "@tabler/icons-react";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";

function Home() {
  useEffect(() => {
    getTfastatus();
  }, []);

  const textRef1 = useRef(null);

  const handleCopy1 = () => {
    const textToCopy = textRef1.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showsuccessToast("Code Copied");
      })
      .catch((error) => {
        showerrorToast("Failed to copy code");
      });
  };
  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    if (sanitizedValue.length < 7) {
      settfaCode(sanitizedValue);
    }

  };

  const [tfaUrl, settfaUrl] = useState("");
  const [userMail, setuserMail] = useState("");
  const [tfaCode, settfaCode] = useState("");
  const [tfaStatus, settfaStatus] = useState("");
  const [Keystatus, setKeystatus] = useState("");
  const [ValidationErrors, setValidationErrors] = useState({});

  const getTfastatus = async () => {
    var data = {
      apiUrl: apiService.getTfaDetials,
    };

    var resp = await getMethod(data);
    console.log(resp, "=-=-=-resp");
    settfaUrl(resp.data.tfa_url);
    setuserMail(resp.data.email);
    settfaStatus(resp.data.tfastatus);
    setKeystatus(resp.data.tfaenablekey);

    localStorage.setItem("tfa_status", resp.data.tfastatus);
  };

  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      setValidationErrors({
        tfaCode: "2FA code is required",
      });
    } else {
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);

      if (resp.status == true) {
        toast.success(resp.Message);
        settfaCode("");
        getTfastatus();
      } else {
        toast.error(resp.Message);
        settfaCode("");
        getTfastatus();

      }

    }
  };
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <div className="section_bg_ash">
              <div className="section_selec_ash">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="headin_deposur_s">
                        <h3>Two Factor Authentication</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner_conteten pb-5">
                <div className="dashcard1 p-3 mb-4">
                  <div className="contant_secion_balance">
                    <div className="d-flex justify-content-between">
                      <h3 className="heading_sub_section">
                        <span
                          className={`${tfaStatus == 0 ? "text-danger" : "text-white"
                            }`}
                        >
                          Disabled
                        </span>

                        {"  "} 2FA

                      </h3>
                    </div>
                    <p className="mt-0 mb-0 paragraph">
                      If you want to turn off 2FA, input your account password
                      and the six-digit code provided by the Google
                      Authenticator app below, then click "Disable 2FA".
                      <br /> <br />
                    </p>

                    <div className="d-flex justify-content-between">
                      <h3 className="heading_sub_section mt-3">
                        Enable{" "}
                        <span
                          className={`${tfaStatus == 1 ? "text-success" : "text-white"
                            }`}
                        >
                          2FA
                        </span>
                      </h3>
                    </div>
                    <p className="mt-0 mb-0 paragraph">
                      Enter the six-digit code provided by the Google
                      Authenticator app to Disable the 2FA verification
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mb-5">
                <div className="col-lg-6">
                  <div className="card_login mr17 shadow">
                    <div>
                      <div class="form_login_input">
                        <label>Email</label>
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          disabled
                          value={userMail}
                        />
                      </div>



                      {tfaStatus == 0 ? (
                        <>
                          <div className="form_login_input">
                            <label>Security Key</label>
                            <p
                              className="d-flex justify-content-between form-control"
                              ref={textRef1}
                            >
                              {Keystatus}
                              <IconCopy onClick={handleCopy1} />
                            </p>
                          </div>
                          <div className="qr_code">
                            <img src={tfaUrl} />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div class="form_login_input">
                        <label>2FA Code</label>
                        <input
                          type="Number"
                          class="form-control"
                          placeholder="Enter 2FA Code"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(
                              evt.key
                            ) && evt.preventDefault()
                          }
                          value={tfaCode}
                          name="email"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="form_login_input ">
                      <Button className="mt-5" onClick={handleSubmit}>
                        {tfaStatus === 0 ? "Enable 2FA" : "Disable 2FA"}{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
