import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { IconLockCancel } from "@tabler/icons-react";
import { IconEye } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { IconLoaderQuarter } from "@tabler/icons-react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { env } from ".././core/service/envconfig";
import { IconCopy } from "@tabler/icons-react";

function Home() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);

  const [Invoicedata, SetInvoicedata, Invoicedataref] = useState("");
  const [deleteitem, Setdeleteitem, deleteitemref] = useState("");

  const textRef1 = useRef(null);

  const handleCopy1 = () => {
    const textToCopy = `${env.BaseURL}/userpayment/${Invoicedataref.current._id}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showsuccessToast("Code Copied");
      })
      .catch((error) => {
        showerrorToast("Failed to copy code");
      });
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const toggle = async (e) => {
    console.log("e====", e);
    setModal(!modal);
    SetInvoicedata(e);
  };

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
    Setdeleteitem(e);
  };
  const goto = () => setModal(!modal);
  const goto1 = () => setModal1(!modal1);

  const [Merchantdetails, SetMerchantdetails] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currentpage, Setcurrentpage] = useState(1);
  const [totalpage, Settotalpage] = useState(0);
  useEffect(() => {
    getmerchantdetails();
  }, [0]);

  const recordpage = 5;
  const pagerange = 5;
  const historychanges = (pagenumber) => {
    getmerchantdetails(pagenumber);
    Setcurrentpage(pagenumber);
  };
  const getmerchantdetails = async (page) => {
    try {
      setLoader(true);
      var data = {
        apiUrl: apiService.Getmerchantdetails,
        payload: {
          FilPerpage: 5,
          FilPage: page,
        },
      };
      var resp = await postMethod(data);
      console.log("getmerchantdetails===", resp);

      if (resp) {
        setLoader(false);
        console.log(resp.obj, "=-=-respobj-=-=");
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
        SetMerchantdetails(resp.obj);
        Settotalpage(resp.total);
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const deletemerchant = async (deleteData) => {
    try {
      var data = {
        apiUrl: apiService.deletmerchant,
        payload: deleteData,
      };
      var resp = await postMethod(data);
      setModal1(!modal1);

      if (resp.status) {
        getmerchantdetails();
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  const rawHTML = `<iframe src="${env.BaseURL}/userpayment/${Invoicedataref.current._id}" 
  height="600px" width="350px" title="Buy with Gatecrypto Pay"></iframe>`;



  const textRef2 = useRef(null);

  const handleCopy2 = () => {
    const textToCopy = textRef2.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showsuccessToast("Link Copied");
      })
      .catch((error) => {
        showerrorToast("Failed to copy Link");
      });
  };


  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                          <div className="headin_deposur_s">
                            <h3>Checkouts</h3>
                          </div>
                          <div class="form_login_input">
                            <Button
                              className="button_colore"
                              onClick={() => navigate("/create-cheeckout")}
                            >
                              Create
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-lg-12 shadow">
                            <div className="market_tabel1 w-100 mt-0 p-3">
                              <div className="market_section dashcard1 p-3 bg_none">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        {/* <th>S.NO</th> */}
                                        <th className="leftborder">
                                          Created Date
                                        </th>
                                        <th>Product Logo</th>
                                        <th>Product Name</th>
                                        <th>Amount</th>
                                        <th className="rightborder">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Merchantdetails &&
                                        Merchantdetails.length > 0 ? (
                                        Merchantdetails.map((item, i) => {
                                          return (
                                            <>
                                              <tr>
                                                <td className="td_amount">
                                                  {" "}
                                                  <p>
                                                    {moment(
                                                      item.createdAt
                                                    ).format("lll")}
                                                  </p>
                                                </td>

                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="contents">
                                                      <img
                                                        width="35px"
                                                        className="d-block mx-auto"
                                                        src={item.Logourl}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>

                                                <td className="td_amount">
                                                  {" "}
                                                  <p>{item.Name}</p>
                                                </td>
                                                <td className="td_amount">
                                                  {" "}
                                                  <p>
                                                    {item.Fixed != null
                                                      ? ` ${item.Fixed} USD`
                                                      : "Donation"}
                                                  </p>
                                                </td>

                                                <td className="td_amount">
                                                  {buttonLoader == false ? (
                                                    <>
                                                      <Link
                                                        // to={`/checkouts/${item._id}`}
                                                        className="curspoint viewicon"
                                                        onClick={(e) =>
                                                          toggle(item)
                                                        }
                                                      >
                                                        View
                                                      </Link>

                                                      <IconTrash
                                                        className="icon_danger"
                                                        onClick={(e) =>
                                                          toggle1(item)
                                                        }
                                                      />
                                                    </>
                                                  ) : (
                                                    <IconLoaderQuarter className="icon_danger" />
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan="5"
                                          >
                                            <img src={require('../Image/nodata.png')} className="mt-5" />
                                            <p className="mb-5 mt-3 nodatafound">No data Found</p>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>

                                </div>


                                {Merchantdetails &&
                                  Merchantdetails.length > 0 ? (

                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={currentpage}
                                    itemsCountPerPage={recordpage}
                                    totalItemsCount={totalpage}
                                    pageRangeDisplayed={pagerange}
                                    onChange={historychanges}
                                  />
                                ) : ""}


                                {/* <iframe src="http://localhost:3000/userpayment/6641e70b8bc6396b2e6698f4" height="600px" width="350px" title="Buy with Gatecrypto Pay"></iframe> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className="rem10 checkout_modal"
                  >
                    <ModalBody className="p-0">
                      <div class="Frame-14722">
                        <span class="Item-Details">Item Details</span>
                        <div class="">
                          <i class="ri-close-fill  Close" onClick={goto}></i>
                        </div>
                      </div>

                      <div class="">
                        <div class="Frame-14699">
                          <span class="Leftsides">Name</span>
                          <span class="rightsides">
                            {Invoicedataref.current.Name}
                          </span>
                        </div>

                        <div class="Frame-14699">
                          <span class="Leftsides">Price</span>
                          <span class="rightsides">
                            {Invoicedataref.current.Fixed}{" "}
                            {Invoicedataref.current.currency}
                          </span>
                        </div>
                        <div class="Frame-14699">
                          <span class="Leftsides">Description</span>
                          <span class="rightsides">
                            {Invoicedataref.current.Description}
                          </span>
                        </div>
                        <div class="Frame-14699">
                          <span class="Leftsides">Share a link</span>
                          <span class="rightsides" ref={textRef1}>
                            {Invoicedataref.current === ""
                              ? "Connect Wallet"
                              : `${env.BaseURL
                              }/userpayment/${Invoicedataref.current?._id?.substring(
                                0,
                                1
                              )}...`}
                            <IconCopy
                              className="copyicon"
                              onClick={handleCopy1}
                            />

                            {``}
                          </span>
                        </div>
                      </div>

                      <div>
                        <h6 className="Embedcheckout">Embed checkout</h6>

                        <div class="Frame-14725">
                          <span className="frmaecontent" ref={textRef2}>
                            {rawHTML}
                          </span>
                          <IconCopy
                            className="copyicon"
                            onClick={handleCopy2}
                          />
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>

                  <Modal
                    isOpen={modal1}
                    toggle={toggle1}
                    className="rem10 checkout_modal"
                  >
                    <ModalBody className="p-0">
                      <div class="Frame-14722">
                        <span class="Item-Details"><i class="ri-alert-line text-danger"></i> Delete Checkout</span>
                        <div class="">
                          <i class="ri-close-fill  Close" onClick={goto1}></i>
                        </div>
                      </div>

                      <div className="py-2">
                        <p className="Deletecontent">
                          Are you sure you want to delete this checkout detail ?
                        </p>

                        <div className="row card_login flex-row pb-0 pt-2">
                          <div className="col-md-6">
                            <div className="form_login_input">
                              <Button className="discardbtn" onClick={goto1}>
                                Discard
                              </Button>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form_login_input">
                              <Button
                                onClick={(e) =>
                                  deletemerchant(deleteitemref.current._id)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>

                      </div>

                    </ModalBody>
                  </Modal>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
