import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { decryptData } from "../core/service/common.api";
import { generatepostMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Home() {
    const navigate = useNavigate();

    const [Merchantdetails, SetMerchantdetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [totalpage, Settotalpage] = useState(0);
    const [Secert, setSecert, Secertref] = useState("");
    const [API, setAPI, APIref] = useState("");
    const [Label, setLabel, Labelref] = useState("");
    const [userLabel, setuserLabel, userLabelref] = useState("");
    const [labelError, setLabelError, labelErrorref] = useState("");
    const [otpError, setOtpError, otpErrorref] = useState("");
    const [qrcode, Setqrcode, qrcoderef] = useState("");
    const [TfaKey, setTfaKey, TfaKeyref] = useState("");

    const handleChange = (e) => {
        const value = e.target.value;
        const sanitizedValue = value.replace(/\s/g, "");
        if (/\s/.test(sanitizedValue)) {
            setLabelError("URL cannot contain spaces");
        } else if (sanitizedValue.length < 4 || sanitizedValue.length > 50) {
            setLabelError("URL must be between 4 and 50 characters");
        } else if (
            !/^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|localhost)(:\d{1,5})?(\/[^\s]*)?$/.test(
                sanitizedValue
            )
        ) {
            setLabelError("Invalid Webhook URL");
        } else {
            setLabelError("");
        }
        setLabel(sanitizedValue);
    };

    const gotoEnable = async () => {
        navigate("/security_tfa");
    };

    const [OTP, setOTP, OTPref] = useState("");
    const [tfaStatus, settfaStatus] = useState("");

    const handleChangeOTP = (e) => {
        const value = e.target.value;
        const sanitizedValue = value.replace(/\s/g, "");

        if (/\s/.test(sanitizedValue)) {
            setOtpError("OTP cannot contain spaces");
        } else {
            setOtpError("");
        }

        if (sanitizedValue.length < 7) {
            setOTP(sanitizedValue);
        }
    };

    const [modal1, setModal1] = useState(false);

    const toggle1 = async (e) => {
        console.log("e====", e);
        setModal1(!modal1);
    };

    const goto1 = () => setModal1(!modal1);

    const [modal2, setModal2] = useState(false);

    const toggle2 = async (e) => {
        console.log("e====", e);
        setModal2(!modal2);
    };

    const goto2 = () => setModal2(!modal2);

    useEffect(() => {
        GetApi();
        // Getresponse();
        getTfastatus();
    }, []);

    const getTfastatus = async () => {
        var data = {
            apiUrl: apiService.getTfaDetials,
        };

        var resp = await getMethod(data);
        console.log(resp, "=-=-=-resp");
        settfaStatus(resp.data.tfastatus);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
    };

    const GetApi = async () => {
        try {
            setLoader(true);
            var data = {
                apiUrl: apiService.getwebhook,
            };
            var resp = await getMethod(data);
            console.log("getmerchantdetails===", resp);
            setLoader(false);

            if (resp.status == true) {
                setAPI(resp.obj.ClientID);
                setSecert(resp.obj.webhookUrl);
                setuserLabel(resp.obj.ClientID);
                Setqrcode(resp.code);
            } else {
                console.log(resp, "-=-=-resp=-=-=-resp==-resp");
            }
        } catch (error) {
            console.log(error, "=-=error=-=-=");
        }
    };

    const CreateAPI = async () => {
        if (labelError || otpError || !Label || !OTP) {
            console.log("Form validation failed");

            if (otpErrorref.current == "") {
                setOtpError("OTP is required");
            }
            return;
        }

        try {
            setLoader(true);
            if (OTPref.current != "" && OTPref.current <= 999999) {
                const formValue = {
                    OTP: OTPref.current,
                    webhookUrl: Labelref.current,
                };

                var data = {
                    apiUrl: apiService.createwebhook,
                    payload: formValue,
                };
                var resp = await postMethod(data);
                console.log("getmerchantdetails===", resp);
                setLoader(false);
                setOTP("");

                if (resp.status == true) {
                    goto2();
                    GetApi();
                    toast.success(resp.Message);
                } else {
                    toast.error(resp.Message);
                    goto2();

                    console.log(resp, "-=-=-resp=-=-=-resp==-resp");
                }
            } else {
                console.log("OTP is required");
            }
        } catch (error) {
            console.log(error, "=-=error=-=-=");
        }
    };

    const APIOTP = async () => {
        if (labelError || !Label) {
            if (labelErrorref.current == "") {
                setLabelError("Label is required");
            }
            console.log("Form validation failed");
            return;
        }
        try {
            toggle1();
            toggle2();
        } catch (error) {
            console.log(error, "=-=error=-=-=");
        }
    };

    const copy = (content) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(content);
            showsuccessToast("Copied successfully");
        } else {
            showerrorToast("Link not copied, please try after sometimes");
        }
    };


    const showsuccessToast = (message) => {
        toast.dismiss();
        toast.success(message);
      };
      
      const showerrorToast = (message) => {
        toast.dismiss();
        toast.error(message);
      };
    

    return (
        <div className="header">
            <Header />
            <div className="css-1wr4jig">
                <div className="css-1o43t2y">
                    <div className="css-1t8cbiy">
                        <Sidebar />
                    </div>
                    <div className="css-1h690ep">
                        <main className="main_heinght">
                            {loader == true ? (
                                <div class="spinner css-ezm1fk">
                                    <div class="css-1qoumk2"></div>
                                    <div class="css-1v19680"></div>
                                    <div class="css-1v90jie"></div>
                                    <div class="css-19p0rvp"></div>
                                </div>
                            ) : (
                                <>
                                    <section className="deposut_section">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                                                    <div className="headin_deposur_s">
                                                        <h3>WebHook Management</h3>
                                                    </div>
                                                    <div class="form_login_input">
                                                        {userLabelref.current == "" ? (
                                                            <Button
                                                                className="button_colore"
                                                                onClick={toggle1}
                                                            >
                                                                Add
                                                            </Button>
                                                        ) : APIref.current == "" &&
                                                            Secertref.current == "" ? (
                                                            <Button
                                                                className="button_colore"
                                                                onClick={toggle2}
                                                            >
                                                                Update
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="button_colore"
                                                                onClick={toggle1}
                                                            >
                                                                Update
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-4">
                                                    <div className="dashcard1 w-100 p-3  mb-4">
                                                        <div className="contant_secion_balance">
                                                            <div className="d-flex justify-content-between">
                                                                <h3 className="heading_sub_section">Note :</h3>
                                                            </div>
                                                            <p className="mb-3 mb-0 paragraph">
                                                                1. Never share your webhook URLs in public
                                                                repositories, forums, or any other publicly
                                                                accessible locations.
                                                            </p>
                                                            <p className="my-3 mb-0 paragraph">
                                                                2. Store webhook URLs in environment variables
                                                                or use secure key management services, rather
                                                                than hardcoding them in your codebase.
                                                            </p>
                                                            <p className="my-3 mb-0 paragraph">
                                                                3. You will not be able to create a webhook if
                                                                certain requirements are not met.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {Secertref.current != "" && APIref.current != "" ? (
                                                    <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                                                        <div className="dashcard1 w-100 p-3  mb-2">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <div className="qr_image_and_address">
                                                                        <div className="img_padding">
                                                                            <img
                                                                                src={qrcoderef.current}
                                                                                className="blackshow bg-white rounded p-1 mx-auto d-block mb-5"
                                                                                width="200px"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="APIcards">
                                                                        <label>Event</label>
                                                                        <p className="d-flex justify-content-between mb-0">
                                                                            {Secertref.current ? (
                                                                                <>
                                                                                    <span>Payment Process</span>
                                                                                </>
                                                                            ) : (
                                                                                <span>{"Add Webhook URL"}</span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="APIcards">
                                                                        <label>Client ID</label>
                                                                        <p className="d-flex justify-content-between">
                                                                            {APIref.current ? (
                                                                                <>
                                                                                    <span>{APIref.current}</span>
                                                                                </>
                                                                            ) : (
                                                                                <span>{"Add WebHook URL "}</span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="APIcards">
                                                                        <label>WebHook URL</label>
                                                                        <p className="d-flex justify-content-between mb-0">
                                                                            {Secertref.current ? (
                                                                                <>
                                                                                    <span className="webhookurl">
                                                                                        {Secertref.current}
                                                                                    </span>

                                                                                    <i
                                                                                        onClick={() =>
                                                                                            copy(Secertref.current)
                                                                                        }
                                                                                    >
                                                                                        Copy
                                                                                    </i>
                                                                                </>
                                                                            ) : (
                                                                                <span>{"Add Webhook URL"}</span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                                                            <div className="dashcard1 w-100 p-3  mb-2">
                                                                <div className="contant_secion_balance">
                                                                    <img
                                                                        src={require("../Image/nodata.png")}
                                                                        className="mt-5 mx-auto d-block"
                                                                    />
                                                                    <p className="my-4 mx-0 text-center nodatafound f_14">
                                                                        Your has not created <br />
                                                                        any API key yet
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                </>
                            )}

                            <Modal
                                isOpen={modal1}
                                toggle={toggle1}
                                className="rem10 checkout_modal"
                            >
                                <ModalBody className="p-0">
                                    <div class="Frame-14722">
                                        <span class="Item-Details h2">Add WebHook</span>
                                        <div class="">
                                            <i class="ri-close-fill  Close" onClick={goto2}></i>
                                        </div>
                                    </div>
                                    {tfaStatus == 0 ? (
                                        <>
                                            <div className="">
                                                <div className="card_login px-0 shadow">
                                                    <div>
                                                        <h2 className="text-white text-center">
                                                            Please Enable the 2FA process to gain access to
                                                            the page.
                                                        </h2>
                                                        <div className="row card_login px-0 flex-row pb-0 pt-2">
                                                            <div className="col-md-6">
                                                                <div className="form_login_input">
                                                                    <Button
                                                                        className="discardbtn"
                                                                        onClick={goto1}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form_login_input">
                                                                    <Button onClick={gotoEnable}>
                                                                        Enable 2FA
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="">
                                                <div className="card_login px-0 shadow">
                                                    <div>
                                                        <div class="form_login_input">
                                                            <label>WebHook URL</label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                placeholder="Enter WebHook URL"
                                                                name="otp"
                                                                minLength={4}
                                                                maxLength={50}
                                                                value={Label}
                                                                onChange={handleChange}
                                                            />
                                                            {labelErrorref.current != "" ? (
                                                                <p className="text-danger bot22">
                                                                    {" "}
                                                                    {labelErrorref.current}{" "}
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>

                                                        <div className="row card_login px-0 flex-row pb-0 pt-2">
                                                            <div className="col-md-6">
                                                                <div className="form_login_input my-2">
                                                                    <Button
                                                                        className="discardbtn"
                                                                        onClick={goto1}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form_login_input my-2">
                                                                    <Button onClick={APIOTP}>Next</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </ModalBody>
                            </Modal>

                            <Modal
                                isOpen={modal2}
                                toggle={toggle2}
                                className="rem10 checkout_modal"
                            >
                                <ModalBody className="p-0">
                                    <div class="Frame-14722">
                                        <span class="Item-Details h2">
                                            Authenticator App Verification
                                        </span>
                                        <div class="">
                                            <i class="ri-close-fill  Close" onClick={goto2}></i>
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <p className="Deletecontent1 mt-4">
                                            Enter the 6-digit code generated by the Authenticator App
                                        </p>
                                        <div className="card_login px-0 py-2 shadow">
                                            <div>
                                                <div class="form_login_input">
                                                    <label>Authenticator OTP</label>
                                                    <input
                                                        type="Number"
                                                        class="form-control"
                                                        placeholder="Enter OTP"
                                                        name="otp"
                                                        onKeyDown={(evt) =>
                                                            ["e", "E", "+", "-"].includes(
                                                                evt.key
                                                            ) && evt.preventDefault()
                                                        }
                                                        value={OTP}
                                                        onChange={handleChangeOTP}
                                                    />
                                                    {otpErrorref.current != "" ? (
                                                        <p className="text-danger  bot22">
                                                            {" "}
                                                            {otpErrorref.current}{" "}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="row card_login px-0 flex-row pb-0 pt-2">
                                                    <div className="col-md-12">
                                                        <div className="form_login_input">
                                                            <Button onClick={CreateAPI}>Submit</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
