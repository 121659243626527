import React, {useEffect, useRef} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {IconLockCancel} from "@tabler/icons-react";
import {Dropdown} from "semantic-ui-react";
import moment from "moment";
import Pagination from "react-js-pagination";
import {Link, useNavigate} from "react-router-dom";
import Sidebar from "./Sidebarlink";
import {IconCopy} from "@tabler/icons-react";

function ViewInvoice() {
  const textRef2 = useRef(null);
  const textRef1 = useRef(null);
  const navigate = useNavigate();

  const [invoiceDetails, SetinvoiceDetails] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    var link = window.location.href.split("/")[4];
    console.log(link, "link");

    getinvoicedetails(link);
  }, [0]);

  const getinvoicedetails = async (link) => {
    try {
      console.log(link, "jsfjsadklfjskadjfkl");
      setLoader(true);
      var obj = {
        link: link,
      };
      var data = {
        apiUrl: apiService.getinvoicedetails,
        payload: obj,
      };
      var resp = await postMethod(data);
      SetinvoiceDetails(resp.data);
      console.log("getmerchantdetails===", resp.data);
      if (resp.status == true) {
        setLoader(false);
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 d-flex">
                          <div className="headin_deposur_s ">
                            <h3>
                              <i
                                class="ri-arrow-left-line cusonr"
                                onClick={() => navigate("/invoice")}
                              ></i>
                              <span className="pl-3">Invoice details</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container">
                      <div className="row justify-content-center payment_burron">
                        <div className="col-lg-12 card_login">
                          <h3 className="heading_class_recent p-3">
                            Item details
                          </h3>

                          <div className="table-responsive">
                            <table class="table checkout_table">
                              <tbody>
                                <tr>
                                  <td>
                                    <p className="h5">Invoice Id </p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p> {invoiceDetails.invoiceid}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Total Amount</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>
                                      {invoiceDetails.totalamount}{" "}
                                      {invoiceDetails.currency}{" "}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Due Date</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>
                                      {" "}
                                      {moment(invoiceDetails.due).format("lll")}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Created Date</p>
                                  </td>
                                  <td className="text-right">
                                    <p>
                                      {" "}
                                      {moment(invoiceDetails.created_at).format(
                                        "lll"
                                      )}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Status</p>
                                  </td>
                                  {invoiceDetails.status == 0 ? (
                                    <td className="text-right">
                                      {" "}
                                      <p>No View</p>
                                    </td>
                                  ) : invoiceDetails.status == 1 ? (
                                    <td className="text-right">
                                      {" "}
                                      <p>viewed</p>
                                    </td>
                                  ) : invoiceDetails.status == 2 ? (
                                    <td className="text-right">
                                      {" "}
                                      <p>Open</p>
                                    </td>
                                  ) : invoiceDetails.status == 3 ? (
                                    <td className="text-right">
                                      {" "}
                                      <p>Paid</p>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Memo</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>{invoiceDetails.memo}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Email</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>{invoiceDetails.email}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Name</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>{invoiceDetails.cutomerName}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h5">Share a link</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p> {invoiceDetails.link}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <div class="modal fade" id="myModal3" role="dialog">
        <div class="modal-dialog modal-lg popup_ik modal-dialog-centered">
          <div class="modal-content bg_ik ">
            <div className="col-lg-12">
              <div class="modal-header">
                <h3 className="heading_class_recent p-3">Voiding Invoice</h3>
                <button
                  type="button"
                  class="close close close_ik"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
            </div>
            <div class="modal-body">
              <div className="row justify-content-center payment_burron">
                <div className="col-lg-10 border">
                  <div className="qr_section_class">
                    <div>
                      <img
                        width="70"
                        height="70"
                        className="d-block mx-auto mb-3"
                        src="https://res.cloudinary.com/dpnvbcrn2/image/upload/v1690955950/images_1_ywsniw.png"
                      />
                    </div>
                    <p>
                      Be Voiding your invoice,your Customer will no longer be
                      able to pay.If they have already initiated a payment,it
                      will a "Delayed" status once confirmed and may need your
                      attention.
                    </p>
                    {/* <div className="crypto_coin_selec pa-_opo">
                      <div className="flex_coin_copy">
                        <p>Invoice to {invoiceDetails.cutomerName}</p><br/>
                        <h6> {invoiceDetails.invoiceid} {moment(invoiceDetails.updated_at).format(
                                        "lll"
                                      )}</h6>
                      </div>
                    </div> */}
                    <div className="border rounded ">
                      <h6>Invoice to {invoiceDetails.cutomerName}</h6>
                      <br />
                      <p>
                        {" "}
                        {invoiceDetails.invoiceid}{" "}
                        {moment(invoiceDetails.updated_at).format("lll")}
                      </p>
                      <p className="justify-content-right">
                        $ {invoiceDetails.amount}
                      </p>
                    </div>
                  </div>
                  <div class="form_login_input">
                    <Button>Loading...</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewInvoice;
