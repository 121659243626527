import { Dropdown, Loader, Input } from "semantic-ui-react";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import "semantic-ui-css/semantic.min.css";
import axios from "axios";
import useState from "react-usestateref";
import { Button } from "@mui/material";
import { IconCopy } from "@tabler/icons-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const moment = require("moment");

function Invoicecharge() {
    const textRef1 = useRef(null);
    const handleCopy1 = () => {
        const textToCopy = addressref.current;
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                toast.success("Code Copied");
            })
            .catch((error) => {
                toast.error("Failed to copy code");
            });
    };
    const textRef2 = useRef(null);

    const handleCopy2 = () => {
        const textToCopy = textRef2.current.innerText;
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                toast.success("Code Copied");
            })
            .catch((error) => {
                toast.error("Failed to copy code");
            });
    };
    const navigate = useNavigate();
    const initialFormValue = { email: "", walletaddress: "", payid: "", otp: "" };
    const [modal, setModal] = useState(false);
    const [formValue, setFormValue] = useState(initialFormValue);
    const [emailErr, SetemailErr, emailErrref] = useState(false);
    const [walletaddressErr, SetwalletaddressErr, walletaddressErrref] = useState(false);
    const [payidErr, SetpayidErr, payidErrref] = useState(false);
    const [ButtonLoader, setButtonLoader] = useState(false);
    const [loader, Setloader] = useState(false);
    const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);
    const [button, setbutton] = useState("");
    const [Checkoutdetails, SetCheckoutdetails, Checkoutdetailsref] = useState(
        []
    );
    const [process, Setprocess] = useState(true);
    const [pay, Setpay] = useState(false);
    const [validationnErr, setvalidationnErr] = useState("");
    const [submit, Setsubmit] = useState(false);
    const [fromcurrency, Setfromcurrency, fromcurrencyref] = useState("");
    const [tocurrency, Settocurrency, tocurrencyref] = useState("");
    const [totalamount, Settotalamount, totalamountref] = useState("");
    const [address, Setaddress, addressref] = useState("");
    const [currency, setCurrency, currencyref] = useState("");
    const [currencyid, setcurrencyid, currencyidref] = useState("");

    const [uuids, setuuids, uuidsref] = useState("");


    const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
    const { payid, email, otp, walletaddress } = formValue;
    const [Terms, setTerms] = useState(false);
    const [termsValidation, setTermsValidation, termsValidationref] =
        useState(false);
    const [isChecked, setIschecked] = useState(false);

    const toggle = () => setModal(!modal);
    const goto = () => navigate("/");
    useEffect(() => {
        var link = window.location.href.split("/")[4];
        console.log(link);
        checkoutdetails(link);
    }, [0]);

    const handleTerms = (event) => {
        if (event.target.checked) {
            setTerms(true);
        } else {
            setTerms(false);
        }
        setIschecked((current) => !current);
    };

    const Proceed = async () => {

        console.log(formValue, "formValue");

        validate(formValue);

        if (currency != "" && emailErrref.current == false && walletaddressErrref.current == false) {
            var obj = {
                currency: currencyref.current,
                currId: currencyidref.current,
                MerchatId: Checkoutdetails.userId,
                walletaddress: formValue.walletaddress,
            };
            var data = {
                apiUrl: apiService.generateMerchatAddress,
                payload: obj,
            };
            setButtonLoader(false);

            var resp = await postMethod(data);
            setButtonLoader(false);


            console.log(resp);
            if (resp.status == true) {
                Setaddress(resp.Message.address);

                Setpay(true);
                Setprocess(false);
            }
        } else {
            console.log("please select the currency");
        }



    };

    // const Proceed = async () => {
    //     validate(formValue);
    //     console.log(currency, "setCurrencyErrref");
    //     Setfromcurrency(Checkoutdetails.currency);

    //     Settocurrency(currency);
    //     if (currency != "" && emailErrref.current == false) {
    //         const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${Checkoutdetails.currency}&tsyms=${currencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
    //         setButtonLoader(true);
    //         axios
    //             .get(apiUrl)
    //             .then(async (response) => {
    //                 var obj = { link: Checkoutdetails.userId,
    //                 currency: currencyref.current };
    //                 var data = {
    //                     apiUrl: apiService.getaddress,
    //                     payload: obj,
    //                 };
    //                 var resp = await postMethod(data);
    //                 if (resp.status == true) {
    //                     Setaddress(resp.address);
    //                 } else {

    //                 }

    //                 if (response.data.Response != "Error") {
    //                     console.log(response.data, "response");

    //                     var value =
    //                         response.data[Checkoutdetails.currency][currencyref.current];
    //                     console.log(value, "value");
    //                     var total = value * Checkoutdetails.Fixed;
    //                     Settotalamount(total);
    //                     console.log(totalamountref.current);
    //                     setButtonLoader(false);
    //                     Setpay(true);
    //                     Setprocess(false);
    //                     SetemailErr(false);
    //                     SetpayidErr(false);
    //                 } else {
    //                     toast.error("This Currency Cannot Working");
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("An error occurred:", error);
    //                 toast.error("Something Went Wrong");
    //             });
    //     } else {
    //         console.log("please select the currency");
    //     }
    // };

    const Pay = async () => {
        validate(formValue);
        console.log("hello");
        if (termsValidationref.current == false) {
            var obj = {
                productId: Checkoutdetails.Name,
                MerchantId: Checkoutdetails.userId,
                email: formValue.email,
                walletaddress: formValue.walletaddress,
                address: addressref.current,
                amount: totalamountref.current,
                currency: currencyref.current,
            };
            var data = {
                apiUrl: apiService.Depositsubmit,
                payload: obj,
            };
            Setsubmit(true);
            var resp = await postMethod(data);
            if (resp) {
                if (resp.status) {
                    toast.success(resp.Message);
                    setuuids(resp.data);
                    setModal(!modal);
                    Setpay(false);
                } else {
                    toast.error(resp.Message);
                    navigate("/");
                    // setModal(!modal)
                }
            } else {
                console.log("Something Went Wrong");
            }
        } else {
            console.log("Something Went Wrong");
        }
    };

    const checkoutdetails = async (link) => {
        try {
            Setloader(true);
            var obj = { link: link };
            var data = {
                apiUrl: apiService.checkoutdetails,
                payload: obj,
            };
            var resp = await postMethod(data);
            console.log("getmerchantdetails===", resp);
            if (resp) {
                Setloader(false);
                var currArrayFiat = [];
                var data = resp.Currency;
                console.log(data, "datadatadatadatadata");

                for (var i = 0; i < data.length; i++) {
                    var obj = {
                        value: data[i]._id,
                        key: data[i].currencySymbol,
                        text: data[i].currencySymbol,
                        image: {
                            avatar: true,
                            src: data[i].Currency_image,
                        },
                        coinType: data[i].coinType,
                        erc20token: data[i].erc20token,
                        bep20token: data[i].bep20token,
                        trc20token: data[i].trc20token,
                        rptc20token: data[i].rptc20token,
                    };
                    currArrayFiat.push(obj);
                }
                console.log(currArrayFiat);
                setToCurrencyRef(currArrayFiat);
                SetCheckoutdetails(resp.obj);
            } else {
                console.log(resp, "-=-=-resp=-=-=-resp==-resp");
            }
        } catch (error) {
            console.log(error, "=-=error=-=-=");
        }
    };
    const handleChange = async (e) => {
        console.log("e====", e);
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        console.log("formData====", formData);
        setFormValue(formData);
    };
    const validate = async (values) => {
        const errors = {};
        if (currency == "") {
            errors.currency = "Currency is required !";
            setCurrencyErr(true);
        } else {
            setCurrencyErr(false);
        }

        if (!Terms) {
            errors.terms = "Terms is a required field";
            setTermsValidation(true);
        } else {
            errors.terms = "";
            setTermsValidation(false);
        }

        if (values.email == "") {
            errors.email = "email is required !";
            SetemailErr(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
            SetemailErr(true);
        } else {
            SetemailErr(false);
        }

        if (values.walletaddress == "") {
            errors.walletaddress = "wallet address is required !";
            SetwalletaddressErr(true);
        } else {
            SetwalletaddressErr(false);
        }

        if (values.payid == "") {
            errors.payid = "payid is required !";
            SetpayidErr(true);
        } else if (values.payid.length < 10 || values.payid.length > 13) {
            errors.payid = "Invalid Payment ID";
            SetpayidErr(true);
        } else {
            SetpayidErr(false);
        }
        setvalidationnErr(errors);
        return errors;
    };

    const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Address copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

    const onSelect = async (option, e) => {
        setcurrencyid(e.value);
        setCurrency(option.target.outerText);
        console.log(option.target.outerText, "target", Checkoutdetails.currency);

        const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${Checkoutdetails.currency}&tsyms=${currencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;

        axios
            .get(apiUrl)
            .then(async (response) => {
                var value =
                    response.data[Checkoutdetails.currency][currencyref.current];
                console.log(value, "value");
                var total = (value * Checkoutdetails.Fixed).toFixed(4);

                Settotalamount(total);
            })
            .catch((error) => {
                console.error("An error occurred:", error);
            });
    };

    return (
        <section className="section_projec checkpage h100 bg_trrrr w-100">
            <div className="container">
                <div className="row justify-content-center">
                    {/* <div className="col-lg-6 px-20 ">
                       
                    </div> */}

                    <div className="col-lg-5  py-20 ">
                        <div className=" checkrightcard ">
                            {pay == false ? (
                                <div className="p-5">
                                    <div>
                                        <h3 className="text-center h2 mb-4 Textprimary">
                                            Gatecryptopay
                                        </h3>

                                        <div className="d-flex justify-content-center">
                                            <img src={Checkoutdetails.Logourl} className="widgetimage" />

                                            <div className="ml-3 mt-2 Textprimary">
                                                {" "}
                                                <h2>{Checkoutdetails.Name}</h2>
                                            </div>
                                        </div>
                                        <h5 className="text-center mt-3 Textprimary">
                                            {Checkoutdetails.Description}
                                        </h5>

                                        <div className="widgetbottom">
                                            <div className="">
                                                <div className="coin_select">
                                                    <h5 className="heading_class_h5 Textprimary pt-4 pb-2">
                                                        Select currencies
                                                    </h5>
                                                    <Dropdown
                                                        className="shadow Drops"
                                                        placeholder="Select Currencies"
                                                        fluid
                                                        selection
                                                        defaultValue={toCurrencyRef.current[0]}
                                                        options={toCurrencyRef.current}
                                                        onChange={onSelect}
                                                    // onClick={onSelect}
                                                    />
                                                    <div>
                                                        {setCurrencyErrref.current == true ? (
                                                            <p className="text-danger mt-2">
                                                                {validationnErr.currency}
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="coin_select">
                                                    <h5 className="heading_class_h5 Textprimary  pb-2">
                                                        Email
                                                    </h5>
                                                    <input
                                                        className="shadow Drops"
                                                        placeholder="Enter your email address"
                                                        type="text"
                                                        value={email}
                                                        name="email"
                                                        onChange={handleChange}
                                                    />
                                                    {emailErrref.current == true ? (
                                                        <p className="text-danger mt-2">
                                                            {validationnErr.email}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className="coin_select">
                                                    <h5 className="heading_class_h5 Textprimary pt-4 pb-2">
                                                        Your Wallet Address
                                                    </h5>
                                                    <input
                                                        className="shadow Drops"
                                                        placeholder="Enter your wallet address"
                                                        type="text"
                                                        value={walletaddress}
                                                        name="walletaddress"
                                                        onChange={handleChange}
                                                    />
                                                    <div>
                                                        {walletaddressErrref.current == true ? (
                                                            <p className="text-danger mt-2">
                                                                {validationnErr.walletaddress}
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>

                                                <h4 className="Textprimary d-flex">
                                                    Total Amount : {Checkoutdetails.Fixed}{" "}
                                                    {Checkoutdetails.currency}
                                                </h4>

                                                {ButtonLoader == false ? (
                                                    <Button className="swap_but newbutton" onClick={Proceed}>
                                                        Proceed
                                                    </Button>
                                                ) : (
                                                    <Button className="swap_but newbutton" disabled>
                                                        Loading....
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : submit == true ? (
                                <div className="mt-5">
                                    <img
                                        src={require("../Image/paycrypto.png")}
                                        className="mt-5 d-block mx-auto"
                                        width="80%"
                                    />
                                    <h4 className="text-white text-center">
                                        Payment on processing...
                                    </h4>
                                </div>
                            ) : (
                                <div className="p-5">
                                    <div>


                                        <div className="mt-2 Textprimary">
                                            <h2 className="text-center text-white">
                                                Payment Details
                                            </h2>
                                        </div>

                                        <div className=" my-4">
                                            <h4 className="text-white "> Selected Currency </h4>
                                            <p className="  Textprimary Drops text-white px-3  ">
                                                {currency}
                                            </p>
                                        </div>

                                        <div className=" my-4">
                                            <h4 className="text-white ">Total Amount </h4>
                                            <p className="  Textprimary Drops text-white px-3  ">
                                                {totalamountref.current} {currency}
                                            </p>
                                        </div>

                                        <div className=" my-4">
                                            <h4 className="text-white ">Address </h4>
                                            <p
                                                className="  Textprimary Drops text-white px-3 d-flex  justify-content-between "
                                                ref={textRef1}
                                            >

                                                {addressref.current ? (
                                                    <span>{addressref.current.substring(0, 15) + "..."}</span>
                                                ) : (
                                                    <span></span> // Render an empty span if addressref.current.address is falsy
                                                )}


                                                <IconCopy onClick={handleCopy1} className="ml-3" />
                                            </p>
                                        </div>

                                        <div class="form-group form-check mt-3 mb-1">
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="exampleCheck1"
                                                onChange={handleTerms}
                                                value={isChecked}
                                            />
                                            <label class="addoverflow text-white" for="exampleCheck1">
                                                I have deposited the currency
                                            </label>
                                        </div>
                                        <div>
                                            {termsValidationref.current == true ? (
                                                <p className="textdang mt-0">Confirm the deposit</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="">
                                            {ButtonLoader == false ? (
                                                <Button
                                                    className="swap_but newbutton mt-2"
                                                    onClick={Pay}
                                                >
                                                    Pay Now{button}
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="swap_but newbutton  mt-5"
                                                    disabled
                                                >
                                                    Loading...
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Modal isOpen={modal} className="userwhites" toggle={toggle}>
                                <ModalBody className="p-5">
                                    <h2 className="text-center text-success h1">
                                        {" "}
                                        Payment Success !
                                    </h2>
                                    <div>
                                        <img
                                            width="20%"
                                            src={require("../Image/tick.jpg")}
                                            className="mx-auto d-block my-5"
                                        />

                                        <div className=" my-4">
                                            <h4 className="Textprimary">Your Transaction ID </h4>
                                            <p
                                                className="  Textprimary Dropchecks text-white px-3 d-flex  justify-content-between "
                                                ref={textRef2}
                                            >
                                                {uuidsref.current}

                                                <IconCopy onClick={handleCopy2} className="ml-3" />
                                            </p>
                                        </div>
                                        <h3 className="text-center mb-5">
                                            Your Order has been confirmed <br /> check your email for
                                            details
                                        </h3>
                                    </div>
                                    <div>
                                        <Button
                                            className="text-white mx-auto d-block bg-success"
                                            onClick={goto}
                                        >
                                            Go Back
                                        </Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Invoicecharge;
