import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebarlink";
import Sidebar from './userslidbar';
import useState from "react-usestateref";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import { Button } from "@mui/material";

function Home() {
  const navigate = useNavigate();

  const initialValues = {
    send: "",
  };

  const initialValues1 = {
    receive: "",
  };

  const [fiat, setfiat, fiatref] = useState("");
  const [crypto, setcrypto, cryptoref] = useState("");
  const [formValue, setFormValue, formValueref] = useState(initialValues);
  const [formValue1, setFormValue1, formValue1ref] = useState(initialValues1);
  const [showDiv, setShowDiv] = useState(false);
  const [receiveshowDiv, setreceiveShowDiv] = useState(false);
  const [SelectedFiat, setSelectedFiat, SelectedFiatref] = useState("");
  const [SelectedCrypto, setSelectedCrypto, SelectedCryptoref] = useState("");
  const [fiatdata, setfiatdata, fiatdataref] = useState("");
  const [cryptodata, setcryptodata, cryptodataref] = useState("");
  const [fromamount, Setfromamount, fromamountref] = useState("");
  const [paymentmethods, Setpaymentmethods, paymentmethodsref] = useState("");
  const [gateways, Setgateways, gatewaysref] = useState([]);
  const [gate, Setgate, gateref] = useState([]);
  const [payvalue, Setpayvalue, payvalueref] = useState();
  const [payment, setpayment, paymentref] = useState("");
  const [buycryptodeatil, setbuycryptodeatil, buycryptodeatilref] = useState("");
  const [loader, setloader, loaderref] = useState(false);


  const { send } = formValue;
  const { receive } = formValue1;

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    console.log(token, "=-=-=-=-=-=-=-=--=-=-");
    if (token != null) {
      onRamp();
      Supportedfiat("INR");
      Supportedcrypto("ETH");
    } else {
      navigate("/login");
    }
  }, [0]);

  const onSelect = async (e, option) => {
    setpayment(option.value);
    console.log(paymentref.current, "paymentref");
    marketprices();
  };

  const onSelect1 = async (e, option) => {
    Setgate(option.value);
    const filteredGateway = gatewaysref.current.filter(
      (gateway) => gateway.value == option.value
    );
    Setpayvalue(filteredGateway);
    Setgate(filteredGateway[0].value);
    console.log(filteredGateway[0].cryptoAmount, "cryptoAmount");
    var formattedValue = (filteredGateway[0].cryptoAmount / Math.pow(10, 18))
      .toFixed(8)
      .replace(/\.?0+$/, "");
    setFormValue1({ receive: formattedValue });
    console.log(gatewaysref.current, "filteredGateway");
    console.log(filteredGateway, "filteredGateway");
    console.log(option.value, "filteredGateway");
  };

  const handleToggle = () => {
    setShowDiv(!showDiv);
  };
  const handleToggle1 = () => {
    setreceiveShowDiv(!receiveshowDiv);
  };

  const handleFiatSelect = (data) => {
    setSelectedFiat(data);
    Supportedfiat(data.id);
    console.log(data, "data");
    marketprices();
  };

  const handleCryptoSelect = (data) => {
    console.log(data, "data");
    // Supportedcrypto(data.ticker);
    setSelectedCrypto(data);
    marketprices();
  };

  const handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue, ...{ [name]: value } };
      setFormValue(formdata);
      marketprices();
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleChange1 = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formdata = { ...formValue1, ...{ [name]: value } };
      setFormValue1(formdata);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const buycrypto = async () => {
    try {
      var obj = {
        cefiGateway: gateref.current,
        fiat: SelectedFiatref.current
          ? SelectedFiatref.current.id
          : fiatdataref.current.id,
        fiatAmount: formValueref.current.send * 100,
        payment: paymentref.current,
        chain: SelectedCryptoref.current
          ? SelectedCryptoref.current.chain.toUpperCase()
          : "eth",
        crypto: SelectedCryptoref.current
          ? SelectedCryptoref.current.address
          : "",
      };
      console.log(obj, "objsssss");
      var data = {
        apiUrl: apiService.buycrypto,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setbuycryptodeatil(resp.data);
        console.log("viewref====", resp);
      } else {
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  var onRamp = async () => {
    setloader(true);

    var data = {
      apiUrl: apiService.onRamp,
    };
    var resp = await getMethod(data);
    console.log(resp, "Respdata");
    setloader(false);

    if (resp.status == true) {
      const Fiat = resp.data.Fiat;
      const Crypto = resp.data.Crypto;

      setfiat(Fiat);
      setcrypto(Crypto);
    } else {
      console.log(resp.Message);
    }
  };

  var Supportedfiat = async (item) => {
    var obj = {
      data: item,
    };

    var data = {
      apiUrl: apiService.Supportedfiat,
      payload: obj,
    };

    var resp = await postMethod(data);

    if (resp.status == true) {
      setfiatdata(resp.data);
      setFormValue({ send: resp.data.placeholder / 100 });

      var Paymentarray = [];

      const Fiat = resp.data.payment;

      console.log(Fiat, "Fiat");
      for (var i = 0; i < Fiat.length; i++) {
        var obj = {
          value: Fiat[i].id,
          key: Fiat[i].name,
          text: Fiat[i].name,
          image: {
            avatar: true,
            src: Fiat[i].image,
          },
        };

        Paymentarray.push(obj);
      }

      Setpaymentmethods(Paymentarray);
      setpayment(Paymentarray[0].value);

      marketprices();
    } else {
      console.log(resp.Message);
    }
  };

  var Supportedcrypto = async (item) => {
    var obj = {
      data: item,
    };

    console.log(obj, "obj");

    var data = {
      apiUrl: apiService.Supportedcrypto,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setcryptodata(resp.data);
    } else {
      console.log(resp.Message);
    }
  };

  var marketprices = async () => {

    setloader(true);
    var obj = {
      fiat: SelectedFiatref.current
        ? SelectedFiatref.current.id
        : fiatdataref.current.id,
      fiatAmount: formValueref.current.send * 100,
      payment: paymentref.current,
      chain: SelectedCryptoref.current
        ? SelectedCryptoref.current.chain
        : "eth",
      crypto: SelectedCryptoref.current
        ? SelectedCryptoref.current.address
        : "",
    };


    console.log(obj, "obj");

    var data = {
      apiUrl: apiService.marketprices,
      payload: obj,
    };
    var resp = await postMethod(data);
    setloader(false);
    console.log(resp, "resp");

    if (resp.status == true) {
      var Gatewayarray = [];

      const Fiat = resp.data.gateways;

      console.log(Fiat, "Fiat");
      for (var i = 0; i < Fiat.length; i++) {
        var obj = {
          value: Fiat[i].gateway,
          key: Fiat[i].gateway,
          text: Fiat[i].gateway,
          type: Fiat[i].type,
          cryptoAmount: Fiat[i].cryptoAmount,
          cryptoDecimal: Fiat[i].cryptoDecimal,
          transactionFee: Fiat[i].transactionFee,
          chain: Fiat[i].chain,
          networkFee: Fiat[i].networkFee,
        };

        Gatewayarray.push(obj);
      }

      Setgateways(Gatewayarray);
      Setgate(Gatewayarray[0].value);

      console.log(Gatewayarray[0].cryptoAmount, "cryptoAmount");

      var formattedValue = (Gatewayarray[0].cryptoAmount / Math.pow(10, 18))
        .toFixed(8)
        .replace(/\.?0+$/, "");
      setFormValue1({ receive: formattedValue });

      console.log(formattedValue, "cryptoAmount");
    } else {
      console.log(resp.Errormessage, "Errormessage");
    }
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">

          {loader == true ? (
              <div class="spinner css-ezm1fk">
                <div class="css-1qoumk2"></div>
                <div class="css-1v19680"></div>
                <div class="css-1v90jie"></div>
                <div class="css-19p0rvp"></div>
              </div>
            ) : (

            <main className="main_heinght">
              <div>
                <section className="form-deposit uniramp" >
                  <div className="section_selec_ash row">
                    <div className="col-lg-12 p-3 ">
                      <div className="headin_deposur_s ">
                        <h3>
                          <i
                            class="ri-arrow-left-line cusonr"
                            onClick={() => navigate("/userdashboard")}
                          ></i>
                          <span className="pl-3">Buy Crypto</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center ">
                      <div className="col-lg-6 newform mx-3 mb-5  text-white shadow">
                        <div className="qr_section_class p-0">
                          {buycryptodeatilref.current.cefiInitiate ? (
                            <div className="row">
                              <div className="col-lg-12">
                                <iframe src={buycryptodeatilref.current.cefiInitiate.url} title="programiz pro website" width="100%" height="600px"></iframe>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-lg-12 mb-24">
                                <div className="crypto_coin_selec pa-_opo ">
                                  <label className="mt-3">Spend</label>
                                </div>

                                <div className="form_swap">
                                  <div
                                    className="select_drobdoan"
                                    onClick={handleToggle}
                                  >
                                    {SelectedFiatref.current ? (
                                      <div>
                                        <img
                                          src={SelectedFiatref.current.image}
                                          width="30px"
                                        />
                                        <p className="selectp text-dark">
                                          {SelectedFiatref.current
                                            ? SelectedFiatref.current.id.toUpperCase()
                                            : ""}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src={fiatdataref.current.image}
                                          width="30px"
                                        />
                                        <p className="selectp text-dark">
                                          {fiatdataref.current
                                            ? fiatdataref.current.id.toUpperCase()
                                            : ""}
                                        </p>
                                      </div>
                                    )}
                                    <i class="ri-arrow-drop-right-line px35 text-dark"></i>
                                  </div>
                                  <div className="input_send">
                                    <input
                                      type="number"
                                      value={send}
                                      name="send"
                                      onChange={handleChange}
                                    />
                                  </div>

                                  <div
                                    className={`custom-div dropdown_mnyw   ${showDiv ? "show" : "hide"
                                      }`}
                                  >
                                    <div className="header">
                                      {/* <span>
                                        <i class="ri-search-line"></i>
                                      </span>
                                      <input type="text" placeholder="Search" /> */}
                                      <button>
                                        <i
                                          class="ri-close-fill text-white"
                                          onClick={handleToggle}
                                        ></i>
                                      </button>
                                    </div>
                                    <div className="body_slect">
                                      {fiatref.current.length > 0 ? (
                                        fiatref.current.map((dataItem, i) => {
                                          return (
                                            <div
                                              className="coin_slect"
                                              key={i}
                                              onClick={() =>
                                                handleFiatSelect(dataItem)
                                              }
                                            >
                                              <div
                                                className="coin_name"
                                                onClick={handleToggle}
                                              >
                                                <img
                                                  src={dataItem.image}
                                                  alt="Currency Logo"
                                                />
                                                <div className="d-flex justify-content-between w-100">
                                                  <div className="d-flex flex-col">
                                                    <span>
                                                      {dataItem
                                                        ? dataItem.id.toUpperCase()
                                                        : ""}
                                                    </span>
                                                    <span className="fullname">
                                                      {dataItem.name}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p>No --Data-- Found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12 mb-24">
                                <div className="crypto_coin_selec pa-_opo ">
                                  <label className="mt-3">Receive</label>
                                </div>
                                <div className="form_swap">
                                  <div
                                    className="select_drobdoan"
                                    onClick={handleToggle1}
                                  >
                                    {SelectedCryptoref.current ? (
                                      <div>
                                        <img
                                          src={SelectedCryptoref.current.image}
                                          width="30px"
                                        />
                                        <p className="selectp text-dark">
                                          {SelectedCryptoref.current
                                            ? SelectedCryptoref.current.ticker.toUpperCase()
                                            : ""}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src={cryptodataref.current.image}
                                          width="30px"
                                        />
                                        <p className="selectp text-dark">
                                          {cryptodataref.current
                                            ? cryptodataref.current.ticker.toUpperCase()
                                            : ""}
                                        </p>
                                      </div>
                                    )}
                                    <i class="ri-arrow-drop-right-line px35 text-dark"></i>
                                  </div>
                                  <div className="input_send">
                                    <input
                                      type="number"
                                      placeholder=""
                                      value={receive}
                                      name="receive"
                                      onChange={handleChange1}
                                    />
                                  </div>

                                  <div
                                    className={`custom-div dropdown_mnyw   ${receiveshowDiv ? "show" : "hide"
                                      }`}
                                  >
                                    <div className="header">
                                      <button>
                                        <i
                                          class="ri-close-fill text-white"
                                          onClick={handleToggle1}
                                        ></i>
                                      </button>
                                    </div>
                                    <div className="body_slect">
                                      {cryptoref.current.length > 0 ? (
                                        cryptoref.current.map((dataItem, i) => {
                                          return (
                                            <div
                                              className="coin_slect"
                                              key={i}
                                              onClick={() =>
                                                handleCryptoSelect(dataItem)
                                              }
                                            >
                                              <div
                                                className="coin_name"
                                                onClick={handleToggle1}
                                              >
                                                <img
                                                  src={dataItem.image}
                                                  alt="Currency Logo"
                                                />
                                                <div className="d-flex justify-content-between w-100">
                                                  <div className="d-flex flex-col">
                                                    <span>
                                                      {dataItem
                                                        ? dataItem.ticker.toUpperCase()
                                                        : ""}
                                                    </span>
                                                    <span className="fullname">
                                                      {dataItem.name}
                                                    </span>
                                                  </div>

                                                  <span className="chains">
                                                    {dataItem
                                                      ? dataItem.chain.toUpperCase()
                                                      : ""}
                                                  </span>
                                                </div>
                                              </div>
                                              <small>
                                                {dataItem.currencyName}
                                              </small>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p>No --Data-- Found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-lg-12">
                                <div className="d-flex mt-3 ">
                                  <label className="mt-3 mr-2">By</label>


                                  {loaderref.current == true ? (<>
                                    <p className="gateways padds text-dark"><i class="fa-solid fa-spinner fa-spin"></i> Fetching </p>
                                  </>) : (

                                    
                                    <Dropdown
                                      fluid
                                      className="gateways"
                                      selection
                                      value={gateref.current}
                                      options={gatewaysref.current}
                                      onChange={onSelect1}
                                    />
                                  )}


                                </div>
                              </div> */}

                              <div className="col-lg-12">
                                <div className="crypto_coin_selec pa-_opo ">
                                  <label className="mt-3">By</label>
                                  <Dropdown
                                    fluid
                                    selection
                                    value={gateref.current}
                                    options={gatewaysref.current}
                                    onChange={onSelect1}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="crypto_coin_selec payment_gate pa-_opo ">
                                  <label className="mt-3">Payment</label>
                                  <Dropdown
                                    fluid
                                    selection
                                    value={paymentref.current}
                                    options={paymentmethodsref.current}
                                    onChange={onSelect}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div class="form_login_input">

                                  <Button onClick={buycrypto}>
                                    Buy{" "}
                                    {SelectedCryptoref.current
                                      ? SelectedCryptoref.current.ticker.toUpperCase()
                                      : "ETH"}{" "}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </main>)}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
