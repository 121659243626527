import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { decryptData } from "../core/service/common.api";
import { generatepostMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Home() {
  const navigate = useNavigate();

  const [Merchantdetails, SetMerchantdetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalpage, Settotalpage] = useState(0);
  const [Secert, setSecert, Secertref] = useState("");
  const [API, setAPI, APIref] = useState("");
  const [Label, setLabel, Labelref] = useState("");
  const [userLabel, setuserLabel, userLabelref] = useState("");
  const [labelError, setLabelError, labelErrorref] = useState("");
  const [otpError, setOtpError, otpErrorref] = useState("");
  const [qrcode, Setqrcode, qrcoderef] = useState("");
  const [TfaKey, setTfaKey, TfaKeyref] = useState("");


  const handleChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\s/g, "");
    if (/\s/.test(sanitizedValue)) {
      setLabelError("Label cannot contain spaces");
    } else if (sanitizedValue.length < 3 || sanitizedValue.length > 10) {
      setLabelError("Label must be between 3 and 10 characters");
    } else if (!/^[a-zA-Z0-9_]+$/.test(sanitizedValue)) {
      setLabelError("Username can only contain letters, numbers, and underscores");
    } else {
      setLabelError("");
    }
    setLabel(sanitizedValue);
  };

  const gotoEnable = async () => {
    navigate("/security_tfa");
  };

  const [OTP, setOTP, OTPref] = useState("");
  const [tfaStatus, settfaStatus] = useState("");

  const handleChangeOTP = (e) => {


    const value = e.target.value;
    const sanitizedValue = value.replace(/\s/g, "");

    if (/\s/.test(sanitizedValue)) {
      setOtpError("OTP cannot contain spaces");
    } else {
      setOtpError("");
    }

    if (sanitizedValue.length < 7) {
      setOTP(sanitizedValue);
    }
  };

  const [modal1, setModal1] = useState(false);

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
  };

  const goto1 = () => setModal1(!modal1);

  const [modal2, setModal2] = useState(false);

  const toggle2 = async (e) => {
    console.log("e====", e);
    setModal2(!modal2);
  };

  const goto2 = () => setModal2(!modal2);

  useEffect(() => {
    GetApi();
    // Getresponse();
    getTfastatus();

    Createsignature();
  }, []);

  const getTfastatus = async () => {
    var data = {
      apiUrl: apiService.getTfaDetials,
    };

    var resp = await getMethod(data);
    console.log(resp, "=-=-=-resp");
    settfaStatus(resp.data.tfastatus);

    localStorage.setItem("tfa_status", resp.data.tfastatus);
  };

  const GetApi = async () => {
    try {
      setLoader(true);
      var data = {
        apiUrl: apiService.getapidata,
      };
      var resp = await getMethod(data);
      console.log("getmerchantdetails===", resp);
      setLoader(false);

      if (resp.status == true) {
        const API = await decryptData(resp.data.Api);
        const Secret = await decryptData(resp.data.Secret);
        setAPI(API);
        setSecert(Secret);
        setuserLabel(resp.data.label);
        Setqrcode(resp.data.code);
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const CreateAPI = async () => {
    if (labelError || otpError || !Label || !OTP) {
      console.log("Form validation failed");

      if (otpErrorref.current == "") {
        setOtpError("OTP is required");
      }
      return;
    }

    try {
      setLoader(true);
      if (OTPref.current != "" && OTPref.current <= 999999) {
        const formValue = {
          OTP: OTPref.current,
          Key: TfaKeyref.current
        };

        var data = {
          apiUrl: apiService.generateApiKey,
          payload: formValue,
        };
        var resp = await postMethod(data);
        console.log("getmerchantdetails===", resp);
        setLoader(false);

        if (resp.status == true) {
          goto2();
          GetApi();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
          goto2();

          console.log(resp, "-=-=-resp=-=-=-resp==-resp");
        }
      } else {
        console.log("OTP is required");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const APIOTP = async () => {
    if (labelError || !Label) {
      if (labelErrorref.current == "") {
        setLabelError("Label is required");
      }
      console.log("Form validation failed");
      return;
    }
    try {
      toggle1();
      const formValue = { Label: Labelref.current };

      var data = {
        apiUrl: apiService.generateApiOTP,
        payload: formValue,
      };
      var resp = await postMethod(data);
      console.log("getmerchantdetails===", resp);
      toggle2();
      if (resp.status == true) {
        setuserLabel(resp.data);
        setTfaKey(resp.TfaKey);

        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const Getresponse = async () => {
    try {
      const formValue = { amount: 100, currency: "USDT", network: "BNB" };

      var data = {
        apiUrl: apiService.getAPIreponse,
        payload: formValue,
        apiKey: APIref.current,
        secretKey: Secertref.current,
        signature:
          "d92d1451211b5042e6a1d4602455aed880a6da96d63975f253b98478e74af0aa",
      };
      var resp = await generatepostMethod(data);
      console.log("getmerchantdetails===", resp);

      if (resp) {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const Createsignature = async () => {
    try {
      const formValue = { amount: 100, currency: "USDT", network: "BNB" };
      var data = {
        apiUrl: apiService.createSignature,
        apiKey: APIref.current,
        secretKey: Secertref.current,
        payload: formValue,
      };
      var resp = await generatepostMethod(data);
      console.log("getmerchantdetails===", resp);

      if (resp) {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const Transactionprocess = async () => {
    try {
      const formValue = {
        TnxID:
          "0xe9961586ba32bec36da1d960028877ed7d34e1b9b8c90605b4e77be8e3d76884",
        Address: "0xca87b07dcb597fb5f1f921cccd704fada6301607",
        currency: "BNB",
      };

      var data = {
        apiUrl: apiService.Transactionprocess,
        payload: formValue,
        apiKey: APIref.current,
        secretKey: Secertref.current,
        signature:
          "8aaea45fbfa3ec7f1657d0690366b346ce67374efb1511bc4f09ce965fa5be98",
      };
      var resp = await generatepostMethod(data);
      console.log("getmerchantdetails===", resp);
      if (resp) {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      showsuccessToast("Copied successfully");
    } else {
      showerrorToast("Link not copied, please try after sometimes");
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                          <div className="headin_deposur_s">
                            <h3>API Management</h3>
                          </div>
                          <div class="form_login_input">
                            {userLabelref.current == "" ? (
                              <Button
                                className="button_colore"
                                onClick={toggle1}
                              >
                                Create
                              </Button>
                            ) : APIref.current == "" &&
                              Secertref.current == "" ? (
                              <Button
                                className="button_colore"
                                onClick={toggle2}
                              >
                                Verify OTP
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-4">
                          <div className="dashcard1 w-100 p-3  mb-4">
                            <div className="contant_secion_balance">
                              <div className="d-flex justify-content-between">
                                <h3 className="heading_sub_section">Note :</h3>
                              </div>
                              <p className="mb-3 mb-0 paragraph">
                                1. Never share your API keys in public
                                repositories, forums, or any other publicly
                                accessible locations.
                              </p>
                              <p className="my-3 mb-0 paragraph">
                                2. Store API keys in environment variables or
                                use secure key management services, rather than
                                hardcoding them in your codebase
                              </p>
                              <p className="my-3 mb-0 paragraph">
                                3. You will not be able to create an API Key if
                                KYC is not completed.
                              </p>
                            </div>
                          </div>
                        </div>

                        {Secertref.current != "" && APIref.current != "" ? (
                          <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                            <div className="dashcard1 w-100 p-3  mb-2">
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="qr_image_and_address">
                                    <div className="img_padding">
                                      <img
                                        src={qrcoderef.current}
                                        // src={require("../Image/QrPNG.png")}
                                        className="blackshow bg-white rounded p-1 mx-auto d-block mb-5"
                                        width="150px"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="APIcards">
                                    <label>API Key</label>
                                    <p className="d-flex justify-content-between">
                                      {APIref.current ? (
                                        <>
                                          <span>
                                            {APIref.current.substring(0, 4) +
                                              " * * * * * * * * * * * * * "}
                                          </span>

                                          <i
                                            onClick={() => copy(APIref.current)}
                                          >
                                            Copy
                                          </i>
                                        </>
                                      ) : (
                                        <span>{"Create API Key "}</span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="APIcards">
                                    <label>Secert Key</label>
                                    <p className="d-flex justify-content-between mb-0">
                                      {Secertref.current ? (
                                        <>
                                          <span>
                                            {Secertref.current.substring(0, 4) +
                                              " * * * * * * * * * * * * * * * * * * * * * * * * * * "}
                                          </span>

                                          <i
                                            onClick={() =>
                                              copy(Secertref.current)
                                            }
                                          >
                                            Copy
                                          </i>
                                        </>
                                      ) : (
                                        <span>{"Create Secert Key"}</span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div class="col-lg-12 d-flex justify-content-between align-items-center px-4 pt-3">
                              <div className="dashcard1 w-100 p-3  mb-2">
                                <div className="contant_secion_balance">
                                  <img
                                    src={require("../Image/nodata.png")}
                                    className="mt-5 mx-auto d-block"
                                  />
                                  <p className="my-4 mx-0 text-center nodatafound f_14">
                                    Your has not created <br />
                                    any API key yet
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                </>
              )}

              <Modal
                isOpen={modal1}
                toggle={toggle1}
                className="rem10 checkout_modal"
              >
                <ModalBody className="p-0">
                  <div class="Frame-14722">
                    <span class="Item-Details h2">
                      Create API Key
                    </span>
                    <div class="">
                      <i class="ri-close-fill  Close" onClick={goto1}></i>
                    </div>
                  </div>
                  {tfaStatus == 0 ? (
                    <>
                      <div className="">
                        <div className="card_login px-0 shadow">
                          <div>
                            <h2 className="text-white text-center">
                              Please Enable the 2FA process to gain access to
                              the page.
                            </h2>
                            <div className="row card_login px-0 flex-row pb-0 pt-2">
                              <div className="col-md-6">
                                <div className="form_login_input mt-3">
                                  <Button
                                    className="discardbtn"
                                    onClick={goto1}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form_login_input mt-3">
                                  <Button onClick={gotoEnable}>
                                    Enable 2FA
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="">
                        <div className="card_login px-0 shadow">
                          <div>
                            <div class="form_login_input">
                              <label> API Key Label </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Label"
                                name="otp"
                                minLength="3"
                                maxLength="10"
                                value={Label}
                                onChange={handleChange}
                              />
                              {labelErrorref.current != "" ? (
                                <p className="text-danger bot22">
                                  {" "}
                                  {labelErrorref.current}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="row card_login px-0 flex-row pb-0 pt-2">
                              <div className="col-md-6">
                                <div className="form_login_input my-2">
                                  <Button
                                    className="discardbtn"
                                    onClick={goto1}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form_login_input my-2">
                                  <Button onClick={APIOTP}>Next</Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </ModalBody>
              </Modal>

              <Modal
                isOpen={modal2}
                toggle={toggle2}
                className="rem10 checkout_modal"
              >
                <ModalBody className="p-0">
                  <div class="Frame-14722">
                    <span class="Item-Details h2">
                      Authenticator App Verification
                    </span>
                    <div class="">
                      <i class="ri-close-fill  Close" onClick={goto2}></i>
                    </div>
                  </div>
                  <div className="py-2">
                    <p className="Deletecontent1 mt-4">
                      Enter the 6-digit code generated by the Authenticator App
                    </p>
                    <div className="card_login px-0 py-2 shadow">
                      <div>
                        <div class="form_login_input">
                          <label>Authenticator OTP</label>
                          <input
                            type="Number"
                            class="form-control"
                            placeholder="Enter OTP"
                            name="otp"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(
                                evt.key
                              ) && evt.preventDefault()
                            }
                            value={OTP}
                            onChange={handleChangeOTP}
                          />
                          {otpErrorref.current != "" ? (
                            <p className="text-danger  bot22">
                              {" "}
                              {otpErrorref.current}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="row card_login px-0 flex-row pb-0 pt-2">
                          <div className="col-md-12">
                            <div className="form_login_input">
                              <Button onClick={CreateAPI}>Submit</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
