import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { setAuthorization } from "../core/service/axios";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { email, password } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(formValue);
    setbuttonLoader(true);
    if (formValue.email != "" && formValue.password != "") {
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };

      var resp = await postMethod(data);
      localStorage.setItem("socket_token", resp.socketToken);
      if (resp?.tfa === 1) {
        setbuttonLoader(false);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/tfalogin", {
          state: {
            socketToken: resp.socketToken,
          },
        });
      } else {
        if (resp.status) {
          setbuttonLoader(false);
          toast.success(resp.Message);
          console.log("resp.token====", resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          setAuthorization(resp.token);
          navigate("/dashboard");
          //window.location.href = "https://latoken.com/exchange/ADVB_USDT";
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row justify-content-center">
              {/* <div className="col-lg-6 d-flex align-items-center">
                <div className="instruction_content">
                  <div className="steps_section">
                    <h2>
                      <span>Step 1:</span> Confirm your email address
                    </h2>
                    <p>
                      We will send a confirmation link to your company’s email
                      address. Please check your inbox and confirm it.
                    </p>
                  </div>
                  <div className="steps_section">
                    <h2>Step 2: Provide general business information</h2>
                    <p>
                      Let us know your business name and an official website
                      address.
                    </p>
                  </div>
                  <div className="steps_section">
                    <h2>Step 3: Complete account verification</h2>
                    <p>
                      Verify your business to unlock full access to our payment
                      processing system
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6">
                <div className="card_login">
                  <h3>What type of account would you like to setup?</h3>

                  <div className="conteni_logon_secti">
                    <Link to="/userregister" className="select_account_tiype">
                      <p>Personal use</p>
                      <small>
                        For individuals wanting access to cryptocurrency
                        exchange services
                      </small>
                    </Link>
                    <Link to="/register" className="select_account_tiype">
                      <p>Business</p>
                      <small>
                        For companies aiming to establish and accept
                        cryptocurrency payments.
                      </small>
                    </Link>
                    <p className="text_logon">
                      already have an account
                      <Link to="/login" className="pass_forgee mt-3 d-block">
                        login ?
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
