import React, { useEffect } from "react";
import useState from "react-usestateref";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IconFileAnalytics, IconFileDatabase } from "@tabler/icons-react";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import { Link, useNavigate } from "react-router-dom";
import { removeAuthToken } from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import { socket } from "../core/context/socket";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "moment";
import { toast } from "react-toastify";

const Header = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  // console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(isMatch);
  const [loginCheck, setloginCheck] = useState(false);
  const [notifications, setNotification] = React.useState("");
  const [modal1, setModal1] = useState(false);

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
  };

  const goto1 = () => setModal1(!modal1);

  const navigate = useNavigate();

  useEffect(() => {
    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      let UserType = localStorage.getItem("UserType");
      console.log(UserType, "UserTypeUserType");
      setuserDetails(UserType);
      socket.on("socketResponse" + socketToken, function (res) {
        console.log("notify response====", res);

        if (res.Reason == "notify") {
          getNotifications();
        }

        if (res.Message == "account_deactivate") {
          toast.error("Your account deactivated by admin");
          logout();
        }
      });
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      checkUser();
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  };

  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.notifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) { }
  };

  const [userDetails, setuserDetails, userDetailsref] = useState("");

  const checkUser = async () => {
    try {
      var data = {
        apiUrl: apiService.usercheck,
      };
      var resp = await getMethod(data);
      console.log(resp, "checkuser");
      if (resp.status) {
      } else {
        console.log("call logout");
        logout();
      }
    } catch (error) { }
  };
  return (
    <React.Fragment>
      <AppBar
        sx={{ background: "#000000" }}
        className={
          loginCheck == true
            ? "headerseacondary header_padidng"
            : "header_padidng"
        }
      >
        <Toolbar>
          <div className="imag">
            <Link className="nav_brand" to="/">
              {" "}
              <img
                src={require("../Image/bitcoin-p2p/Logolight.png")}
                className="blackshow"
              />
            </Link>
          </div>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="nav_link_section ml-5 mr-auto navbar navbar-expand-md">
                {loginCheck == true ? (
                  <>
                    {userDetailsref.current == "User" ? (
                      <div>
                        <Link to="/userdashboard" className="Butn_header mr-3">
                          Dashboard
                        </Link>
                        <Link to="/uniramp" className="Butn_header mr-3">
                          Buy Crypto
                        </Link>
                      </div>
                    ) : (
                      <>
                        <Link to="/dashboard" className="Butn_header mr-3">
                          Dashboard
                        </Link>

                        <Link to="/checkout" className="Butn_header mr-3">
                          Checkout
                        </Link>

                        <Link to="/invoice" className="Butn_header mr-3">
                          Invoice
                        </Link>

                        <ul class="navbar-nav ">
                          <li class="nav-item dropdown mega-menu">
                            <a
                              class="nav-link dropdown-toggle"
                              href="#"
                              id="megaMenuDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              History
                            </a>
                            <div
                              class="dropdown-menu mega-menu-content"
                              aria-labelledby="megaMenuDropdown"
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <div className="menu_section">
                                    <Link to="/checkouthistory">
                                      <div className="icon">
                                        <IconFileDatabase className="icon_menu" />
                                      </div>
                                      <div className="content">
                                        <div className="soon_text">
                                          <h5>Checkout History </h5>
                                          {/* <span className="before">Soon</span> */}
                                        </div>
                                        {/* <p>Launch your token on Beleaf</p> */}
                                      </div>
                                    </Link>
                                    <Link to="/invoicehistory">
                                      <div className="icon">
                                        <IconFileAnalytics className="icon_menu" />
                                      </div>
                                      <div className="content">
                                        <div className="soon_text">
                                          <h5>Invoice History </h5>
                                          {/* <span className="before">Soon</span> */}
                                        </div>
                                        {/* <p>Launch your token on Beleaf</p> */}
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link to="/register">Business</Link>
                    <Link to="/userregister">Personal</Link>
                    <ul class="navbar-nav ">
                      <li class="nav-item dropdown mega-menu">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="megaMenuDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Product
                        </a>
                        <div
                          class="dropdown-menu mega-menu-content"
                          aria-labelledby="megaMenuDropdown"
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <div className="menu_section">
                                <Link to="/checkout">
                                  <div className="content">
                                    <div className="soon_text">
                                      <h5>Checkout </h5>
                                    </div>
                                  </div>
                                </Link>
                                <Link to="/merchatAPI">
                                  <div className="content">
                                    <h5>Payment</h5>
                                  </div>
                                </Link>

                                <Link to="/invoice">
                                  <div className="content">
                                    <div className="soon_text">
                                      <h5>Invoice</h5>
                                    </div>
                                  </div>
                                </Link>
                                <Link to="/merchatAPI">
                                  <div className="content">
                                    <h5>E-Commerce Integration</h5>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul class="navbar-nav ">
                      <li class="nav-item dropdown mega-menu">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="megaMenuDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Solution
                        </a>
                        <div
                          class="dropdown-menu mega-menu-content"
                          aria-labelledby="megaMenuDropdown"
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <div className="menu_section">
                                <Link to="#">
                                  <div className="content">
                                    <div className="soon_text">
                                      <h5>E-commerce & Plugins </h5>
                                    </div>
                                  </div>
                                </Link>

                                <Link to="#">
                                  <div className="content">
                                    <h5>Crypto Token Purchase</h5>
                                  </div>
                                </Link>

                                <Link to="#">
                                  <div className="content">
                                    <h5>Freelancing Platforms</h5>
                                  </div>
                                </Link>

                                <Link to="#">
                                  <div className="content">
                                    <div className="soon_text">
                                      <h5>Donations</h5>
                                    </div>
                                  </div>
                                </Link>

                                <Link to="#">
                                  <div className="content">
                                    <h5>Ticketing and Event booking</h5>
                                  </div>
                                </Link>

                                <Link to="#">
                                  <div className="content">
                                    <h5>Travel & Tourism Product</h5>
                                  </div>
                                </Link>

                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </>
                )}
              </div>

              {loginCheck == false ? (
                <>
                  <Link to="/Accounttype" className="Butn_header2  mr-0">
                    Sign Up<i class="fa-solid fa-arrow-right-long"></i>
                  </Link>
                  <Link to="/login" className="Butn_header3 mr-0">
                    Sign In<i class="fa-solid fa-arrow-right-long"></i>
                  </Link>
                </>
              ) : (
                ""
              )}

              <div className="notofy_icone">
                {loginCheck && loginCheck == true ? (
                  <div className="notification_section  padingrespos ">
                    <Button data-toggle="collapse" data-target="#notification">
                      <i class="fa-regular fa-bell mr-3 header-icon"></i>
                    </Button>

                    {userDetails == "User" ? (
                      <Button onClick={() => navigate("/userprofile")}>
                        <i class="fa-regular fa-circle-user header-icon "></i>
                      </Button>
                    ) : (
                      <Button onClick={() => navigate("/profile")}>
                        <i class="fa-regular fa-circle-user header-icon"></i>
                      </Button>
                    )}

                    <div id="notification" class="collapse">
                      <div className="content_notification">
                        {userDetails == "User" ? (
                          <h3>
                            Notification{" "}
                            <Link to="/usernotification" className="viewss">View all</Link>
                          </h3>
                        ) : (
                          <h3>
                            Notification{" "}
                            <Link to="/notification" className="viewss">View all</Link>
                          </h3>
                        )}

                        <div className="overflow_seee">
                          {notifications && notifications.length > 0
                            ? notifications.map((item, i) => {
                              return (
                                <>
                                  {userDetails == "User" ? (
                                    <Link
                                      to="/usernotification"
                                      className="msg-noti"
                                    >
                                      <small>
                                        {Moment(item.createdAt).fromNow()}
                                      </small>
                                      <p>{item.message}</p>
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/notification"
                                      className="msg-noti"
                                    >
                                      <small>
                                        {Moment(item.createdAt).fromNow()}
                                      </small>
                                      <p>{item.message}</p>
                                    </Link>
                                  )}
                                </>
                              );
                            })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {loginCheck == true ? (
                <>
                  {userDetailsref.current == "User" ? (
                    <>
                      <Link to="" className="headerbtn mr-3" onClick={toggle1}>
                        Logout <i class="ri-logout-box-r-line ml-1"></i>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="" className="headerbtn " onClick={toggle1}>
                        Logout <i class="ri-logout-box-r-line ml-1"></i>
                      </Link>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
        </Toolbar>

        <Modal
          isOpen={modal1}
          toggle={toggle1}
          className="rem10 checkout_modal"
        >
          <ModalBody className="p-0">
            <div class="Frame-14722">
              <span class="Item-Details h2">
                <i class="ri-logout-box-line mr-2"></i> Logout{" "}
              </span>
              <div class="">
                <i class="ri-close-fill  Close" onClick={goto1}></i>
              </div>
            </div>

            <div className="py-2">
              <p className="Deletecontent my-4">
                Are you sure want to logout this session ?
              </p>

              <div className="row card_login flex-row pb-0 pt-2">
                <div className="col-md-6">
                  <div className="form_login_input">
                    <Button className="discardbtn" onClick={goto1}>
                      Discard
                    </Button>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form_login_input">
                    <Button onClick={logout}>Logout</Button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
