import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function App() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        navigation={true}
        modules={[Navigation]}
        // navigation={{
        //   clickable: true,
        // }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide className="feature-slider-card1">
          <img src={require("../Image/New-Home/step-1.png")} />
          <h3 className="step-title"> STEP 01</h3>
          <p className="step-content">
            Customer acceptance of your cryptocurrency ecosystem
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/step-2.png")} />
          <h3 className="step-title"> STEP 02</h3>
          <p className="step-content">
            Your client decides to use cryptocurrency as payment.
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/step-3.png")} />
          <h3 className="step-title"> STEP 03</h3>
          <p className="step-content">
            The client choose the currency they
            <br /> want to use to make the payment.
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/step-4.png")} />
          <h3 className="step-title"> STEP 04</h3>
          <p className="step-content">
            The deposit address receives crypto from the customer.
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/step-5.png")} />
          <h3 className="step-title"> STEP 05 </h3>
          <p className="step-content">
            You get paid in the currency of your choosing.
          </p>
        </SwiperSlide>
        <SwiperSlide className="display_none"></SwiperSlide>
      </Swiper>
    </>
  );
}
