import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

function Home() {
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [referralHistory, setreferralHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refferance, setrefferance] = useState("");
  const [referID, setreferID] = useState("");
  const [referLink, setreferLink] = useState("");
  const [loader, Setloader] = useState(false);
  const title = "Welcome to Beleaf";

  useEffect(() => {
    getReferralDetails();
    getreferralHistory(1);
  }, [0]);

  const recordPerPage = 5;
  const pageRange = 5;

  const getReferralDetails = async () => {
    try {
      Setloader(true);
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        Setloader(false);
        var currURL = window.location.href.split("refferal")[0];
        var link = currURL + "register?/invite/" + resp.data.referralCode;
        link = link.substring(0, 10) + "...." + link.substring(link.length - 5);
        setrefferance(link);
        setreferID(resp.data.referralCode);
        var referlink = currURL + "register?/invite/" + resp.data.referralCode;
        setreferLink(referlink);
      } else {
      }
    } catch (error) {}
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Referral Link copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getreferralHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.referralHistory,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setreferralHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const handlePageChange = (pageNumber) => {
    getreferralHistory(pageNumber);
    setCurrentPage(pageNumber);
  };
  return (
    <div className="header">
      <Header />
      <main>
        {loader == true ? (
          <div class="spinner css-ezm1fk">
            <div class="css-1qoumk2"></div>
            <div class="css-1v19680"></div>
            <div class="css-1v90jie"></div>
            <div class="css-19p0rvp"></div>
          </div>
        ) : (
          <>
            <section className="section_projec first_refer_dection">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="refer-content refer-class">
                      <h1>
                        Refer Friends.
                        <br></br>Earn Crypto Together
                      </h1>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card_section_refer">
                      <div className="reder_input_copy">
                        <label>Referral ID</label>
                        <p>
                          {referID}{" "}
                          <i
                            class="ri-file-copy-fill"
                            onClick={() => copy(referID)}
                          ></i>
                        </p>
                      </div>
                      <div className="reder_input_copy">
                        <label>Referral Link</label>
                        <p>
                          {refferance}{" "}
                          <i
                            class="ri-file-copy-fill"
                            onClick={() => copy(referLink)}
                          ></i>
                        </p>
                      </div>
                      <div className="socila_refere">
                        <ul>
                          <li>
                            {/* <a href="" target="_blank">
                          <i class="ri-facebook-circle-fill"></i>
                        </a> */}
                            <FacebookShareButton url={referLink} title={title}>
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </li>
                          <li>
                            {/* <a href="" target="_blank">
                          <i class="ri-whatsapp-fill"></i>
                        </a> */}
                            <WhatsappShareButton url={referLink} title={title}>
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </li>
                          <li>
                            {/* <a href="" target="_blank">
                          <i class="ri-telegram-fill"></i>
                        </a> */}
                            <TelegramShareButton url={referLink} title={title}>
                              <TelegramIcon size={32} round />
                            </TelegramShareButton>
                          </li>
                          <li>
                            {/* <a href="" target="_blank">
                          <i class="ri-twitter-fill"></i>
                        </a> */}
                            <TwitterShareButton url={referLink} title={title}>
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </li>
                          <li>
                            {/* <a href="" target="_blank">
                          <i class="ri-instagram-fill"></i>
                        </a> */}
                            <InstapaperShareButton
                              url={referLink}
                              title={title}
                            >
                              <InstapaperIcon size={32} round />
                            </InstapaperShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="tow_refer">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="reer_title">
                      <i class="ri-gift-fill"></i> Referral History
                    </h2>
                    <div className="market_tabel w-100">
                      <div className="market_section">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Email</th>
                                <th>User Name</th>
                                <th>Date / time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {referralHistory.length > 0 ? (
                                referralHistory &&
                                referralHistory.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>{item.email}</td>
                                      <td>{item.username}</td>
                                      <td>
                                        {moment(item.createdDate).format("lll")}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <td colSpan="7">
                                  <span className="w-100 text-center d-block">
                                    No Refferal History Found!
                                  </span>
                                </td>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {referralHistory.length > 0 ? (
                      <Pagination
                        itemClass="page-item" // add it for bootstrap 4
                        linkClass="page-link" // add it for bootstrap 4
                        activePage={currentPage}
                        itemsCountPerPage={recordPerPage}
                        totalItemsCount={totalPage}
                        pageRangeDisplayed={pageRange}
                        onChange={handlePageChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="tow_refer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="reer_title">
                  <i class="ri-gift-fill"></i> My Rewards
                </h2>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Distribution Date</th>
                        <th> Reward Name</th>
                        <th className="text-right"> Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                      <tr>
                        <td>ID243423424</td>

                        <td>01-01-2023</td>
                        <td>Invite</td>
                        <td className="text-right text-green">success</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          </>
        )}
      </main>
       {/* <Footer /> */}
    </div>
  );
}

export default Home;
