import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import Sidebar from "./Sidebarlink";
import usestateref from "react-usestateref";
import apiService from "../core/service/detail";
import {postMethod, getMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

function Home() {
  const [userName, setUsername] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonloader, setButtonloader] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, [0]);

  const formSubmit = async () => {
    try {
      var data = {
        apiUrl: apiService.editProfile,
        payload: {phoneNumber: phoneNumber, userName: userName},
      };
      var resp = await postMethod(data);
      setLoader(true);
      if (resp.Status == true) {
        setLoader(false);
        toast.success("Profile Updated Successfully");
        getUserDetails();
      }
    } catch (error) {}
  };
  const getUserDetails = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      setUsername(resp.data.username);
      setPhone(resp.data.mobileNumber);
      // formData.userName = resp.data.username
      // formData.phoneNumber = resp.data.mobileNumber
      // console.log(formData,"=-=-=username")
      // console.log(formData.phoneNumber,"=-=-=phoneNumber")
    } else {
    }
  };

  // const handleChange = async (e) => {
  //   //e.preventDefault();
  //   const { name, value } = e.target;
  //   let formValue = { ...formData, ...{ [name]: value } };
  //   setformData(formValue);
  //   console.log(formData, "=-=-=-=-")
  // };
  const handleUsername = async (e) => {
    setUsername(e.target.value);
  };

  const handlePhone = async (e) => {
    setPhone(e.target.value);
  };
  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          {loader == true ? (
            <div class="spinner css-ezm1fk">
              <div class="css-1qoumk2"></div>
              <div class="css-1v19680"></div>
              <div class="css-1v90jie"></div>
              <div class="css-19p0rvp"></div>
            </div>
          ) : (
            <div className="css-1h690ep">
              <div className="section_bg_ash">
                <div className="section_selec_ash">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="heading_titlr">Edit Profile</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inner_conteten pt-4">
                  <div className="contant_secion_balance mt-4">
                    <div className="row justify-content-center mt-3">
                      <div className="col-lg-4">
                        <div className="row ">
                          <div className="col-lg-12">
                            <h3 className="input_labele">Username</h3>
                            <div className="with-eye">
                              <input
                                name="userName"
                                className="pan_number"
                                value={userName}
                                onChange={handleUsername}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <h3 className="input_labele">Mobile Number</h3>
                            <div className="with-eye">
                              <input
                                type="number"
                                value={phoneNumber}
                                className="pan_number"
                                name="phoneNumber"
                                onChange={handlePhone}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            {buttonloader == true ? (
                              <Button className="changepassword">
                                Loading...
                              </Button>
                            ) : (
                              <Button
                                onClick={formSubmit}
                                className="changepassword"
                              >
                                Change
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {loader == true ? (
        <div class="spinner css-ezm1fk">
          <div class="css-1qoumk2"></div>
          <div class="css-1v19680"></div>
          <div class="css-1v90jie"></div>
          <div class="css-19p0rvp"></div>
        </div>
      ) : (
         {/* <Footer /> */}
      )}
    </div>
  );
}

export default Home;
