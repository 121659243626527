import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Dropdown, Loader, Input } from "semantic-ui-react";
import { IconCopy } from "@tabler/icons-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import "semantic-ui-css/semantic.min.css";
import useState from "react-usestateref";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import { Button } from "@mui/material";
const moment = require("moment");

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: 30,
  },

  title: {
    marginTop: 10,
    fontSize: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  method: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50,
  },
  pay: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },

  Date: {
    fontSize: 10,
    marginTop: 20,
    borderBottom: "1px solid black",
  },
  Bill: {
    fontSize: 10,
    marginTop: 20,
  },
  ID: {
    fontSize: 15,
    marginTop: 10,
  },
  Text: {
    marginBottom: 10,
  },
  section: {
    fontSize: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    display: "table",
    borderCollapse: "collapse",
    marginTop: 10,
    marginBottom: 50,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1px solid black",
    fontSize: 10,
    padding: 8,
    flex: 1,
  },
});
const MyDocument = (obj) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.title}>
        <Text>Invoice </Text>
        <Text>Gatecryptopay</Text>
      </View>
      <View style={styles.ID}>
        <Text>{ } </Text>
      </View>
      <View style={styles.Date} className="mt-5">
        <Text style={styles.Text}>
          {" "}
          Invoice Date : {moment(obj.created_at).format("lll")}{" "}
        </Text>
        <Text style={styles.Text}>
          {" "}
          Due In: {moment(obj.due).format("lll")}{" "}
        </Text>
      </View>
      <View style={styles.Bill} className="mt-5">
        <Text style={styles.Text}> Billed From : Gatecryptopay </Text>
        <Text style={styles.Text}> Billed To : {obj.cutomerName} </Text>
        <Text style={styles.Text}> Receiver Email : {obj.email} </Text>
      </View>
      <View style={styles.section}>
        <Text>{obj.memo}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Product</Text>
          <Text style={styles.tableCell}>Qty</Text>
          <Text style={styles.tableCell}>Unit Price</Text>
          <Text style={styles.tableCell}>Total Amount</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}> {obj.itemname} </Text>
          <Text style={styles.tableCell}>{obj.quantity}</Text>
          <Text style={styles.tableCell}>
            {obj.amount} {obj.currency}{" "}
          </Text>
          <Text style={styles.tableCell}>
            {obj.quantity * obj.amount} {obj.currency}
          </Text>
        </View>
      </View>
      <View style={styles.method}>
        <Text> </Text>
        <Text>Due {moment(obj.due).format("lll")}</Text>
      </View>
      <View style={styles.title}>
        <Text> </Text>
        <Text>
          {obj.amount * obj.quantity} {obj.currency}
        </Text>
      </View>
    </Page>
  </Document>
);

function Invoicecharge() {
  useEffect(() => {
    var id = window.location.href.split("/").at(4);
    getInvoiceDetails(id);
  }, []);

  const navigate = useNavigate();

  const initialFormValue = { email: "", walletaddress: "", payid: "", otp: "" };

  const [formValue, setFormValue] = useState(initialFormValue);

  const { payid, email, otp, walletaddress } = formValue;

  const [data, setData, dataref] = useState("");
  const [Currentdate, SetCurrentdate, Currentdateref] = useState("");
  const [duedate, Setduedate, duedateref] = useState("");
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);
  const [currencyid, setcurrencyid, currencyidref] = useState("");
  const [currency, setCurrency, currencyref] = useState("");
  const [totalamount, Settotalamount, totalamountref] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [emailErr, SetemailErr, emailErrref] = useState(false);
  const [walletaddressErr, SetwalletaddressErr, walletaddressErrref] =
    useState(false);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [address, Setaddress, addressref] = useState("");
  const [changes, Setchanges, changesref] = useState(false);

  const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
  const [Terms, setTerms] = useState(false);
  const [termsValidation, setTermsValidation, termsValidationref] =
    useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [uuids, setuuids, uuidsref] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const goto = () => navigate("/");
  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log("formData====", formData);
    setFormValue(formData);
  };
  const handleTerms = (event) => {
    if (event.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
    setIschecked((current) => !current);
  };
  const textRef2 = useRef(null);

  const handleCopy2 = () => {
    const textToCopy = textRef2.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Code Copied");
      })
      .catch((error) => {
        toast.error("Failed to copy code");
      });
  };
  const getInvoiceDetails = async (id) => {
    var obj = {
      id: id,
    };
    var data = {
      apiUrl: apiService.get_Invoice_Details_cutomer,
      payload: obj,
    };
    var resp = await postMethod(data);

    if (resp.status == true) {


    
      setData(resp.data);

      console.log(dataref.current.userId, "dataref");

      const currentDate = new Date();
      const dueDate = new Date(resp.data.due);
      SetCurrentdate(currentDate);
      Setduedate(dueDate);
      var currArrayFiat = [{ value: "all", label: "" }];
      var data = resp.Currency;
      console.log(data, "datadatadatadatadata");

      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
          coinType: data[i].coinType,
          erc20token: data[i].erc20token,
          bep20token: data[i].bep20token,
          trc20token: data[i].trc20token,
          rptc20token: data[i].rptc20token,
        };
        currArrayFiat.push(obj);
      }

      console.log(currArrayFiat);
      setToCurrencyRef(currArrayFiat);
    
    }
  };

  const onSelect = async (option, e) => {
    setcurrencyid(e.value);
    setCurrency(option.target.outerText);

    const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${data.currency}&tsyms=${currencyref.current}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;

    axios
      .get(apiUrl)
      .then(async (response) => {
        var value = response.data[data.currency][currencyref.current];
        console.log(value, "value");
        var total = (value * data.quantity * data.amount).toFixed(4);

        Settotalamount(total);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const validate = async (values) => {
    const errors = {};
    if (currency == "") {
      errors.currency = "Currency is required !";
      setCurrencyErr(true);
    } else {
      setCurrencyErr(false);
    }

    if (values.email == "") {
      errors.email = "email is required !";
      SetemailErr(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      SetemailErr(true);
    } else {
      SetemailErr(false);
    }

    if (values.walletaddress == "") {
      errors.walletaddress = "wallet address is required !";
      SetwalletaddressErr(true);
    } else {
      SetwalletaddressErr(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const Proceed = async () => {
    console.log(formValue, "formValue");

    validate(formValue);

    if (
      currency != "" &&
      emailErrref.current == false &&
      walletaddressErrref.current == false
    ) {
      var obj = {
        currency: currencyref.current,
        currId: currencyidref.current,
        MerchatId: dataref.current.userId,
        walletaddress: formValue.walletaddress,
      };
      var data = {
        apiUrl: apiService.generateMerchatAddress,
        payload: obj,
      };
      setButtonLoader(false);

      var resp = await postMethod(data);
      setButtonLoader(false);

      console.log(resp);
      if (resp.status == true) {
        Setaddress(resp.Message.address);
        Setchanges(true);
      }
    } else {
      console.log("please select the currency");
    }
  };
  const textRef1 = useRef(null);
  const handleCopy1 = () => {
    const textToCopy = textRef1.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Code Copied");
      })
      .catch((error) => {
        toast.error("Failed to copy code");
      });
  };

  const Pay = async () => {
    validate(formValue);
    console.log("hello");
    if (termsValidationref.current == false) {
      var obj = {
        InvoiceId : dataref.current._id,
        productId: dataref.current.itemname,
        MerchantId: dataref.current.userId,
        email: formValue.email,
        walletaddress: formValue.walletaddress,
        address: addressref.current,
        amount: totalamountref.current,
        currency: currencyref.current,
      };
      var data = {
        apiUrl: apiService.Invoicesubmit,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp) {
        if (resp.status) {
          toast.success(resp.Message);
          setuuids(resp.data);
          setModal(!modal);
          // setuuids(resp.data);
        } else {
          toast.error(resp.Message);
          navigate("/");
          // setModal(!modal);
        }
      } else {
        console.log("Something Went Wrong");
      }
    } else {
      console.log("Something Went Wrong");
    }
  };

  return (
    <section className="section_projec bg_trrrr w-100">
      <div className="container">
        <div className="row py-5 justify-content-center">
          {data.status == 1 ? (
            <>
              <div className="col-lg-12 py-5">
                <h3 className="text-center my-5">
                  Your Payment Completed Successfully!.
                </h3>
                <img
                  src={require("../Image/invopay.png")}
                  className="my-5 mx-auto d-block"
                  width="40% "
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div class="form_login_input">
                    <Button onClick={() => navigate("/")}>goto Home</Button>
                  </div>
                </div>
              </div>
            </>
          ) : duedate > Currentdate == true ? (
            <>
              <div className="col-lg-6 mt-5">
                <div className="p-5 mt-3">
                  <div>
                    <h3>Gatecryptopay</h3>
                    <h4 className="text-secondary d-flex">
                      Invoice :
                      <span className="ml-3">
                        <PDFDownloadLink
                          document={MyDocument(data)}
                          fileName={`${data.invoiceid}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <>
                                <p>
                                  url
                                  {data.invoiceid}{" "}
                                  <i class="fa-solid fa-download"></i>
                                </p>
                              </>
                            )
                          }
                        </PDFDownloadLink>
                      </span>
                    </h4>
                    <h2>
                      {data.quantity * data.amount} {data.currency}
                    </h2>
                    <h5 className="text-secondary">
                      Due In : {moment(data.due).format("lll")}
                    </h5>
                    <hr />

                    <p>{`To   :        ${data.cutomerName}`} </p>
                    <p>From : Gatecryptopay</p>
                    <p>Term : {data.memo}</p>
                    <hr />
                    <PDFDownloadLink
                      document={MyDocument(data)}
                      fileName={`${data.invoiceid}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <>
                            <p>
                              View Invoice{" "}
                              <i class="fa-solid fa-file-arrow-down"></i>{" "}
                            </p>
                          </>
                        )
                      }
                    </PDFDownloadLink>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 ">
                <div className=" border rounded shadow p-5">
                  <div className="">
                    <h3>Pay With Gate Crypto Pay</h3>

                    {changesref.current == true ? (
                      <div className="">
                        <div className="widgetbottom">
                          <div className="checkpage">
                            <div>
                              <div className="mt-2 Textprimary"></div>

                              <div className=" my-4">
                                <h4 className="text-dark ">
                                  {" "}
                                  Selected Currency{" "}
                                </h4>
                                <p className="  Textprimary Drops text-white px-3  ">
                                  {currency}
                                </p>
                              </div>

                              <div className=" my-4">
                                <h4 className="text-dark ">Total Amount </h4>
                                <p className="  Textprimary Drops text-white px-3  ">
                                  {totalamountref.current} {currency}
                                </p>
                              </div>

                              <div className=" my-4">
                                <h4 className="text-dark ">Address </h4>
                                <p
                                  className="  Textprimary Drops text-white px-3 d-flex  justify-content-between "
                                  ref={textRef1}
                                >
                                  {addressref.current}

                                  <IconCopy
                                    onClick={handleCopy1}
                                    className="ml-3"
                                  />
                                </p>
                              </div>

                              <div class="form-group form-check mt-3 mb-1">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="exampleCheck1"
                                  onChange={handleTerms}
                                  value={isChecked}
                                />
                                <label
                                  class="addoverflow text-dark"
                                  for="exampleCheck1"
                                >
                                  I have deposited the currency
                                </label>
                              </div>
                              <div>
                                {termsValidationref.current == true ? (
                                  <p className="textdang mt-0">
                                    Confirm the deposit
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="">
                                {ButtonLoader == false ? (
                                  <Button
                                    className="Drops Textprimary border mt-2"
                                    onClick={Pay}
                                  >
                                    Pay Now
                                  </Button>
                                ) : (
                                  <Button
                                    className="Drops Textprimary border  mt-5"
                                    disabled
                                  >
                                    Loading...
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="widgetbottom">
                          <div className="checkpage">
                            <div className="coin_select">
                              <h5 className="heading_class_h5 Textprimary pt-4 pb-2">
                                Select currencies
                              </h5>
                              <Dropdown
                                className="shadow Drops"
                                placeholder="Select Currencies"
                                fluid
                                selection
                                options={toCurrencyRef.current}
                                onChange={onSelect}
                              // onClick={onSelect}
                              />
                              <div>
                                {setCurrencyErrref.current == true ? (
                                  <p className="text-danger mt-2">
                                    {validationnErr.currency}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="coin_select">
                              <h5 className="heading_class_h5 Textprimary  pb-2">
                                Email
                              </h5>
                              <input
                                className="shadow Drops"
                                placeholder="Enter your email address"
                                type="text"
                                value={email}
                                name="email"
                                onChange={handleChange}
                              />
                              {emailErrref.current == true ? (
                                <p className="text-danger mt-2">
                                  {validationnErr.email}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="coin_select">
                              <h5 className="heading_class_h5 Textprimary pt-4 pb-2">
                                Wallet Address
                              </h5>
                              <input
                                className="shadow Drops"
                                placeholder="Enter your wallet address"
                                type="text"
                                value={walletaddress}
                                name="walletaddress"
                                onChange={handleChange}
                              />
                              <div>
                                {walletaddressErrref.current == true ? (
                                  <p className="text-danger mt-2">
                                    {validationnErr.walletaddress}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <h4 className="Textprimary d-flex">
                              Total Amount : {data.quantity * data.amount}{" "}
                              {data.currency}
                            </h4>

                            {ButtonLoader == false ? (
                              <Button
                                className="swap_but newbutton"
                                onClick={Proceed}
                              >
                                Proceed
                              </Button>
                            ) : (
                              <Button className="swap_but newbutton" disabled>
                                Loading....
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-12 py-5">
                <h3 className="text-center my-5">Your Due Date is expired.</h3>
                <img
                  src={require("../Image/dueout.png")}
                  className="my-5 mx-auto d-block"
                  width="30% "
                />
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div class="form_login_input">
                    <Button onClick={() => navigate("/")}>goto Home</Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="p-5">
            <h2 className="text-center text-success h1"> Payment Success !</h2>
            <div>
              <img
                width="20%"
                src={require("../Image/tick.jpg")}
                className="mx-auto d-block my-5"
              />

              <div className=" my-4">
                <h4 className="Textprimary">Your Transaction ID </h4>
                <p
                  className="  Textprimary Dropchecks text-white px-3 d-flex  justify-content-between "
                  ref={textRef2}
                >
                  {uuidsref.current}

                  <IconCopy onClick={handleCopy2} className="ml-3" />
                </p>
              </div>
              <h3 className="text-center mb-5">
                Your Order has been confirmed <br /> check your email for
                details
              </h3>
            </div>
            <div>
              <Button
                className="text-white mx-auto d-block bg-success"
                onClick={goto}
              >
                Go Back
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </section>
  );
}
export default Invoicecharge;
