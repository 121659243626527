import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";

import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebarlink";
import UserSidebar from "./userslidbar";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IconPencil } from "@tabler/icons-react";
import { IconArrowBack } from "@tabler/icons-react";
import { IconCopy } from "@tabler/icons-react";
import validator from "validator";

function Home() {
  const textRef = useRef(null);

  const handleCopy1 = () => {
    const textToCopy = textRef.current.value;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Code Copied");
      })
      .catch((error) => {
        toast.error("Failed to copy code");
      });
  };

  useEffect(() => {
    getTfastatus();
    profile();
  }, []);
  const navigate = useNavigate();
  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };

  const initialValue = {
    Name: "",
    phoneNumber: "",
    Address: "",
    imageLable: "",
    BussinessName: "",
    Website: "",
    payment: "",
  };
  const [formdata, setFormdata] = useState(initialValue);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaUrl, settfaUrl] = useState("");
  const [userMail, setuserMail] = useState("");
  const [tfaCode, settfaCode] = useState("");
  const [tfaStatus, settfaStatus] = useState("");
  const [ValidationErrors, setValidationErrors] = useState({});
  const [oldpassvalidate, setoldpassvalidate] = useState(false);
  const [passwordValidate, setpasswordValidate] = useState(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate] = useState(false);
  const [passconfNotmatch, setpassconfNotmatch] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passhide1, setpasshide1] = useState(false);
  const [inputtype1, setinputtype1] = useState("password");
  const [passhide2, setpasshide2] = useState(false);
  const [inputtype2, setinputtype2] = useState("password");
  const [passhide3, setpasshide3] = useState(false);
  const [inputtype3, setinputtype3] = useState("password");
  const [type, settype] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [title, settitle] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [address, setaddress] = useState("");
  const [site, setsite] = useState("");
  const [editStatus, seteditStatus] = useState(false);
  const [Profile, setprofile] = useState("");
  const [imagelable, setimagelable] = useState("");
  const [nameErr, setNameErr, nameErrref] = useState(false);
  const [phoneNumberErr, setphoneNumberErr, phoneNumberErrref] = useState(false);
  const [BussinessNameErr, setBussinessNameErr] = useState(false);
  const [WebsiteErr, setWebsiteErr] = useState(false);
  const [addressErr, setaddressErr, addressErrref] = useState(false);
  const [imagelableErr, setimagelableErr] = useState(false);
  const { oldpassword, password, confirmPassword } = formValue;
  const { Name, phoneNumber, Address, imageLable, BussinessName, Website } =
    formdata;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };


    // let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate1(formData);
  };

  const handleProfileChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    console.log(name, "name", value);

    if (name == "Name") {
      console.log(name, "name1", value, "value");

      const sanitizedValue = value.replace(/\s/g, "");
      let Data = { ...formdata, ...{ [name]: sanitizedValue } };
      setFormdata(Data);
      validate(Data);
    } else if (name == "Address") {
      const sanitizedValue3 = value.trimLeft();
      let Data = { ...formdata, ...{ [name]: sanitizedValue3 } };
      setFormdata(Data);
      validate(Data);
    } else if (name == "phoneNumber") {

      const sanitizedValue1 = value.replace(/[+\-eE]/g, "");

      console.log(sanitizedValue1, "sanitizedValue1");

      if (sanitizedValue1.length < 11) {
        let Data = { ...formdata, ...{ [name]: sanitizedValue1 } };
        setFormdata(Data);
        validate(Data);
      }
    } else {
      console.log(name, "name2", value, "value");

      let Data = { ...formdata, ...{ [name]: value } };
      setFormdata(Data);
      validate(Data);
    }
  };

  const validate1 = async (values) => {
    const errors = {};

    console.log(values, "values");

    if (values.oldpassword == "") {
      setoldpassvalidate(true);
      errors.oldpassword = "Old Password is required ";
    } else if (
      values.oldpassword.length < 5 ||
      values.oldpassword.length > 25
    ) {
      setoldpassvalidate(true);
      errors.oldpassword =
        "Old Password should not below 5 above 25 letters ";
    } else if (!values.oldpassword.match(/[a-z]/g)) {
      setoldpassvalidate(true);
      errors.oldpassword = "Please enter at least lower character ";
    } else if (!values.oldpassword.match(/[A-Z]/g)) {
      setoldpassvalidate(true);
      errors.oldpassword = "Please enter at least upper character ";
    } else if (!values.oldpassword.match(/[0-9]/g)) {
      setoldpassvalidate(true);
      errors.oldpassword = "Please enter at One digit character ";
    } else if (!values.oldpassword.match(/[!@#$%^&*]/g)) {
      setoldpassvalidate(true);
      errors.oldpassword = "Please enter at least one special character ";
    } else if (values.password == "") {
      setoldpassvalidate(false);
      setpasswordValidate(true);
      errors.password = "Password is required ";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Password should not below 5 above 25 letters ";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character ";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character ";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character ";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character ";
    } else if (values.password === values.oldpassword) {
      errors.password = "Old Password and New Password are same ";
      setpasswordValidate(true);
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors.confirmPassword = "Confirm Password is a required field";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and Confirm Password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
      setpasswordValidate(false);
      setoldpassvalidate(false);


    }

    setvalidationnErr(errors);
    return errors;
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.Name) {
      errors.Name = "Name is a required field ";
      setNameErr(true);
    } else if (!/^[a-zA-Z]/.test(values.Name)) {
      errors.Name = "Username must start with a letter ";
      setNameErr(true);
    } else if (values.Name.length < 3 || values.Name.length > 25) {
      errors.Name = "Name must have an  3 to 25 characters ";
      setNameErr(true);
    } else if (!/^[a-zA-Z0-9_]+$/.test(values.Name)) {
      errors.Name =
        "Username can only contain letters, numbers, and underscores ";
      setNameErr(true);
    } else if (values.phoneNumber == "" || values.phoneNumber == null) {
      setNameErr(false);
      errors.phoneNumber = "phone Number is required";
      setphoneNumberErr(true);
    } else if (values.phoneNumber.length < 10) {
      errors.phoneNumber = "Invalid phone number";
      setphoneNumberErr(true);
    } else if (values.Address == "") {
      setphoneNumberErr(false);
      errors.Address = "Address is required";
      setaddressErr(true);
    } else if (values.Address?.length < 3 || values.Address?.length > 50) {
      errors.Address = "Address must have an  3 to 50 characters ";
      setaddressErr(true);
    } else {
      setphoneNumberErr(false);
      setNameErr(false);
      setaddressErr(false);
    }

    if (imagelable == "") {

      errors.imageLable = "Image is required";
      setimagelableErr(true);
    } else if (values.Website == "" || values.Website == null) {
      setimagelableErr(false);

      errors.Website = "Website is required";
      setWebsiteErr(true);
    } else if (values.BussinessName == "" || values.BussinessName == null) {
      setWebsiteErr(false);

      errors.BussinessName = "Bussiness Name is required";
      setBussinessNameErr(true);
    } else {
      setBussinessNameErr(false);
    }
    setvalidationnErr(errors);
    return errors;
  };


  const formSubmit = async (payload) => {
    validate1(formValue);

    console.log(oldpassvalidate,
      passwordValidate,
      confirmPasswordValidate)
    if (
      oldpassvalidate == false &&
      passwordValidate == false &&
      confirmPasswordValidate == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp.email);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        localStorage.clear();
        navigate("/");
        window.location.reload(true);
      } else {
        toast.error(resp.Message);
      }
    } else {
      console.log("all field requird");
    }
  };

  const oldPassword = (data1) => {
    if (data1 == "hide") {
      setpasshide1(true);
      setinputtype1("text");
    } else {
      setpasshide1(false);
      setinputtype1("password");
    }
  };

  const newPassword = (data2) => {
    if (data2 == "hide") {
      setpasshide2(true);
      setinputtype2("text");
    } else {
      setpasshide2(false);
      setinputtype2("password");
    }
  };

  const conPassword = (data3) => {
    if (data3 == "hide") {
      setpasshide3(true);
      setinputtype3("text");
    } else {
      setpasshide3(false);
      setinputtype3("password");
    }
  };

  const getTfastatus = async () => {
    var data = {
      apiUrl: apiService.getTfaDetials,
    };

    var resp = await getMethod(data);
    console.log(resp, "=-=-=-resp");
    settfaUrl(resp.data.tfa_url);
    setuserMail(resp.data.email);
    settfaStatus(resp.data.tfastatus);
    localStorage.setItem("tfa_status", resp.data.tfastatus);
  };

  const profile = async () => {
    try {
      var data = {
        apiUrl: apiService.profiledetail,
      };

      var resp = await getMethod(data);
      console.log(resp.Message);
      if (resp.status === true) {
        console.log(resp.Message);
        setemail(resp.Message.email);
        setname(resp.Message.name);
        setprofile(resp.Message.profile);
        formdata.Name = resp.Message.name;
        settitle(resp.Message.BussinessName);
        setsite(resp.Message.website);
        formdata.Website = resp.Message.website;
        formdata.BussinessName = resp.Message.BussinessName;
        formdata.payment = resp.Message.payment;
        settype(resp.Message.tyep);
        console.log(resp.Message.type);
        console.log(type);
        setusername(resp.Message.username);
        setphonenumber(resp.Message.mobileNumber);
        formdata.phoneNumber = resp.Message.mobileNumber;
        console.log(phonenumber);
        setaddress(resp.Message.address);
        setimagelable(resp.Message.profile);
        formdata.Address = resp.Message.address;

        console.log(address);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const edituserprofile = () => {
    seteditStatus(true);
  };

  const back = () => {
    seteditStatus(false);
  };

  const imageUpload = (val) => {
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "fgzjzpjf");
      data.append("cloud_name", "dysf7ocz6");
      console.log("formdata===", data);
      fetch("https://api.cloudinary.com/v1_1/dysf7ocz6/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data.secure_url);
          setprofile(data.secure_url);
          setimagelable(fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const EditProfile = async () => {
    validate(formdata);
    console.log(nameErrref, phoneNumberErrref, addressErrref, "formdata");
    if (
      nameErrref.current == false &&
      phoneNumberErrref.current == false &&
      addressErrref.current == false
    ) {
      var obj = {
        data: formdata,
        Profile,
        type: "Users"
      };
      var data = {
        apiUrl: apiService.editUserprofile,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      if (resp.status == true) {
        setbuttonLoader(false);
        seteditStatus(false);
        toast.success(resp.Message);
        profile();
      } else {
        toast.error(resp.Message);
      }
    } else {
      console.log("hbjbjbjbjbnj");
    }
  };

  return (
    <div className="header ">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <UserSidebar />
          </div>

          <div className="css-1h690ep">
            <div className="section_bg_ash">
              <div className="section_selec_ash">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="headin_deposur_s">
                        <h3>User Profile</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner_conteten pt-0">
                <div className="order_tabs card_login p-0">
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" href="#home1" className="active">
                        User Profile
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu2">
                        Change Password
                      </a>
                    </li>
                    {/* <li>
                      <a data-toggle="tab" href="#menu3">
                        Payment ID
                      </a>
                    </li> */}
                  </ul>
                  <div class="tab-content ">
                    <div id="home1" class="tab-pane fade in active show">
                      {editStatus != true ? (
                        <div className="row mx-5 justify-content-center dashcard1 MerProfile marging_phone">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center mt-5">
                              {Profile == "" ? (
                                <img
                                  src={require("../Image/profile.png")}
                                  className="profile_img mx-auto block "
                                />
                              ) : (
                                <img
                                  src={Profile}
                                  className="profile_img mx-auto block "
                                />
                              )}
                            </div>

                            <h5 className="text-center">{name}</h5>
                            <h5 className="text-center mb-4">{email}</h5>
                          </div>


                          <div className="col-md-6 py-5">
                            <div class="form_login_input">
                              <label>User Name</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="-"
                                value={name}
                                disabled
                              />
                            </div>
                            <div class="form_login_input">
                              <label>Email ID</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="-"
                                value={email}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6 py-5">
                            <div class="form_login_input">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="-"
                                value={phonenumber}
                                disabled
                              />
                            </div>
                            <div class="form_login_input">
                              <label>Address</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="-"
                                value={address}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-md-6 pb-5">
                            <div class="form_login_input mt-3">
                              <Button
                                className="gap0"
                                onClick={() => edituserprofile()}
                              >
                                Edit <i class="ri-edit-2-fill ml-3"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row mx-5 login_form justify-content-center dashcard1 MerProfile marging_phone">
                          <div className="col-md-12 editprofiles">
                            {/* <IconArrowBack onClick={back}></IconArrowBack> */}
                            <div className="d-flex  justify-content-center mt-5">
                              {Profile == "" ? (
                                <img
                                  src={require("../Image/profile.png")}
                                  className="profile_img mx-auto block "
                                />
                              ) : (
                                <img
                                  src={Profile}
                                  className="profile_img mx-auto block "
                                />
                              )}

                            </div>

                            <h5 className="text-center">{name}</h5>
                            <label htmlFor="customFile" className="custom-file-label">
                              <i className="ri-edit-2-fill editsicon" aria-hidden="true"></i>
                            </label>

                            <input
                              type="file"
                              className="custom-file-input dnone"
                              id="customFile"
                              onChange={(e) => imageUpload(e.target.files[0])}
                              onClick={(e) => e.target.value = null}
                            />
                            <h5 className="text-center mb-4">{email}</h5>
                          </div>


                          <div className="col-md-6  py-5">
                            <div class="form_login_input">
                              <label>User Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Name"
                                required=""
                                placeholder="User Name"
                                minLength={4}
                                maxLength={25}
                                value={formdata.Name}
                                onChange={handleProfileChange}
                              />
                              {nameErrref.current == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.Name}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="form_login_input">
                              <label>Email ID</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Email"
                                required=""
                                placeholder="Email ID"
                                disabled
                                value={email}
                              />

                            </div>
                          </div>
                          <div className="col-md-6 py-5 ">
                            <div class="form_login_input">
                              <label>Phone Number</label>
                              <input
                                type="Number"
                                class="form-control"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                minLength={6}
                                maxLength={10}
                                value={formdata.phoneNumber}
                                onChange={handleProfileChange}
                              />
                              {phoneNumberErrref.current == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.phoneNumber}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="form_login_input">
                              <label>Address</label>
                              <input
                                type="text"
                                class="form-control"
                                name="Address"
                                required=""
                                placeholder="Address"
                                minLength={3}
                                maxLength={50}
                                value={formdata.Address}
                                onChange={handleProfileChange}
                              />
                              {addressErrref.current == true ? (
                                <p className="text-danger">
                                  {" "}
                                  {validationnErr.Address}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 pb-3">
                            <div className="form_login_input mt-3">
                              <Button className="" onClick={back}>
                                Back
                              </Button>
                            </div>
                          </div>

                          <div className="col-md-6 pb-3 ">
                            <div className="form_login_input mt-3">
                              {buttonLoader == false ? (
                                <Button onClick={EditProfile}>Save</Button>
                              ) : (
                                <Button>Loading...</Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>



                    <div id="menu2" class="tab-pane fade">
                      <div className="contant_secion_balance mt-4">
                        <div className="row justify-content-center mt-3">
                          <div className="col-lg-6">
                            <div className="card_login login_form cardb p20 shadow">
                              <div>
                                <div class="form_login_input">
                                  <label>Old Password</label>

                                  <input
                                    type={inputtype1}
                                    class="form-control"
                                    placeholder="Old Password"
                                    name="oldpassword"
                                    value={oldpassword}
                                    onChange={handleChange}
                                  />
                                  <div className="input-group-addon ">
                                    {passhide1 == false ? (
                                      <i
                                        className="bi bi-eye-slash-fill eyeicons text-white"
                                        onClick={() => oldPassword("hide")}
                                      ></i>
                                    ) : (
                                      <i
                                        className="bi bi-eye-fill eyeicons text-white"
                                        onClick={() => oldPassword("show")}
                                      ></i>
                                    )}
                                  </div>

                                  {oldpassvalidate == true ? (
                                    <p className="text-danger btm30">
                                      {" "}
                                      {validationnErr.oldpassword}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div class="form_login_input">
                                  <label>New Password</label>

                                  <input
                                    type={inputtype2}
                                    class="form-control"
                                    placeholder="New password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                  />
                                  <div className="input-group-addon ">
                                    {passhide2 == false ? (
                                      <i
                                        className="bi bi-eye-slash-fill eyeicons text-white"
                                        onClick={() => newPassword("hide")}
                                      ></i>
                                    ) : (
                                      <i
                                        className="bi bi-eye-fill eyeicons text-white"
                                        onClick={() => newPassword("show")}
                                      ></i>
                                    )}
                                  </div>

                                  {passwordValidate == true ? (
                                    <p className="text-danger btm30">
                                      {" "}
                                      {validationnErr.password}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div class="form_login_input">
                                  <label>Confirm Password</label>

                                  <input
                                    type={inputtype3}
                                    class="form-control"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                  />
                                  <div className="input-group-addon ">
                                    {passhide3 == false ? (
                                      <i
                                        className="bi bi-eye-slash-fill eyeicons text-white"
                                        onClick={() => conPassword("hide")}
                                      ></i>
                                    ) : (
                                      <i
                                        className="bi bi-eye-fill eyeicons text-white"
                                        onClick={() => conPassword("show")}
                                      ></i>
                                    )}
                                  </div>

                                  {confirmPasswordValidate == true ? (
                                    <p className="text-danger btm30">
                                      {" "}
                                      {validationnErr.confirmPassword}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div class="form_login_input ">
                                {buttonLoader == false ? (
                                  <Button className="mt-5" onClick={formSubmit}>
                                    Change Password
                                  </Button>
                                ) : (
                                  <Button className="mt-5">Loading...</Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="menu3" class="tab-pane fade">
                      <div className="contant_secion_balance mt-6 ">
                        <div className="row justify-content-center mt-5">
                          <div className="col-lg-6 dashcard1 p-3">
                            <h4 className="text-dark">Payment ID</h4>
                            <div className="with-eye row">
                              <div className="col-sm-12 col-lg-10 left_eye">
                                <input
                                  type={inputtype1}
                                  name="oldpassword"
                                  required=""
                                  className="pan_number"
                                  id="exampleInputPassword1"
                                  placeholder="Old password"
                                  value={formdata.payment}
                                  onChange={handleChange}
                                  ref={textRef}
                                />
                                <span>
                                  {passhide1 == false ? (
                                    <i
                                      className="bi bi-eye-slash-fill mt-2"
                                      onClick={() => oldPassword("hide")}
                                    ></i>
                                  ) : (
                                    <i
                                      className="bi bi-eye-fill"
                                      onClick={() => oldPassword("show")}
                                    ></i>
                                  )}
                                </span>
                              </div>
                              <div className="col-sm-2 col-lg-2 ">
                                <div className="cutton_copy mar_top_20">
                                  <div onClick={handleCopy1}>Copy</div>
                                </div>{" "}
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
