import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import DOMPurify from "dompurify";

function Home() {
  const [currencyCount, setcurrencyCount] = useState({});
  useEffect(() => {
    viewMoreCurrency();
  }, []);
  const viewMoreCurrency = async () => {
    var data = {
      apiUrl: apiService.getContent,
      payload: { title: "About us" },
    };
    var resp = await postMethod(data);
    if (resp) {
      console.log(resp, "=-=-=-=currencyCount");
      setcurrencyCount(resp.data);
    } else {
    }
  };
  return (
    <div className="home ">
      <Header />
      <div className="terms_contition mt-5">
        <h1>{currencyCount.title}</h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currencyCount.content_description),
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
