import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { setAuthorization } from "../core/service/axios";

function Home() {

  const initialFormValue = {
    email: "",
    password: "",
  };

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    var UserType = localStorage.getItem("UserType");


    console.log(UserType, "UserType");
    if (token) {
      if (UserType == "Merchant") {
        navigate("/dashboard");
      } else {
        navigate("/userdashboard");
      }
    }
  }, []);

  const navigate = useNavigate();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const { email, password } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        values.email
      )
    ) {
      errors.email =
        "Invalid email address ";
      setemailValidate(true);
    } else if (values.email.length > 50) {
      errors.email = "Invalid email address ";
      setemailValidate(true);
    } else if (values.password == "") {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "password is required ";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Invalid Password ";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Invalid Password ";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Invalid Password ";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character ";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Invalid Password ";
    } else {
      setpasswordValidate(false);
      setemailValidate(false);

    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setFormValue(initialFormValue);
      localStorage.setItem("socket_token", resp.socketToken);

      if (resp?.tfa === 1) {
        setbuttonLoader(false);
        localStorage.setItem("socket_token", resp.socketToken);
        localStorage.setItem("UserType", resp.type);


        navigate("/tfalogin", {
          state: {
            socketToken: resp?.socketToken,
          },
        });
      } else {
        if (resp.status) {
          setbuttonLoader(false);
          toast.success(resp.Message);
          console.log("resp.token====", resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          localStorage.setItem("UserType", resp.type);

          setAuthorization(resp.token);


          console.log(resp, "loginresp")

          if (resp.type == "User") {
            navigate("/userdashboard");
          } else {
            navigate("/dashboard");
          }
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card_login shadow">
                  <h3>Sign In</h3>
                  <p>
                    You’re one step away from accessing a global market and
                    taking your business to the next level Start by signing up!
                  </p>
                  <div>
                    <div class="form_login_input">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        maxLength="50"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                      {emailValidate == true ? (
                        <p className="text-danger"> {validationnErr.email} </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="form_login_input">
                      <label>Password</label>

                      <input
                        type={inputType}
                        class="form-control"
                        placeholder="Password"
                        name="password"
                        maxLength="25"

                        value={password}
                        onChange={handleChange}
                      />
                      <div className="input-group-addon ">
                        {passHide == false ? (
                          <i
                            className="bi bi-eye-slash-fill eyeicons"
                            onClick={() => passwordHide("hide")}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-eye-fill eyeicons"
                            onClick={() => passwordHide("show")}
                          ></i>
                        )}
                      </div>

                      {passwordValidate == true ? (
                        <p className="text-danger">
                          {" "}
                          {validationnErr.password}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div class="form_login_input ">
                    {buttonLoader == false ? (
                      <Button className="mt-5" onClick={formSubmit}>
                        Login
                      </Button>
                    ) : (
                      <Button className="mt-5">Loading...</Button>
                    )}
                  </div>
                  <Link to="/forget" className="pass_forgee mt-3 d-block">
                    Forget Password ?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
