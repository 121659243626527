const
    service = {
        searchcurrency: "users/searchcurrency",
        signup: 'users/register',
        emailotpverify: 'users/emailotpverify',
        resendCode: 'users/resendCode',
        signin: 'users/login',
        changepswdlink: 'users/changepswdlink',
        verifyForgotpasslink: 'users/verifyForgotpasslink',
        resetpassword: 'users/forgotpassword',
        profileUpdate: '/user/profileAdd',
        getuserProfile: 'users/getUserProfile',
        getUserDetails: 'users/getUserDetails',
        editProfile: "users/editProfile",
        get_Btc_Usdt_Balance: 'users/get_Btc_Usdt_Balance',
        getTransaction: 'users/getTransaction',
        getSessionHisotry: 'users/getSessionHisotry',
        getnotification: 'users/getnotification',
        getKYC: 'kyc/getkyc',
        savekyc_id: 'kyc/updatekyc',
        generateAddress: "address/getUserAddress",
        submitWithdraw: "withdraw/withdraw_submit",
        confirmWithdraw: "withdraw/withdraw_user_action",
        bankwire: "users/getbankwire",
        fiatdepositSubmit: "withdraw/fiatdeposit_submit",
        createdSupport: "support/create_ticket",
        findedSupport: "support/ticket_list",
        getP2Pcurrency: 'p2p/p2p_currencies',
        p2pOrder: 'p2p/postAddOrder',
        p2pGetOrder: 'p2p/getAllp2pOrder/',
        getp2pOrder: 'p2p/getp2pOrder/',
        p2pchat: 'p2p/chat/',
        getp2pchat: 'p2p/getp2pchat/',
        buyer_confirm: 'p2p/buyer_confirm_payment/',
        seller_confirm: 'p2p/seller_coin_confirm',
        buyer_cancel: 'p2p/buyer_cancel/',
        seller_cancel: 'p2p/seller_cancel',
        submitfiatWithdraw: "withdraw/fiatwithdraw_submit",
        p2pOrders: 'p2p/myOrders',
        getnotifyOrder: 'p2p/getnotifyOrder/',
        getCurrpairData: 'trade/getCurrpairData',
        getUserBalance: 'users/getUserBalance',
        getparUserBalance: 'trade/getparUserBalance',
        orderPlaceapi: 'orderPlaceApi/orderPlace',
        addBank: '/user/addBank',
        getBank: '/user/getBank',
        defaultBanks: 'users/defaultBankChoose',
        merchantdetail: 'users/merchantdetail',
        profiledetail: 'users/profiledetail',
        productotp: 'users/productotp',
        checkoutdetails: 'users/checkoutdetails',
        Getsidebardetails: 'users/Getsidebardetails',
        Getmerchantdetails: 'users/Getmerchantdetails',
        getapidata: 'users/getapidata',
        getwebhook: 'users/getwebhook',
        generateApiKey: 'users/generateApiKey',
        createwebhook: 'users/createwebhook',
        generateApiOTP: 'users/generateApiOTP',
        getAPIreponse: 'users/getAPIreponse',
        createSignature: 'users/createSignature',
        Transactionprocess: 'users/Transactionprocess',

        checkouthistory: 'users/checkouthistory',
        Invoicehistory: 'users/Invoicehistory',

        verifycustomer: 'users/verifycustomer',
        getaddress: 'users/getaddress',
        Depositsubmit: 'users/Depositsubmit',
        deletmerchant: 'users/deletmerchantDetails',
        widgetdetail: 'users/widgetdetail',
        Bankdetails: 'users/bankdetails',
        deletbank: 'users/deletbankDetails',
        Getbankdetails: 'users/Getbankdetails',
        savekyc_address: '/kyc/savekyc_Address',
        userLoginHistory: '/user/getLoginHistory',
        p2pCompareAPI: 'p2p/cryptoCompare',
        buy_sell_Express: 'p2p/buy_sell_Express_api',
        changePassword: "users/changePassword",
        getTfaDetials: "users/tfaDetials",
        changeTfaStatus: "users/changeTfaStatus",
        tfaVerify: "users/tfa_login",
        getBalance: "/user/getAllBalances",
        checkwallet: "/checkwallet",
        transactionHistory: '/withdraw/transactionHistory',
        resetPass: '/user/retetPassword',
        forgotepassword: '/user/forgotepassword',
        notifications: 'users/notifications',
        p2pMyorders: '/p2p/p2pMyorders',
        p2pExpress: '/p2p/p2pExpressOrders',
        getSupportData: '/adminapi/support_category_list',
        createSupport: '/user/create_ticket',
        get_Ticket: '/user/get_Ticket',
        updateTickets: '/user/updateTicket',
        viewChat: '/user/viewChat',
        closeTicket: '/user/ticketClosed',
        pairbycurrency: 'adminapi/tradepair/viewbycurrency',
        fetch_tickers: 'users/fetch_tickers',
        withdraw_history: 'withdraw/withdraw_history',
        deposit_history: 'withdraw/get_user_deposit',
        getActiveOrders: 'users/getActiveOrders',
        marketTrades: 'marketTrades/getMarketTrades',
        tradeHistory: 'users/tradeHistory',
        getCancelOrders: 'users/getCancelOrders',
        getCurrency: 'users/getcurrency',
        getSiteDatas: 'adminapi/get_sitedata',
        getContent: "adminapi/getContent",
        getStatkingDetails: 'staking/getStatkingDetails',
        statkingDetails_fixed: 'staking/statkingDetails_fixed',
        statkingDetails_flexible: 'staking/statkingDetails_flexible',
        statkingDetails_yield: 'staking/statkingDetails_yield',
        getUserBalanceAll: 'withdraw/getUserBalanceStaking',
        confirmStaking: 'staking/confirmStaking_user',
        getAllstakingHistory: 'staking/getAllstakingHistory',
        getStakingTotal: 'staking/getStakingTotal',
        claimNowapi: 'staking/claimNowapi',
        yieldcalculation: 'staking/yieldcalculation',
        claimNow_yield: 'staking/claimNowapi_yield',
        popular_staking: 'staking/popular_staking',
        submitForm: 'launchPad/submitForm',
        getAllLaunchpad: 'launchPad/getAllLaunchpad',
        getTokenDetails: 'launchPad/getTokenDetails',
        launchPadcurrency: 'launchPad/launchPadcurrency',
        getCurrencyConvertion: 'launchPad/getCurrencyConvertion',
        tokenPurchase: 'launchPad/tokenPurchase',
        lauchPadHistory: 'launchPad/lauchPadHistory',
        cancelOrder: 'orderCancelApi/cancelOrder',
        claimNowapiFlexible: 'staking/claimNowapiFlexible',
        portfolioBalance: 'users/portfolio_balance',
        transfer_balance: 'users/transfer_balance',
        getKYCDetails: 'kyc/getKYCDetails',
        withdrawhistoryBycurrency: 'withdraw/withdraw_history_bycurrency',
        deposithistoryBycurrency: 'withdraw/deposit_history_bycurrency',
        p2p_confirm_order: 'p2p/p2p_confirm_order',
        p2p_confirm_sellorder: 'p2p/p2p_confirm_sellorder',
        p2p_confirm_check: 'p2p/p2p_confirm_check',
        p2pHistory: 'p2p/p2p_history',
        getP2Pbalance: 'p2p/p2p_balance',
        allpairs: 'adminapi/allpairs',
        update_profile: 'users/update_profile',
        referralHistory: 'users/referralHistory',
        generateAddress_network: "address/getUserAddress_network",
        send_otp: "withdraw/send_withdraw_otp",
        user_balance: 'withdraw/user_balance',
        get_Staking_Details: "users/get_Staking_Details",
        currencyConversion: "users/currencyConversion",
        swapping: "users/swapping",
        swappingHistory: "users/swappingHistory",
        getUserBalanceSwap: 'users/getUserBalanceswap',
        depositcurrency: 'withdraw/get_deposit_currency',
        withdrawcurrency: 'withdraw/get_withdraw_currency',
        homeCurrency: 'users/homeCurrency',
        allmarkets: 'users/allmarkets',

        fetch_price: 'p2p/fetch_price',
        raise_dispute: 'p2p/dispute_order',
        confirmp2porder: 'p2p/p2p_check_confirm',
        viewp2pOrder: 'p2p/viewp2pOrder/',
        cancelConfirmBuy: 'p2p/cancel_confirm_order',
        cancelConfirmSell: 'p2p/cancel_confirmorder_sell',
        readNotify: 'p2p/read_notify',
        update_p2p: 'p2p/update_order',
        buyer_pay_cancel: 'p2p/buyer_pay_cancel',
        p2pProcessOrders: 'p2p/processOrders',
        subscriber: "users/subscriber",
        usercheck: "users/usercheck",




        //verifybussinessapi------------

        savebussinessdetails: "users/savebussinessdetails",
        getbussinessdetails: "users/getbussinessdetails",
        deleteBussiness: "users/deleteBussiness",
        updatebussinessdetails: "users/updatebussinessdetails",

        //support----------------
        createTicket: "users/createTicket",
        getTicket: "users/getTicket",
        deleteTicket: "users/deleteTicket",

        //Invoive--------------------
        getCurrency: "users/getCurrency",
        invoice_create: "users/invoice_create",
        emailvia: "users/emailvia",
        getInvoice: "users/getInvoice",
        getinvoicedetails: "users/getinvoicedetails",
        get_Invoice_Details_cutomer: "users/get_Invoice_Details_cutomer",
        deleteinvoice: "users/deleteinvoice",


        //userProfile---------------
        editUserprofile: "users/editUserprofile",
        Invoicesubmit: "users/Invoicesubmit",

        generateMerchatAddress: "address/getMerchatAddress",



        //UnionRamp ---------------------

        onRamp: 'users/onRamp',
        Supportedfiat: 'users/Supportedfiat',
        Supportedcrypto: 'users/Supportedcrypto',
        marketprices: 'users/marketprices',
        buycrypto: 'users/buycrypto',

        //Pay Onramp ---------------------

        payoncurrency: 'users/payoncurrency',
        exchangeRate: 'users/exchangeRate',
        Phonenumber: 'users/Phonenumber',
        OTPverify: 'users/OTPverify',
        UserKycstatus: 'users/UserKycstatus',
        payonrampkyc: 'users/payonrampkyc',
        getpayonrampkyc: 'users/getpayonrampkyc',
        getQrcode: 'users/getQrcode',
        Getbankdetails: 'users/Getbankdetails',
        submitpayment: 'users/submitpayment',

    }

export default service;