import React, {useEffect, useRef} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./Footer";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {IconCircleArrowLeftFilled} from "@tabler/icons-react";
import Moment from "moment";
import Pagination from "react-js-pagination";
import {Link, useNavigate} from "react-router-dom";
import Sidebar from "./Sidebarlink";
import {IconCopy} from "@tabler/icons-react";
import {env} from ".././core/service/envconfig";

function Home() {
  const textRef2 = useRef(null);
  const textRef1 = useRef(null);
  const navigate = useNavigate();

  const [Checkoutdetails, SetCheckoutdetails] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    var link = window.location.href.split("/")[4];
    console.log(link);
    checkoutdetails(link);
  }, [0]);

  const handleCopy1 = () => {
    const textToCopy = textRef1.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Code Copied");
      })
      .catch((error) => {
        toast.error("Failed to copy code");
      });
  };

  const handleCopy2 = () => {
    const textToCopy = textRef2.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Link Copied");
      })
      .catch((error) => {
        toast.error("Failed to copy Link");
      });
  };

  const checkoutdetails = async (link) => {
    try {
      setLoader(true);

      var obj = {
        link: link,
      };
      var data = {
        apiUrl: apiService.checkoutdetails,
        payload: obj,
      };
      var resp = await postMethod(data);
      console.log("getmerchantdetails===", resp);

      if (resp) {
        setLoader(false);
        console.log(resp.obj, "=-=-respobj-=-=");
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
        SetCheckoutdetails(resp.obj);
      } else {
        console.log(resp, "-=-=-resp=-=-=-resp==-resp");
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const deletemerchant = async (deleteData) => {
    alert("Are you sure you want to permanently delete this bank details ?");
    console.log(deleteData, "=-=-=-=deleteData");
    try {
      var data = {
        apiUrl: apiService.deletmerchant,
        payload: deleteData,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        checkoutdetails();
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  const rawHTML = `<div class="buy-with-beleaf"> 
    <a href="${env.BaseURL}/userpayment/${Checkoutdetails._id}"> 
    Buy with Crypto </a> 
    </div>`;

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 d-flex">
                          <div className="headin_deposur_s ">
                            <h3>
                              <i
                                class="ri-arrow-left-line cusonr"
                                onClick={() => navigate("/checkout")}
                              ></i>
                              <span className="pl-3">Checkout details</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container">
                      <div className="row  p-3 justify-content-center payment_burron">
                        <div className="col-lg-12 card_login">
                          <h3 className="heading_class_recent p-3">
                            Item details
                          </h3>
                          <div className="table-responsive">
                            <table class="table checkout_table">
                              <tbody>
                                <tr>
                                  <td>
                                    <p className="h6">Name</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>{Checkoutdetails.Name}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h6">Price</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>{Checkoutdetails.Fixed} USD</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h6">Description</p>
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    <p>{Checkoutdetails.Description}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="h6">Share a link</p>
                                  </td>
                                  <td className="text-right">
                                    <p
                                      ref={textRef1}
                                      className="text_elips word-break"
                                    >
                                      <span>
                                        {" "}
                                        {`${env.BaseURL}/userpayment/${Checkoutdetails._id}`}{" "}
                                      </span>
                                      <IconCopy onClick={handleCopy1} />
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-lg-12 card_login my-5 shadow">
                          <h3 className="heading_class_recent">
                            Embed checkout
                          </h3>
                          <div className="bg-dark">
                            <p
                              className="p py-5 rawtext px-4 text-light"
                              ref={textRef2}
                            >
                              {rawHTML}
                            </p>
                          </div>
                          <div class="form_login_input pading_foem1">
                            <Button className="mt-3" onClick={handleCopy2}>
                              Copy Code
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
