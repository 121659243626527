import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import {
  IconLayoutDashboard,
  IconIdBadge2,
  IconUserCheck,
  IconFileAnalytics,
  IconWebhook,
  IconHelp,
  IconQrcode,
  IconFingerprint,
  IconCash,
} from "@tabler/icons-react";
import { IconApiApp } from "@tabler/icons-react";
function Home() {
  const [Userdetails, SetUserdetails] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUserdetails();
  }, [0]);

  const getUserdetails = async () => {
    try {
      setLoader(true);
      var data = {
        apiUrl: apiService.Getsidebardetails,
      };
      var resp = await getMethod(data);
      console.log("getUserdetails===", resp);

      if (resp) {
        setLoader(false);
        SetUserdetails(resp.obj);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="sidebar_link ">
        <div className="menuee">
          <NavLink to="/dashboard" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconLayoutDashboard className="icon_menu" />
              <div className="aherd_ineer">Dashboard</div>
            </div>
          </NavLink>
          <NavLink to="/Verifybusiness" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconIdBadge2 className="icon_menu" />
              <div className="aherd_ineer">Business Verification</div>
            </div>
          </NavLink>
          <NavLink to="/merchantkyc" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconUserCheck className="icon_menu" />
              <div className="aherd_ineer">KYC</div>
            </div>
          </NavLink>
          <NavLink to="/Currencyqr" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconQrcode className="icon_menu" />
              <div className="aherd_ineer">Generate QR</div>
            </div>
          </NavLink>
          <NavLink to="/merchatAPI" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconApiApp className="icon_menu" />
              <div className="aherd_ineer">Merchant API</div>
            </div>
          </NavLink>
          <NavLink to="/merchat_webhook" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconWebhook className="icon_menu" />
              <div className="aherd_ineer">WebHook</div>
            </div>
          </NavLink>
          <NavLink to="/withdraw" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconCash className="icon_menu" />
              <div className="aherd_ineer">Withdraw</div>
            </div>
          </NavLink>

          <NavLink to="/security_tfa" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconFingerprint className="icon_menu" />
              <div className="aherd_ineer">Security</div>
            </div>
          </NavLink>
          <NavLink to="/support" className="css-z87e9z">
            <div className="css-10j588g ">
              {" "}
              <IconHelp className="icon_menu" />
              <div className="aherd_ineer">Support</div>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Home;
