import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import {
  IconLayoutDashboard,
  IconIdBadge2,
  IconUserCheck,
  IconBuildingStore,
  IconBrandMastercard,
  IconCode,
  IconBell,
  IconHelp,
  IconQrcode,
  IconUserCircle,
  IconSettingsCode,
  IconFileInvoice,
  IconTags,
  IconWallet,IconBuildingBank,IconCash,
} from "@tabler/icons-react";
function Home() {



  
  const navigate = useNavigate();
  const [Userdetails, SetUserdetails] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUserdetails();
}, [0]);


  const getUserdetails = async () => {
    try {
        setLoader(true);
        var data = {
            apiUrl: apiService.Getsidebardetails,
        };
        var resp = await getMethod(data);
        console.log("getUserdetails===", resp);

        if (resp) {
            setLoader(false);
            SetUserdetails(resp.obj);
        } else {
        }
    } catch (error) {
    }
};


const type = Userdetails.type;


  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (

    <>
     <div className="sidebar_link ">
      <div className="menuee">
        <NavLink to="/userdashboard" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconLayoutDashboard className="icon_menu" />
            <div className="aherd_ineer">Dashboard</div>
          </div>
        </NavLink>
        <NavLink to="/kycbusiness" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconUserCheck className="icon_menu" />
            <div className="aherd_ineer">KYC</div>
          </div>
        </NavLink>
        {/* <NavLink to="/usernotification" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconBell className="icon_menu" />
            <div className="aherd_ineer">Notification</div>
          </div>
        </NavLink> */}
        <NavLink to="/deposit" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconBuildingBank className="icon_menu" />
            <div className="aherd_ineer">Deposit</div>
          </div>
        </NavLink>
        <NavLink to="/userwithdraw" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconCash className="icon_menu" />
            <div className="aherd_ineer">Withdraw</div>
          </div>
        </NavLink>
        <NavLink to="/usersupport" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconHelp className="icon_menu" />
            <div className="aherd_ineer">Support</div>
          </div>
        </NavLink>
       
        {/* <NavLink to="/userprofile" className="css-z87e9z">
          <div className="css-10j588g ">
            {" "}
            <IconUserCircle className="icon_menu" />
            <div className="aherd_ineer">User Profile</div>
          </div>
        </NavLink> */}
      </div>
    </div>
    </>
  );
}

export default Home;
