import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import { Link } from "react-router-dom";
import Select from "react-select";
import Moment from "moment";
import Sidebar from "./userslidbar";

function Home() {

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState("");
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus] = useState(1);
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [maintain, setmaintain, maintainref] = useState(false);
  const [loader, setloader] = useState(false);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 5;
  const pageRange = 5;

  useEffect(() => {
    getAllcurrency();
    getdepositHistory(1);
  }, [0]);

  const getAllcurrency = async () => {
    setloader(true);
    var data = {
      apiUrl: apiService.depositcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      setloader(false);
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
          coinType: data[i].coinType,
          erc20token: data[i].erc20token,
          bep20token: data[i].bep20token,
          trc20token: data[i].trc20token,
          rptc20token: data[i].rptc20token,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Transaction ID copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  const Addcopy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Address copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  const Depcopy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Deposit address copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  const onSelect = async (e, option) => {
    console.log(option, "-=-onSelecttop");

    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);
      setcur_network([]);
      setnet_default("");
      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            // label: "ERC20",
            text: "ERC20",


          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            // label: "BEP20",
            text: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            // label: "TRC20",
            text: "TRC20",
          };
          network_names.push(network_cur);
        }

        if (currencydata.rptc20token == "1") {
          network_cur = {
            value: "rptc20token",
            // label: "RPTC20",
            text: "RPTC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].text);
        setcurrency(currencydata);
        setcointype(currencydata.coinType);
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currency: currencydata.currencySymbol,
          currId: currencydata._id,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        //console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview("view");
          console.log("viewref====", viewref.current);
          setAddress(resp.Message);
        } else {
        }
      } else {
        // var obj = {
        //   currency: currencydata.currencySymbol,
        // };
        // var data = {
        //   apiUrl: apiService.bankwire,
        //   payload: obj,
        // };
        // var resp = await postMethod(data);
        // console.log(resp, "=-=-=fiat deposit resp-=-=--");
        // if (resp.status) {
        //   setBankwire(resp.data);
        // } else {
        //   //toast.error("Something went wrong, please try again later");
        // }
      }
    }
  };

  const onSelect_network = async (e, option) => {
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
      } else {
      }
    }
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      console.log(deposit_history_list, "deposit_history_list");
      setdepositHistory(deposit_history_list.result);
      setTotalpages(deposit_history_list.total);
    }
  };

  const handlePageChange = (pageNumber) => {
    getdepositHistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="headin_deposur_s text-white">
                            <h3>Deposit</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form-deposit">
                    <div className="container">
                      <div className="row justify-content-center m-5">
                        <div className="col-lg-6 card_login mb-5">
                          <div className="crypto_coin_selec form_login_input">
                            <label>Select Coin</label>
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={allCurrencyref.current}
                              onChange={onSelect}
                            />
                          </div>{" "}
                          {cur_currencyref.current.currencyType == "2" ? (
                            <div className="crypto_coin_selec form_login_input">
                              <label>Network</label>
                              <Dropdown
                                placeholder="Select an Network"
                                fluid
                                selection
                                onChange={onSelect_network}
                                options={network_currencyref.current}
                              />
                            </div>

                          ) : (
                            ""
                          )}
                          {viewref.current == "view" ? (
                            <>

                              <div className="form_login_input">
                                <label>Deposit Address</label>
                                <p className="d-flex justify-content-between form-control">
                                  {/* Render truncated address with ellipsis if addressref.current.address exists */}
                                  {addressref.current.address ? (
                                    <span>
                                      {addressref.current.address.substring(
                                        0,
                                        20
                                      ) + "..."}
                                    </span>
                                  ) : (
                                    <span></span> // Render an empty span if addressref.current.address is falsy
                                  )}

                                  <i
                                    class="ri-file-copy-line"
                                    onClick={() =>
                                      Depcopy(addressref.current.address)
                                    }
                                  ></i>
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {viewref.current == "view" ? (
                            <div className="minimu_depost p-0 mb-0">
                              {addressref.current != "" ? (
                                <p>
                                  Available Balance{" "}
                                  <span>
                                    {" "}
                                    {addressref.current.balance}{" "}
                                    {cur_currencyref.current.currencySymbol}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container">
                        <h4 className="heading_class_recent p-3">
                          Recent Deposits
                        </h4>
                        <div className="row dashcard1 p-3 justify-content-center mr17 bg_none">
                          <div className="col-lg-12 shadow">
                            <div className="market_tabel1 w-100 mt-0 p-3">
                              <div className="market_section">
                                <div className="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th className="leftborder">Name</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Address</th>
                                        <th className="rightborder">
                                          TxID
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {depositHistory &&
                                        depositHistory.length > 0 ? (
                                        depositHistory.map((item, i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className="coin_section">
                                                  <img
                                                    src={item.image}
                                                    className="blackshow"
                                                  />

                                                  <span>
                                                    {item.currencySymbol}
                                                  </span>

                                                  {/* <span>
                                                    {item.currencyName}
                                                  </span>{" "}
                                                  <small>
                                                    {item.currencySymbol}
                                                  </small> */}
                                                </div>
                                              </td>
                                              <td>
                                                <p>
                                                  {Moment(item.date).format(
                                                    "lll"
                                                  )}
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {parseFloat(
                                                    item.amount
                                                  ).toFixed(4)}
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {item.address ? (
                                                    <>
                                                      {item.address.substring(
                                                        0,
                                                        15
                                                      ) + "..."}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <i
                                                    class="ri-file-copy-line"
                                                    onClick={() =>
                                                      Addcopy(item.address)
                                                    }
                                                  ></i>
                                                </p>
                                              </td>
                                              <td>
                                                <p className="text-right">
                                                  {item.txnid ? (
                                                    <>
                                                      {item.txnid.substring(
                                                        0,
                                                        15
                                                      ) + "..."}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <i
                                                    class="ri-file-copy-line"
                                                    onClick={() =>
                                                      copy(item.txnid)
                                                    }
                                                  ></i>{" "}

                                                </p>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan="5"
                                          >
                                            <img
                                              src={require("../Image/nodata.png")}
                                              className="mt-5"
                                            />
                                            <p className="mb-5 mt-3 nodatafound">
                                              No data Found
                                            </p>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                 
                                </div>
                                {depositHistory.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentPage}
                                      itemsCountPerPage={recordPerPage}
                                      totalItemsCount={totalPage}
                                      pageRangeDisplayed={pageRange}
                                      onChange={handlePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
