export const env = {
  // apiHost: "http://localhost:3033/",
  // BaseURL: "http://localhost:3000", 
  // BaseURL: "https://gatecryptopay.store", // test server
  // apiHost: "https://gatecryptopay.store:3033/", // test server

  BaseURL: "https://payment.web5.nexus", // test server
  apiHost: "https://payment.web5.nexus:3033/", // test server
  // cloud_name: "daigxuvxp",
  // preset: "y7bdmq1g",
  cloud_name: "dvdvhlndj",
  preset: "xurbcxih",
};
