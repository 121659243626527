import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { removeAuthToken } from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

const pages = ["Products", "Services", "ABoutUs", "ContactUs"];
const DrawerComp = () => {
  const [modal1, setModal1] = useState(false);
  const goto1 = () => setModal1(!modal1);

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
    setOpenDrawer(false);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 240;
  const zIndex1 = 2000000;
  const [loginCheck, setloginCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    getUserdetails();
  }, [0]);

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  };

  const [Userdetails, SetUserdetails] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const getUserdetails = async () => {
    try {
      setLoader(true);
      var data = {
        apiUrl: apiService.Getsidebardetails,
      };
      var resp = await getMethod(data);
      console.log("getUserdetails===", resp);

      if (resp) {
        setLoader(false);
        SetUserdetails(resp.obj);
      } else {
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        className="Drawers"
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            zIndex: zIndex1,
          },
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {loginCheck == true && Userdetails.type == "Merchant" ? (
            <>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link className="nav_brand " to="/">
                      {" "}
                      <img
                        src={require("../Image/bitcoin-p2p/Logolight.png")}
                        className="blackshow"
                      />
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/dashboard" className="link_tage">
                      <i class="ri-layout-grid-fill"></i>
                      Dashboard
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Verifybusiness" className="link_tage">
                      <i class="ri-profile-line"></i>
                      Business Verification
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/merchantkyc" className="link_tage">
                      <i class="ri-user-follow-fill"></i>
                      KYC
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              {/* <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/deposit" className="link_tage">
                <i class="ri-luggage-deposit-line"></i>
                  Deposit
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton> */}
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/withdraw" className="link_tage">
                      <i class="ri-bank-line"></i>
                      Withdraw
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/checkout" className="link_tage">
                      <i class="ri-price-tag-3-line"></i>
                      Checkout
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/invoice" className="link_tage">
                      <i class="ri-article-line"></i>
                      Invoice
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Currencyqr" className="link_tage">
                      <i class="ri-qr-code-line"></i>
                      Generate QR
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/merchatAPI" className="link_tage">
                      <i class="ri-swap-box-line"></i>
                      Merchant API
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/merchat_webhook" className="link_tage">
                      <i class="ri-pages-line"></i>
                      WebHook
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/notification" className="link_tage">
                      <i class="ri-message-2-line"></i>
                      Notification
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/security_tfa" className="link_tage">
                      <i class="ri-lock-unlock-line"></i>
                      Security
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/support" className="link_tage">
                      <i class="ri-questionnaire-line"></i>
                      Support
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/profile" className="link_tage">
                      <i class="ri-file-user-fill"></i>
                      Merchant Profile
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/checkouthistory" className="link_tage">
                      <i class="ri-file-history-line"></i>
                      Checkout History
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/invoicehistory" className="link_tage">
                      <i class="ri-folder-history-fill"></i>
                      Invoice History
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              {/* <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link to="/" className="link_tage">
                  <i class="ri-exchange-funds-fill"></i>
                  Features
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton> */}
            </>
          ) : (
            ""
          )}

          {loginCheck == true && Userdetails.type !== "Merchant" ? (
            <>
              <ListItemButton className="payment_gateway_nav_img">
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link className="nav_brand " to="/">
                      {" "}
                      <img
                        src={require("../Image/bitcoin-p2p/Logolight.png")}
                        className="blackshow"
                      />
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/userdashboard" className="link_tage">
                      <i class="ri-layout-grid-fill"></i>
                      Dashboard
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/kycbusiness" className="link_tage">
                      <i class="ri-user-follow-fill"></i>
                      KYC
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/uniramp" className="link_tage">
                      <i class="ri-token-swap-line"></i>
                      Buy Crypto
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/deposit" className="link_tage">
                      <i class="ri-luggage-deposit-line"></i>
                      Deposit
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/userwithdraw" className="link_tage">
                      <i class="ri-bank-line"></i>
                      Withdraw
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/usernotification" className="link_tage">
                      <i class="ri-message-2-line"></i>
                      Notification
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/usersupport" className="link_tage">
                      <i class="ri-questionnaire-line"></i>
                      Support
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>

              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/userprofile" className="link_tage">
                      <i class="ri-file-user-fill"></i>
                      User Profile
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </>
          ) : (
            ""
          )}

          {loginCheck == false ? (
            <>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link className="nav_brand " to="/">
                      {" "}
                      <img
                        src={require("../Image/bitcoin-p2p/Logolight.png")}
                        className="blackshow"
                      />
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/login" className="link_tage">
                      <i class="ri-login-box-fill"></i>
                      Sign In
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Accounttype" className="link_tage">
                      <i class="ri-user-shared-fill"></i>
                      Sign Up
                    </Link>
                  </ListItemText>
                </ListItemIcon>{" "}
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/register" className="link_tage">
                      <i class="ri-service-line"></i>
                      Business
                    </Link>
                  </ListItemText>
                </ListItemIcon>{" "}
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/userregister" className="link_tage">
                      <i class="ri-user-shared-2-line"></i>
                      Personal
                    </Link>
                  </ListItemText>
                </ListItemIcon>{" "}
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="#" className="link_tage">
                      <i class="ri-database-line"></i>
                      Product
                    </Link>
                  </ListItemText>
                </ListItemIcon>{" "}
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <ListItemText>
                    <Link to="#" className="link_tage">
                      <i class="ri-code-s-slash-line"></i>
                      Solution
                    </Link>
                  </ListItemText>
                </ListItemIcon>{" "}
              </ListItemButton>
            </>
          ) : (
            ""
          )}

          {loginCheck == true ? (
            <ListItemButton>
              {" "}
              <ListItemIcon>
                <ListItemText>
                  <Link to="" className="link_tage" onClick={toggle1}>
                    <i class="ri-user-shared-fill"></i>
                    Logout
                  </Link>
                </ListItemText>
              </ListItemIcon>{" "}
            </ListItemButton>
          ) : (
            ""
          )}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="black" />
      </IconButton>

      <Modal isOpen={modal1} toggle={toggle1} className="rem10 checkout_modal">
        <ModalBody className="p-0">
          <div class="Frame-14722">
            <span class="Item-Details h2">
              <i class="ri-logout-box-line mr-2"></i> Logout{" "}
            </span>
            <div class="">
              <i class="ri-close-fill  Close" onClick={goto1}></i>
            </div>
          </div>

          <div className="py-2">
            <p className="Deletecontent my-4">
              Are you sure want to logout this session ?
            </p>

            <div className="row card_login flex-row pb-0 pt-2">
              <div className="col-md-6 mt-3">
                <div className="form_login_input">
                  <Button className="discardbtn" onClick={goto1}>
                    Discard
                  </Button>
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="form_login_input">
                  <Button onClick={logout}>Logout</Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DrawerComp;
