import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import Select from "react-select";
import Sidebar from "./Sidebarlink";
import { Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { IconTrash, IconEye } from "@tabler/icons-react";
import { IconLoaderQuarter } from "@tabler/icons-react";
import { env } from ".././core/service/envconfig";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { IconCopy } from "@tabler/icons-react";

const ShortUniqueId = require("short-unique-id");

function Invoice() {
  const navigate = useNavigate();

  const options = [
    { key: "ERC-20", value: "ERC-20", text: "ERC-20" },
    { key: "BEP-20", value: "BEP-20", text: "BEP-20" },
  ];

  const initialFormValue = {
    email: "",
    customerName: "",
    amount: "",
    memo: "",
    mobile: "",
    address: "",
    itemname: "",
    qty: "",
    due: "",
  };

  const [loader, setLoader] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationnErr, setvalidationnErr] = useState("");
  const [emailErr, setEmailErr, setEmailErrref] = useState(false);
  const [customerNameErr, setcustomerNameErr, setcustomerNameErrref] =
    useState(false);
  const [mobileErr, setmobileErr, setmobileErrref] = useState(false);
  const [addressErr, setaddressErr, setaddressErrref] = useState(false);
  const [itemnameErr, setitemnameErr, setitemnameErrref] = useState(false);
  const [qtyErr, setqtyErr, setqtyErrref] = useState(false);
  const [dueErr, setdueErr, setdueErrref] = useState(false);
  const [paymentErr, setpaymentErr, setpaymentErrref] = useState(false);
  const [amountErr, setamountErr, setamountErrref] = useState(false);
  const [memoErr, setmemoErr, setmemoErrref] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setCurrency] = useState("");
  const [paymentmethod, setpaymentmethod, paymentmethodref] = useState("");
  const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
  const [link, setLink] = useState("");
  const [invoiceList, setinvoiceList] = useState([]);
  const [currentpage, Setcurrentpage] = useState(1);
  const [totalpage, Settotalpage] = useState(0);
  const [symbol, Setsymbol, symbolref] = useState("");
  const [modal, setModal] = useState(false);
  const [Invoicedata, SetInvoicedata, Invoicedataref] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [deleteitem, Setdeleteitem, deleteitemref] = useState("");

  const goto = () => setModal(!modal);
  const goto1 = () => setModal1(!modal1);

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
    Setdeleteitem(e);
  };

  const {
    email,
    customerName,
    amount,
    memo,
    mobile,
    address,
    itemname,
    qty,
    due,
  } = formValue;

  useEffect(() => {
    getAllcurrency();
    getInvoice();
  }, []);

  const toggle = async (e) => {
    console.log("e====", e);
    setModal(!modal);
    SetInvoicedata(e);
  };

  const textRef1 = useRef(null);

  const handleCopy1 = () => {
    const textToCopy = Invoicedataref.current.link;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showsuccessToast("Code Copied");
      })
      .catch((error) => {
        showerrorToast("Failed to copy code");
      });
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };


  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log("formData====", formData);
    setFormValue(formData);
  };
  const vallidate = () => {
    try {
      const errors = {};
      if (email == "") {
        errors.email = "Email is required !";
        setEmailErr(true);
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        errors.email = "Invalid Email ";
        setEmailErr(true);
      } else {
        setEmailErr(false);
      }
      if (customerName == "") {
        errors.customerName = "Customer Name is required !";
        setcustomerNameErr(true);
      } else if (customerName.length < 3 || customerName.length > 15) {
        errors.customerName =
          "Name Number should not below 3 above 15 letters !";
        setcustomerNameErr(true);
      } else {
        setcustomerNameErr(false);
      }
      if (mobile == "") {
        errors.mobile = "Mobile is required !";
        setmobileErr(true);
      } else if (mobile.length < 10 || mobile.length > 15) {
        errors.mobile = "Mobile Number should not below 10 above 15 letters !";
        setmobileErr(true);
      } else {
        setmobileErr(false);
      }
      if (address == "") {
        errors.address = "Address is required !";
        setaddressErr(true);
      } else if (address.length < 5 || address.length > 50) {
        errors.address = "Address Number should not below 5 above 50 letters !";
        setaddressErr(true);
      } else {
        setaddressErr(false);
      }
      if (due == "") {
        errors.due = "Due Date is required !";
        setdueErr(true);
      } else {
        setdueErr(false);
      }
      if (paymentmethod == "") {
        errors.payment = "Payment Method is required !";
        setpaymentErr(true);
      } else {
        setpaymentErr(false);
      }
      if (itemname == "") {
        errors.itemname = "Item name is required !";
        setitemnameErr(true);
      } else if (itemname.length < 3 || itemname.length > 15) {
        errors.itemname =
          "Item name Number should not below 3 above 15 letters !";
        setitemnameErr(true);
      } else {
        setitemnameErr(false);
      }
      if (qty == "") {
        errors.qty = "Quantity Date is required !";
        setqtyErr(true);
      } else {
        setqtyErr(false);
      }
      if (amount == "") {
        errors.amount = "Amount is required !";
        setamountErr(true);
      } else {
        setamountErr(false);
      }
      if (memo == "") {
        errors.memo = "Memo is required !";
        setmemoErr(true);
      } else if (memo.length < 5 || memo.length > 150) {
        errors.memo = "Memo Number should not below 5 above 150 letters !";
        setmemoErr(true);
      } else {
        setmemoErr(false);
      }
      if (currency == "") {
        errors.currency = "Currency is required !";
        setCurrencyErr(true);
      } else {
        setCurrencyErr(false);
      }
      setvalidationnErr(errors);
    } catch (error) {}
  };

  const onSelect = async (e, option) => {
    setCurrency(option.value);
    for (let i = 0; i < option.options.length; i++) {
      if (option.options[i].value == option.value) {
        Setsymbol(option.options[i].key);
      }
    }
  };

  const onpaymentSelect = async (e, option) => {
    console.log(option.value);
    setpaymentmethod(option.value);
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success(" Link copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.getCurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          //label: data[i].currencySymbol,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
          coinType: data[i].coinType,
          erc20token: data[i].erc20token,
          bep20token: data[i].bep20token,
          trc20token: data[i].trc20token,
          rptc20token: data[i].rptc20token,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const recordpage = 5;
  const pagerange = 5;
  const historychanges = (pagenumber) => {
    getInvoice(pagenumber);
    Setcurrentpage(pagenumber);
  };
  const getInvoice = async (page) => {
    var data = {
      apiUrl: apiService.getInvoice,
      payload: {
        FilPerpage: 5,
        FilPage: page,
      },
    };

    var resp = await postMethod(data);
    if (resp.status == true) {
      console.log(resp.data.data, "INvoice data");
      setinvoiceList(resp.data.data);
      Settotalpage(resp.data.total);
    } else {
      toast.error(resp.message);
    }
  };

  const submit = async () => {
    vallidate(formValue);
    var code = await new ShortUniqueId({ length: 8 });
    var uid = code();
    console.log(uid);
    var Link = `${env.BaseURL}/invoicecharge/${uid}`;
    setLink(Link);
    formValue["currency"] = currency;
    formValue["link"] = Link;
    formValue["invoiceid"] = uid;
    formValue["payment"] = paymentmethodref.current;
    formValue["Totalamount"] = formValue.qty * formValue.amount;

    console.log(formValue, "formValue");
    if (
      setEmailErrref.current == false &&
      setcustomerNameErrref.current == false &&
      setamountErrref.current == false &&
      setmemoErrref.current == false &&
      setCurrencyErrref.current == false &&
      setmobileErrref.current == false &&
      setaddressErrref.current == false &&
      setdueErrref.current == false &&
      setpaymentErrref.current == false &&
      setitemnameErrref.current == false &&
      setqtyErrref.current == false
    ) {
      console.log(formValue, "formValue");
      var data = {
        apiUrl: apiService.invoice_create,
        payload: formValue,
      };
      var resp = await postMethod(data);
      console.log(resp, "resp-==-=resp");
      setFormValue(initialFormValue);
      if (resp.status == true) {
        toast.success(resp.message);
        getInvoice();
      } else {
        toast.error(resp.message);
      }
    } else {
      console.log("require All fields");
    }
  };

  const deleteinvoice = async (deleteData) => {
    console.log(deleteData, "deleteData");
    try {
      var data = {
        id: deleteData,
      };
      var data = {
        apiUrl: apiService.deleteinvoice,
        payload: data,
      };
      var resp = await postMethod(data);
      setModal1(!modal1);

      if (resp.status) {
        getInvoice();
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div class="col-lg-12 d-flex justify-content-between align-items-center px-4">
                          <div className="headin_deposur_s">
                            <h3>Invoice</h3>
                          </div>
                          <div class="form_login_input">
                            <Button
                              className="button_colore"
                              onClick={() => navigate("/create-invoices")}
                            >
                              Create
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="tavle_transactio">
                    <div className="padding_section">
                      <div className="container-fluid">
                        <div className="row justify-content-center">
                          <div className="col-lg-12 shadow">
                            <div className="market_tabel1 w-100 mt-0 p-3">
                              <div className="market_section dashcard1 p-3 bg_none">
                                <div className="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th className="leftborder">
                                          Create Date
                                        </th>
                                        <th>Due Date </th>
                                        <th>Invoice Code</th>
                                        <th>Customer</th>
                                        <th>Total Amount</th>
                                        <th>Status</th>
                                        <th className="rightborder">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {invoiceList && invoiceList.length > 0 ? (
                                        invoiceList.map((item, i) => {
                                          return (
                                            <tr className="curspoint">
                                              <td>
                                                <p>{item.date}</p>
                                              </td>
                                              <td>
                                                <p>{item.due}</p>
                                              </td>
                                              <td>
                                                <p>{item.invoiceid}</p>
                                              </td>
                                              <td>
                                                <p>{item.cutomerName}</p>
                                              </td>
                                              <td>
                                                <p>
                                                  {item.amount} {item.currency}
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {item.status == 0 ? (
                                                    <div className="text-danger">
                                                      Pending
                                                    </div>
                                                  ) : (
                                                    <div className="text-success">
                                                      Completed
                                                    </div>
                                                  )}
                                                </p>
                                              </td>
                                              {/* <td className="td_amount">
                                                {buttonLoader == false ? (
                                                  <IconEye
                                                    className="td_cursor text-primary"
                                                    onClick={() =>
                                                      navigate(
                                                        `/viewInvoice/${item.id}`
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <IconLoaderQuarter className="icon_danger" />
                                                )}
                                              </td> */}
                                              <td className="td_amount">
                                                {buttonLoader == false ? (
                                                  <>
                                                    <Link
                                                      // to={`/viewInvoice/${item.id}`}
                                                      onClick={(e) =>
                                                        toggle(item)
                                                      }
                                                      className="curspoint viewicon"
                                                    >
                                                      View
                                                    </Link>

                                                    <IconTrash
                                                      className="icon_danger td_cursor"
                                                      onClick={(e) =>
                                                        toggle1(item)
                                                      }
                                                      // onClick={(e) =>
                                                      //   deleteinvoice(item.id)
                                                      // }
                                                    />
                                                  </>
                                                ) : (
                                                  <IconLoaderQuarter className="text-danger" />
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan="7"
                                          >
                                            <img
                                              src={require("../Image/nodata.png")}
                                              className="mt-5"
                                            />
                                            <p className="mb-5 mt-3 nodatafound">
                                              No data Found
                                            </p>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                {invoiceList && invoiceList.length > 0 ? ( 
                                  <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={currentpage}
                                  itemsCountPerPage={recordpage}
                                  totalItemsCount={totalpage}
                                  pageRangeDisplayed={pagerange}
                                  onChange={historychanges}
                                />
                                ) : "" }
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </main>
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="rem10 checkout_modal"
            >
              <ModalBody className="p-0">
                <div class="Frame-14722">
                  <span class="Item-Details">Item Details</span>
                  <div class="">
                    <i class="ri-close-fill  Close" onClick={goto}></i>
                  </div>
                </div>

                <div class="">
                  <div class="Frame-14699">
                    <span class="Leftsides">Invoice Id</span>
                    <span class="rightsides">
                      {Invoicedataref.current.invoiceid}
                    </span>
                  </div>

                  <div class="Frame-14699">
                    <span class="Leftsides">Total Amount</span>
                    <span class="rightsides">
                      {Invoicedataref.current.totalamount}{" "}
                      {Invoicedataref.current.currency}
                    </span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Due Date</span>
                    <span class="rightsides">{Invoicedataref.current.due}</span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Created Date</span>
                    <span class="rightsides">
                      {Invoicedataref.current.date}
                    </span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Status</span>
                    <span class="rightsides">
                      {Invoicedataref.current.status == 1
                        ? "Completed"
                        : "Pending"}
                    </span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Memo</span>
                    <span class="rightsides">
                      {Invoicedataref.current.memo
                        ? Invoicedataref.current.memo
                        : "-"}
                    </span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Email</span>
                    <span class="rightsides">
                      {Invoicedataref.current.email}
                    </span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Name</span>
                    <span class="rightsides">
                      {Invoicedataref.current.cutomerName}
                    </span>
                  </div>
                  <div class="Frame-14699">
                    <span class="Leftsides">Share a link</span>
                    <span class="rightsides" ref={textRef1}>
                      {Invoicedataref.current === ""
                        ? "Connect Wallet"
                        : `${Invoicedataref.current?.link?.substring(
                            0,
                            30
                          )} ...`}
                      <IconCopy className="copyicon" onClick={handleCopy1} />
                    </span>
                  </div>
                </div>

                <div></div>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={modal1}
              toggle={toggle1}
              className="rem10 checkout_modal"
            >
              <ModalBody className="p-0">
                <div class="Frame-14722">
                  <span class="Item-Details">
                    <i class="ri-alert-line text-danger"></i> Delete Invoice
                  </span>
                  <div class="">
                    <i class="ri-close-fill  Close" onClick={goto1}></i>
                  </div>
                </div>

                <div className="py-2">
                  <p className="Deletecontent">
                    Are you sure you want to delete this invoice detail ?
                  </p>

                  <div className="row card_login flex-row pb-0 pt-2">
                    <div className="col-md-6">
                      <div className="form_login_input">
                        <Button className="discardbtn" onClick={goto1}>
                          Discard
                        </Button>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form_login_input">
                        <Button
                          onClick={(e) =>
                            deleteinvoice(deleteitemref.current.id)
                          }
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Invoice;
