import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
// import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import Features_slider from "./Features_slider";
import Payment_slider from "./Payment_slider";

function Home() {
  const navigate = useNavigate();
  const [loginCheck, setloginCheck] = useState(false);
  const [CurrencyDetails, setCurrencyDetails] = useState([]);
  const [currencylistData, setcurrencylistData] = useState([]);
  const [metatitle, setmetatitle, metatitleref] = useState(
    "Crypto Payment Gateway"
  );
  const [metadesc, setmetadesc, metadescref] = useState(
    "Swiftly facilitating global trade through secure and efficient international exchange and trading solutions Beleaf Trading."
  );
  const initialFormValue = {
    email: "",
  };
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { email } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Please enter email";
      setemailValidate(true);
      //toast.error("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
      //toast.error("Invalid email address");
    } else {
      setemailValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const notify_submit = async () => {
    // return
    validate(formValue);
    if (formValue.email != "") {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.email)) {
        var data = {
          apiUrl: apiService.subscriber,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status) {
          toast.success(resp.Message);
          formValue.email = "";
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter valid email");
      }
    } else {
      toast.error("Please enter email");
    }
  };

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    getStatkingDetails(0);
    gethomeCurrency();
  }, [0]);

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings_2 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const redirect_url = async (url) => {
    navigate(url);
  };

  const redirect_stake = async () => {
    if (loginCheck == false) {
      navigate("/login");
    } else {
      navigate("/staking");
    }
  };

  const getStatkingDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.get_Staking_Details,
      };
      var resp = await getMethod(data);
      setCurrencyDetails(resp.Message);
    } catch { }
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };

  return (
    <>
      <Helmet>
        <title>{metatitleref.current}</title>
        <meta name="title" content={metatitleref.current} />
        <meta name="description" content={metadescref.current} />
      </Helmet>
      <div>
        <Header />
        <main className="safest-payment-gateway">
          <section className="section_one">
            <Container maxWidth="lg">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="contsnt">
                    <h1>Safest Crypto Payment Gateway</h1>
                    <p>
                      Hassle free crypto payment ecosystem for your business.
                      Get benefit from the most secure crypto gateway store.
                    </p>

                    <div className="button_section_width1">
                      <Button
                        className="d-block mx-auto"
                        onClick={() => redirect_url("/login")}
                      >
                        Let’s Explore{" "}
                        <i class="fa-solid fa-arrow-right-long"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <section className="bg-black crypto-currency-section">
            <div className="">
              <div className="row">
                <div className="col-lg-12">
                  <div className=" w-100">
                    <div className="market_section my-3">
                      <marquee>
                        <div class="margee_flex">
                          {currencylistData &&
                            currencylistData.map((obj, i) => {
                              return (

                                obj.currencySymbol == "BAL" || obj.currencySymbol == "JST" || obj.currencySymbol == "LRC" ? "" : (
                                  <div class="crypto_news">
                                    <img src={obj.Currency_image} width="40px" />
                                    <span class="currencySymbol">


                                      {obj.currencySymbol}
                                    </span>
                                  </div>
                                )

                              );
                            })}
                        </div>
                      </marquee>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section_two">
            <div className="inner">
              <div className="container pt-5">
                <div className="row">
                  <div className="col-lg-12 mt-5">
                    <div className="title_text_home gateway">
                      <h2>Embrace seamless payments across borders.</h2>
                      <p>
                        Open up countless opportunities for your clients and
                        accept cryptocurrency payments with ease from anywhere
                        in the world. Transact with lightning speed and the
                        lowest possible fees.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="second-section-banner">
                      <img
                        src={require("../Image/New-Home/section2-banner.png")}
                      />
                      <img
                        src={require("../Image/New-Home/banner2-coins.png")}
                        className="floating-asset"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="gateway-second-content">
                      <div className="content-text">
                        <i class="fa-solid fa-arrow-right-long"></i>{" "}
                        <p>
                          Create invoices, generate payment links, facilitate
                          checkout, integrate plugins, and send them to your
                          customers effortlessly.
                        </p>
                      </div>
                      <div className="content-text">
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <p>
                          {" "}
                          Make profits by using settlements in your preferred
                          currency for high-level transactions.
                        </p>
                      </div>
                      <div className="content-text">
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <p>
                          {" "}
                          Offer the option for users to pay using their Metamask
                          or Trust Wallet easily
                        </p>
                      </div>
                      <div className="content-text">
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <p>
                          {" "}
                          Easily accept payments from your clients using your
                          preferred cryptocurrencies for seamless settlement.
                        </p>
                      </div>
                      <div className="content-text">
                        <i class="fa-solid fa-arrow-right-long"></i>
                        <p>
                          {" "}
                          Keep tabs on blockchain transactions in real-time,
                          both inside and outside the system.
                        </p>
                      </div>
                      {/* <div className="button_section_width1 connect-button">
                        <Button
                          className="d-block mx-auto"
                          onClick={() => redirect_url("/login")}
                        >
                          Connect Us Via Whatsapp{" "}
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="payment-work-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="payment-content">
                    <h2>
                      How crypto payments
                      <br /> work ?
                    </h2>
                    <p>
                      Here's how our basic crypto payment gateway works. With
                      our system, you can accept crypto payments and boost your
                      revenue in the financial markets
                    </p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Payment_slider />
                </div>
              </div>
            </div>
          </section>
          <section className="features-slider-section">
            <div className="container">
              <div className="use-case-title">
                <h2>
                  Exploring the Features of Our Crypto <br />
                  Payment Gateway Platform
                </h2>
                <p>
                  Explore our platform to discover a wide range of features
                  tailored to enhance your
                  <br /> business. Fine-tune your operations effortlessly and
                  boost your profits.
                </p>
              </div>

              <Features_slider />
              <div className="button_section_width1 connect-button demo-btn">
                <Button
                  className="d-block mx-auto"
                  onClick={() => redirect_url("/login")}
                >
                  Explore<i class="fa-solid fa-arrow-right-long"></i>
                </Button>
              </div>
            </div>
          </section>
          <section className="key-advantage-section">
            <div className="container">
              <div className="use-case-title">
                <h2>
                  Key Advantages of <br />
                  Our Payment Gateway Platform
                </h2>
              </div>
              <div className="key-line">
                <img
                  src={require("../Image/New-Home/key-advantage-line.png")}
                  className="key-line-img"
                />
              </div>
              <div className="key-advantage-cards">
                <div className="advantage-card">
                  <h4>01</h4>
                  <h3>Seamless Integration</h3>
                  <p>
                    Our platform seamlessly
                    <br /> integrates with your existing
                    <br />
                    e-commerce website or point-of
                    <br />
                    -sale system, allowing you to
                    <br />
                    accept various cryptocurrencies
                    <br /> as payment with minimal hassle.
                  </p>
                </div>
                <div className="advantage-card advantage-card2">
                  <h4>02</h4>
                  <h3>Multi-Currency Support</h3>
                  <p>
                    We support a wide range of EVM
                    <br /> and Non EVM Crypto currencies.
                    <br /> This flexibility enables your <br />
                    customers to pay with their
                    <br /> preferred digital assets
                  </p>
                </div>
                <div className="advantage-card">
                  <h4>03</h4>
                  <h3>Secure Transactions</h3>
                  <p>
                    Security is paramount in the world
                    <br /> of cryptocurrency. Our platform
                    <br /> employs robust encryption
                    <br /> protocols and advanced security
                    <br /> measures to safeguard every
                    <br /> transaction.
                  </p>
                </div>
                <div className="advantage-card ">
                  <h4>04</h4>
                  <h3>Customizable Settings</h3>
                  <p>
                    Customise our platform's
                    <br /> capabilities to meet your unique
                    <br />
                    business needs. To conform to
                    <br /> your operational preferences and
                    <br /> brand identity, you can modify
                    <br /> transaction limits
                  </p>
                </div>
                <div className="advantage-card advantage-card4">
                  <h4>05</h4>
                  <h3>Developer-Friendly API</h3>
                  <p>
                    For businesses seeking deeper
                    <br /> integration, our platform offers a<br />{" "}
                    developer-friendly API
                    <br /> (Application Programming
                    <br /> Interface).
                  </p>
                </div>
              </div>
              <div className="button_section_width1 connect-button demo-btn">
                <Button
                  className="d-block mx-auto"
                  onClick={() => redirect_url("/login")}
                >
                  Get A Demo <i class="fa-solid fa-arrow-right-long"></i>
                </Button>
              </div>
            </div>
          </section>

          <section className="use-case-section">
            <div className="container">
              <div className="use-case-title">
                <h2>Use cases of payment gateway</h2>
                <p>
                  Gateway stores offer a range of benefits for your business.
                  Let's explore these use
                  <br /> cases to maximize your advantages
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="use-case-card">
                    <h3>
                      E-commerce &<br />
                      Plugins
                    </h3>
                    <img
                      src={require("../Image/New-Home/E-commerce&plugins.png")}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="use-case-card token">
                    <h3>
                      Crypto Token
                      <br />
                      Purchase
                    </h3>
                    <img src={require("../Image/New-Home/crypto-token.png")} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="use-case-card platform">
                    <h3>
                      Freelancing
                      <br />
                      Platforms
                    </h3>
                    <img
                      src={require("../Image/New-Home/freelancing-platforms.png")}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="use-case-card donations">
                    <h3>Donations</h3>
                    <img src={require("../Image/New-Home/Money.png")} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="use-case-card booking">
                    <h3>
                      Ticketing &<br /> Event booking
                    </h3>
                    <img src={require("../Image/New-Home/Ticket.png")} />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="use-case-card travel">
                    <h3>Travel & Tourism</h3>
                    <img src={require("../Image/New-Home/Travel.png")} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="control-business-section">
            <Container maxWidth="lg">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="contsnt control">
                    <h1>Take control of your business</h1>
                    <p>
                      Empower your business with our crypto payment gateway:
                      lower fees, instant settlements, global reach.
                    </p>

                    <div className="button_section_width1">
                      <Button
                        className="d-block mx-auto"
                        onClick={() => redirect_url("/login")}
                      >
                        Get Started <i class="fa-solid fa-arrow-right-long"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </main>
        <div></div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
