import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";

function Home() {

  const initialFormValue = {
    email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [confirmPasswordValidate, setconfirmPasswordValidate, confirmPasswordValidateref] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [activeStatus, seractiveStatus] = useState(false);
  const [dpliutl, setdpliutl] = useState("");

  const [validationnErr1, setvalidationnErr1] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const { email } = formValue;
  const { password, confirmPassword } = formValue1;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };
  const handleChange1 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData1 = { ...formValue1, ...{ [name]: value } };
    setFormValue1(formData1);
    validate1(formData1);
  };

  useEffect(() => {
    linkverify();
  }, [0]);

  const linkverify = async (values) => {
    var dpliutl = window.location.href.split("_")[1];
    if (dpliutl) {
      setdpliutl(dpliutl);
      var obj = {
        link: dpliutl,
      };
      var data = {
        apiUrl: apiService.verifyForgotpasslink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        seractiveStatus(true);
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is a required field";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else if (values.email.length > 254) {
      errors.email = "Email address is too long";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors1 = {};

    if (!values.password) {
      errors1.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors1.password =
        "Password should not below 5 above 25 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least lower character ";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least upper character ";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at One digit character ";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one special character ";
    }
    else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors1.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    } else if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
      errors1.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setpasswordValidate(false);

      setconfirmPasswordValidate(false);
    }



    setvalidationnErr1(errors1);
    return errors1;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.email != "" && emailValidateref.current == false) {
      var obj = {
        email: formValue.email,
      };
      var data = {
        apiUrl: apiService.changepswdlink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp, "==-=-resp");
      setbuttonLoader(false);
      setFormValue(initialFormValue);
      if (resp.status == true) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    }
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false) {
      var obj = {
        password: formValue1.password,
        confimPassword: formValue1.confirmPassword,
        id: dpliutl,
      };

      console.log(obj, "=-=-=-=-=-=-=-==-=");
      var data = {
        apiUrl: apiService.resetpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue1(initialFormValue1);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 d-flex align-items-center">
                {activeStatus == false ? (
                  <div className="card_login w-100 shadow">
                    <h3>Forget Password</h3>
                    <div>
                      <div class="form_login_input">
                        <label>Email</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                        {emailValidateref.current == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.email}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div class="form_login_input">
                        {buttonLoader == false ? (
                          <Button onClick={formSubmit} className="mt-5">Submit</Button>
                        ) : (
                          <Button className="mt-5">Loading...</Button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card_login w-100 shadow">
                    <h3>Reset Password</h3>
                    <div>
                      <div class="form_login_input">
                        <label>Enter New Password</label>
                        <input
                          type={inputType}
                          class="form-control"
                          placeholder="New Password"
                          maxLength={25}
                          name="password"
                          value={password}
                          onChange={handleChange1}
                        />
                        <div className="input-group-addon ">
                          {passHide == false ? (
                            <i
                              className="bi bi-eye-slash-fill eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          )}
                        </div>
                        <div>
                          {passwordValidate == true ? (
                            <p className="text-danger ">
                              {" "}
                              {validationnErr1.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="form_login_input">
                        <label className="mt-5">Confirm New Password</label>
                        <input
                          type={inputTypeconf}
                          class="form-control"
                          placeholder="Confirm New Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          maxLength={25}

                          onChange={handleChange1}
                        />
                        <div className="input-group-addon">
                          {passHidconf == false ? (
                            <i
                              className="bi bi-eye-slash-fill eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-fill eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          )}
                        </div>
                        <div>
                          {confirmPasswordValidate == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr1.confirmPassword}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div class="form_login_input ">
                        {buttonLoader == false ? (
                          <Button className="mt-5" onClick={formSubmitchange}>
                            Change Password
                          </Button>
                        ) : (
                          <Button className="mt-5">Loading...</Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
