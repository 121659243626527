import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import Sidebar from "./Sidebarlink";
import { Dropdown } from "semantic-ui-react";
import { PhoneInput } from "react-contact-number-input";
import 'react-phone-input-2/lib/style.css';
import PhoneInput2 from 'react-phone-input-2';

function Home() {
  const initialFormValue = {
    bussinessName: "",
    website: "",
    State: "",
    City: "",
    Country : "US"
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const [bussinessNameErr, SetbussinessNameErr, bussinessNameErrref] =
    useState(false);
  const [websiteErr, SetwebsiteErr, websiteErrref] = useState(false);
  const [stateErr, SetstateErr, stateErrref] = useState(false);
  const [cityErr, SetcityErr, cityErrref] = useState(false);

  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [logoImage, setlogoImage] = useState("");
  const [imagelable, setimagelable] = useState("");
  const [logoImageErr, SetlogoImageErr] = useState(false);
  const [bussinessDetailsset, setBussinessdetails] = useState([]);
  const [editStatus, setEditstatus] = useState(false);
  const [id, setid] = useState(false);
  const [statusdata, setstatusdata] = useState(false);

  const { bussinessName, website, Mobile } = formValue;


  const [selectedCountry, setSelectedCountry, selectedCountryref] =
    useState("");
  const [selectedState, setSelectedState, selectedStateref] = useState("state");
  const [selectedCity, setSelectedCity, selectedCityref] = useState("city");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [mobileInput, setmobileInput, mobileInputref] = useState("");
  const [numberErr, setnumberErr] = useState("");
  const [numberEs, setnumberEs] = useState("+917538837107");

  const [edit, setedit] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ name: firstCountry } = {}] = allCountries;
        console.log(allCountries, "allCountries");
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
        SetstateErr(false);
      } catch (error) {
        setCountries([]);
      }
    };
    getCountries();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      console.log(selectedCountry, "selectedCountry", selectedState);
      try {
        console.log(selectedCountryref.current, "selectedCountryref.current");
        const result = await State.getStatesOfCountry(
          selectedCountryref.current
        );
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        console.log("formValue", formValue);
        if (formValue.State == "") {
          setSelectedState(firstState);
        } else {
          setSelectedState(formValue.State);
        }
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };
    getStates();
  }, [selectedCountryref.current]);

  useEffect(() => {
    const getCities = async () => {
      console.log(selectedStateref.current, "selectedCountry");
      console.log(selectedCityref.current, "selectedCity");
      try {
        const result = await City.getCitiesOfState(
          selectedCountryref.current,
          selectedStateref.current
        );
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        const [{ name: firstCity = "" } = {}] = allCities;
        setCities(allCities);
        if (formValue.City == "") {
          setSelectedCity(firstCity);
        } else {
          setSelectedCity(formValue.City);
        }
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
  }, [selectedStateref.current]);

  useEffect(() => {
    getbussinessdetails();
  }, []);

  const navigate = useNavigate();

  const handleOnChange = (type) => {
    console.log(type, "=-=-=-=-=");
    if (type.countryCode != null && type.phoneNumber != null) {
      var number = type.countryCode + " " + type.phoneNumber;
      setmobileInput(number);
      setnumberErr(type.message);
    }
  };

  const handleOnChange2 = (value, country, e, formattedValue) => {
    console.log(value, "value", country, e, formattedValue);
    setmobileInput(value);
    setFormValue({ ...formValue, Mobile: value });

    // Validate phone number length

    console.log(value.length, "value.length");
    console.log(country.format.length)


    const isValid = value.length == (country.format.length - 3);
    console.log(isValid, "isValid");
    if (!isValid) {
      setnumberErr('Invalid phone number length');
    } else {
      setnumberErr("");
    }
  };

  const Editbutton = () => {
    setedit(true);
  };

  const backbutton = () => {
    setedit(false);
  };

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;

    if (name == "website") {
      console.log(name, "name1", value, "value");

      const sanitizedValue = value.replace(/\s/g, "");
      let Data = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(Data);
      console.log("Data", Data);
      console.log("formValue", formValue);
      validate(Data);
    } else if (name == "bussinessName") {
      let sanitizedValue3 = value.trimLeft();
      let Data = { ...formValue, ...{ [name]: sanitizedValue3 } };
      setFormValue(Data);
      validate(Data);
    } else {
      console.log(name, "name2", value, "value");

      let Data = { ...formValue, ...{ [name]: value } };
      setFormValue(Data);
      validate(Data);
    }
    // let formData = { ...formValue, ...{ [name]: value } };
    // console.log("formData====", formData);
    // setFormValue(formData);
  };

  const validate = async (values) => {
    console.log(values, "values");
    const errors = {};
    if (values.bussinessName == "") {
      errors.bussinessName = "Bussiness Name is required";
      SetbussinessNameErr(true);
    } else if (!/^[a-zA-Z]/.test(values.bussinessName)) {
      errors.bussinessName = "Bussiness Name must start with a letter ";
      SetbussinessNameErr(true);
    } else if (
      values.bussinessName?.length < 4 ||
      values.bussinessName?.length > 25
    ) {
      errors.bussinessName = "Bussiness Name must have an  4 to 25 characters ";
      SetbussinessNameErr(true);
    } else if (!/^[a-zA-Z0-9_ ]+$/.test(values.bussinessName)) {
      errors.bussinessName =
        "Username can only contain letters, numbers, and underscores ";
      SetbussinessNameErr(true);
    } else if (logoImage == "") {
      SetbussinessNameErr(false);

      console.log("ijsdifsdaiufio");
      errors.logoImage = "Logo is required ";
      SetlogoImageErr(true);
    } else if (values.website == "") {
      SetlogoImageErr(false);

      errors.Website = "Website Url is required";
      SetwebsiteErr(true);
    } else if (values.website?.length < 4 || values.website?.length > 25) {
      errors.Website = "Website Url must have an  4 to 25 characters ";
      SetwebsiteErr(true);
    } else if (
      !/^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/.test(
        values.website
      )
    ) {
      errors.Website = "Invalid website URL";
      SetwebsiteErr(true);
      // } else if (values.State == "") {
      //   SetwebsiteErr(false);
      //   errors.State = "State is required";
      //   SetstateErr(true);
      // } else if (values.City == "") {
      //   SetstateErr(false);
      //   errors.City = "City is required";
      //   SetcityErr(true);
    } else {
      SetlogoImageErr(false);
      SetbussinessNameErr(false);
      SetstateErr(false);
      SetcityErr(false);
      SetwebsiteErr(false);
      // setnumberErr("Mobile Number is required");
    }

    setvalidationnErr(errors);
    return errors;
  };

  const imageUpload = (val) => {
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "fgzjzpjf");
      data.append("cloud_name", "dysf7ocz6");
      console.log("formdata===", data);
      fetch("https://api.cloudinary.com/v1_1/dysf7ocz6/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data.secure_url);
          setlogoImage(data.secure_url);
          SetlogoImageErr(false);

          setimagelable(fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getbussinessdetails = async () => {
    try {
      var data = {
        apiUrl: apiService.getbussinessdetails,
      };
      var resp = await getMethod(data);
      console.log(resp.data);

      if (resp.status == true) {
        setFormValue(resp.data);
        setlogoImage(resp.data.logo);
        const parts = resp.data.logo.split("/");

        // Get the last part of the URL
        const splited = parts[parts.length - 1];

        console.log(splited);
        setimagelable(splited);
        setstatusdata(false);
        setSelectedCountry(resp.data.Country);
        setSelectedState(resp.data.State);
        setSelectedCity(resp.data.City);
        formValue["Mobile"] = resp.data.Mobile;
        setmobileInput(resp.data.Mobile);
      }else{
        setstatusdata(true);
      }
    } catch (error) { }
  };

  const submitID = async () => {
    try {
      console.log(formValue, "formValue");
      formValue["logo"] = logoImage;
      formValue["Country"] = selectedCountry;
      formValue["State"] = selectedState;
      formValue["City"] = selectedCity;
      validate(formValue);
      if (
        // formValue.State &&
        // formValue.City &&
        mobileInput != "" &&
        numberErr == "" &&
        bussinessNameErrref.current == false &&
        websiteErrref.current == false &&
        logoImage != ""
      ) {
        if (!formValue.Mobile) {
          formValue["Mobile"] = mobileInput;
        }

        setbuttonLoader(true);

        var data = {
          apiUrl: apiService.savebussinessdetails,
          payload: formValue,
        };
        var resp = await postMethod(data);
        console.log(resp, "=-=-resp=--=-=");
        if (resp.status) {
          setbuttonLoader(false);
          formValue.bussinessName = "";
          formValue.website = "";
          setlogoImage("");
          toast.success(resp.Message);
          getbussinessdetails();
          // navigate("/merchantkyc");
          setedit(false);
        } else {
          toast.error(resp.Message);
        }
      } else {
        console.log("ALL FIELD NEED");
      }
    } catch (error) { }
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  <div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <div>
                    <section className="deposut_section">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12 ">
                            <div className="headin_deposur_s">
                              <h3>Verify Business</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="form-deposit">
                      <div className="container">
                        {edit == false ? (
                          <div className="row login_form cardb justify-content-center payment_burron">
                            <div className="col-lg-11 mb-5 card_login mx-3">
                              <div className="d-flex justify-content-center mt-5">
                                {logoImage == "" ? (
                                  <img
                                    src={require("../Image/profile.png")}
                                    className="mx-auto block mb-3"
                                    width="100px"
                                  />
                                ) : (
                                  <img
                                    src={logoImage}
                                    className="mx-auto block mb-3"
                                    width="100px"
                                  />
                                )}
                              </div>

                              {/* {logoImage ? (<img width="50" src={logoImage} />) : "hello"} */}

                              <div className="qr_section_class row">
                                <div className="form_login_input crypto_coin_selec col-lg-6">
                                  <label>
                                    Business Name
                                    <small>4/25characters</small>
                                  </label>

                                  <div className="">
                                    <input
                                      type="text"
                                      placeholder="Ex : Beleaf"
                                      name="bussinessName"
                                      className="form-control"
                                      value={bussinessName}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="form_login_input crypto_coin_selec col-lg-6">
                                  <label className="">
                                    Website
                                    <small>4/25characters</small>
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ex : https://www.google.com/"
                                      name="website"
                                      value={website}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="form_login_input crypto_coin_selec col-lg-6">
                                  <label className="">Country</label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ex : India"
                                      name="website"
                                      value={selectedCountry}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="form_login_input crypto_coin_selec col-lg-6">
                                  <label className="">State</label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ex : Tamilnadu"
                                      name="website"
                                      value={selectedState}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="form_login_input crypto_coin_selec col-lg-6">
                                  <label className="">City</label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ex : Madurai"
                                      name="website"
                                      value={selectedCity}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="form_login_input crypto_coin_selec col-lg-6">
                                  <label className="">Mobile Number</label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ex : 9876543210"
                                      name="website"
                                      value={formValue.Mobile}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="form_login_input my-3">
                                <Button onClick={Editbutton}>Edit</Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row login_form cardb justify-content-center payment_burron">
                            <div className="col-lg-6 mb-5 card_login mx-3">
                              <div className="qr_section_class">
                                <div className="form_login_input crypto_coin_selec">
                                  <label>
                                    Business Name
                                    <small>4/25characters</small>
                                  </label>

                                  <div className="">
                                    <input
                                      type="text"
                                      placeholder="Business Name"
                                      name="bussinessName"
                                      minLength={4}
                                      maxLength={25}
                                      className="form-control"
                                      value={bussinessName}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    {bussinessNameErrref.current == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.bussinessName}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="crypto_coin_selec form_login_input pa-_opo">
                                  <label className="">Logo</label>
                                  {logoImage ? (
                                    <img width="50" src={logoImage} />
                                  ) : (
                                    ""
                                  )}

                                  <div className="flex_coin_copy inut_file">
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        class="custom-file-input "
                                        id="customFile"
                                        onChange={(e) =>
                                          imageUpload(e.target.files[0])
                                        }
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="customFile"
                                      >
                                        {imagelable == ""
                                          ? "Choose File"
                                          : imagelable}
                                      </label>
                                    </div>
                                  </div>
                                  <div></div>
                                  {logoImageErr == true ? (
                                    <p className="text-danger top_100">
                                      {" "}
                                      {validationnErr.logoImage}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="form_login_input mt-24 crypto_coin_selec">
                                  <label className="">
                                    Website
                                    <small>4/25characters</small>
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Website"
                                      name="website"
                                      value={website}
                                      minLength={4}
                                      maxLength={25}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    {websiteErrref.current == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.Website}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="form_login_input">
                                  <label className="">Country</label>
                                  <div className="">
                                    <select
                                      class="dropdown-toggle cscdrop form-control"
                                      id="cars"
                                      name="Country"
                                      value={selectedCountry}
                                      onChange={(event) =>
                                        setSelectedCountry(event.target.value)
                                      }
                                    >
                                      {countries.length > 0 ? (
                                        countries.map(({ isoCode, name }) => (
                                          <option
                                            class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                            value={isoCode}
                                            key={isoCode}
                                          >
                                            {name}
                                          </option>
                                        ))
                                      ) : (
                                        <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                          No Countries Found!
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form_login_input col-lg-6">
                                    <label className="">State</label>
                                    <div className="">
                                      <select
                                        class="dropdown-toggle cscdrop form-control"
                                        name="state"
                                        value={selectedState}
                                        onChange={(event) =>
                                          setSelectedState(event.target.value)
                                        }
                                      >
                                        {states.length > 0 ? (
                                          states.map(({ isoCode, name }) => (
                                            <option
                                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                              value={isoCode}
                                              key={isoCode}
                                            >
                                              {name}
                                            </option>
                                          ))
                                        ) : (
                                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                            No States Found!
                                          </option>
                                        )}
                                      </select>
                                    </div>

                                    {stateErrref.current == true ? (
                                      <h6 className="text-danger">
                                        {" "}
                                        {validationnErr.State}{" "}
                                      </h6>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="form_login_input col-lg-6">
                                    <label className="">City</label>
                                    <div className="">
                                      <select
                                        class="dropdown-toggle cscdrop form-control"
                                        name="city"
                                        value={selectedCity}
                                        onChange={(event) =>
                                          setSelectedCity(event.target.value)
                                        }
                                      >
                                        {cities.length > 0 ? (
                                          cities.map(({ isoCode, name }) => (
                                            <option
                                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                              value={isoCode}
                                              key={isoCode}
                                            >
                                              {name}
                                            </option>
                                          ))
                                        ) : (
                                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                            No Cities Found!
                                          </option>
                                        )}
                                      </select>
                                      {cityErrref.current == true ? (
                                        <h6 className="text-danger">
                                          {" "}
                                          {validationnErr.City}{" "}
                                        </h6>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="form_login_input">
                                  <label className="">Mobile Number</label>

                                  {statusdata == true ? (
                                    <div className="phoneinput">
                                      <PhoneInput
                                        class="mobileInput"
                                        onChange={handleOnChange}
                                      />
                                      {numberErr ? (
                                        <p style={{ color: "red" }}>
                                          {numberErr}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                  <>
                                    <div className="phoneinput">
                                      <PhoneInput2
                                        country={formValue.Country ? formValue.Country : "US"}
                                        class="mobileInput"
                                        onChange={handleOnChange2}
                                        value={formValue.Mobile}
                                      />
                                      {numberErr ? (
                                        <p style={{ color: "red" }}>
                                          {numberErr}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>

                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div class="form_login_input my-2">
                                    <Button onClick={backbutton}>Back</Button>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  {buttonLoader == false ? (
                                    <div class="form_login_input my-2">
                                      <Button onClick={submitID}>Submit</Button>
                                    </div>
                                  ) : (
                                    <div class="form_login_input my-2">
                                      <Button>Loading...</Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  </div>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
