import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { IconLockCancel } from "@tabler/icons-react";
import { IconCircleArrowLeftFilled } from "@tabler/icons-react";
import { Dropdown } from "semantic-ui-react";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebarlink";

function Home() {
  const textRef = useRef(null);
  const navigate = useNavigate();

  const initialFormValue = {
    Name: "",
    Description: "",
    Terms: "",
    Fixed: "",

  };

  const cleardata = {
    Name: "",
    Description: "",
    logoImage: "",
    Terms: "",

  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [currency, setCurrency] = useState("");
  const [currencyErr, setCurrencyErr, setCurrencyErrref] = useState(false);
  const [logoImage, setlogoImage] = useState("");
  const [imagelable, setimagelable] = useState("");
  const [logoImageErr, SetlogoImageErr] = useState(false);
  const [NameErr, SetNameErr, NameErrref] = useState(false);
  const [DescriptionErr, SetDescriptionErr, DescriptionErrref] = useState(
    false
  );
  const [TermsErr, SetTermsErr, TermsErrref] = useState(false);
  const [FixedErr, SetFixedErr, FixedErrref] = useState(false);
  const [maximumErr, SetmaximumErr, maximumErrref] = useState(false);
  const [amounttypeErr, SetamounttypeErr, amounttypeErrref] = useState(false);
  const [amount1Err, Setamount1Err, amount1Errref] = useState(false);
  const [amount2Err, Setamount2Err, amount2Errref] = useState(false);
  const [amount3Err, Setamount3Err, amount3Errref] = useState(false);
  const [amount4Err, Setamount4Err, amount4Errref] = useState(false);
  const [informationErr, SetinformationErr, informationErrref] = useState(
    false
  );
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [validationnErr, setvalidationnErr] = useState("");
  const [fixedamount, setfixedamount] = useState(false);
  const [visitorchoice, setvisitorchoice] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    Name,
    Description,
    Terms,
    Fixed,
  } = formValue;

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;


    if (value.startsWith(' ')) {
      let sanitizedValue1 = value.trimLeft();
      let formData = { ...formValue, ...{ [name]: sanitizedValue1 } };
      setFormValue(formData);
      validate(formData);

    } else if (name == "Fixed") {
      console.log(name, "name1", value, "value");

      if (value.length < 6) {
        const sanitizedValue = value.replace(/\s/g, "");
        let Data = { ...formValue, ...{ [name]: sanitizedValue } };
        setFormValue(Data);
        validate(Data);
      }



    }
    else {
      console.log(name, "name2", value, "value");

      let Data = { ...formValue, ...{ [name]: value } };
      setFormValue(Data);
      validate(Data);

    }

  };

  const handleChange1 = async (e) => {
    formValue.information.push(e.target.value);

    console.log(formValue, "=-=-=-=-=-=-=-=");
  };

  const handleCopy = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => { })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  const onSelect = async (option) => {
    console.log(option.target.outerText);
    setCurrency(option.target.outerText);
  };

  const validate = async (values) => {
    const errors = {};


    if (values.Name == "") {
      errors.Name = "Name is required ";
      SetNameErr(true);
    } else if (!/^[a-zA-Z]/.test(values.Name)) {
      errors.Name = "Name must start with a letter ";
      SetNameErr(true);
    } else if (!/^[a-zA-Z0-9_\s]+$/.test(values.Name)) {
      errors.Name = "Name can only contain letters, numbers, and underscores ";
      SetNameErr(true);
    } else if (values.Name.length < 3 || values.Name.length > 25) {
      errors.Name =
        "Name Number should not below 3 above 25 letters ";
      SetNameErr(true);
    } else if (values.Description == "") {
      SetNameErr(false);

      errors.Description = "Description is required ";
      SetDescriptionErr(true);
    } else if (!/^[a-zA-Z]/.test(values.Description)) {
      errors.Description = "Description must start with a letter ";
      SetDescriptionErr(true);
    } else if (!/^[a-zA-Z0-9_\s,.]+$/.test(values.Description)) {
      errors.Description = "Description can only contain letters, numbers, and underscores ";
      SetDescriptionErr(true);
    } else if (values.Description.length < 5 || values.Description.length > 50) {
      errors.Description =
        "Description Number should not below 5 above 50 letters ";
      SetDescriptionErr(true);
    } else if (values.Terms == "") {
      SetDescriptionErr(false);

      errors.Terms = "Terms is required ";
      SetTermsErr(true);
      // } else if (!/^[a-zA-Z]/.test(values.Terms)) {
      //   errors.Terms = "Terms must start with a letter ";
      //   SetTermsErr(true);
    } else if (!/^[a-zA-Z0-9_\s,.]+$/.test(values.Terms)) {
      errors.Terms = "Terms can only contain letters, numbers, and underscores ";
      SetTermsErr(true);
    } else if (values.Terms.length < 5 || values.Terms.length > 250) {
      errors.Terms =
        "Terms Number should not below 5 above 250 letters ";
      SetTermsErr(true);
    } else if (values.Fixed == "") {
      SetTermsErr(false);
      errors.Fixed = "Price is required ";
      SetFixedErr(true);
    } else if (logoImage == "") {
      SetFixedErr(false);
      console.log("ijsdifsdaiufio");
      errors.logoImage = "Image is required";
      SetlogoImageErr(true);
    } else {
      SetlogoImageErr(false);
      SetFixedErr(false);
      SetDescriptionErr(false);
      SetNameErr(false);
      SetTermsErr(false);
    }



    setvalidationnErr(errors);
    return errors;
  };

  useEffect(() => {
    getAllcurrency();
  }, [0]);

  const imageUpload = (val) => {
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "fgzjzpjf");
      data.append("cloud_name", "dysf7ocz6");
      console.log("formdata===", data);
      fetch("https://api.cloudinary.com/v1_1/dysf7ocz6/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data.secure_url);
          setlogoImage(data.secure_url);
          setimagelable(fileName);
          SetlogoImageErr(false);

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.getCurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          //label: data[i].currencySymbol,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
          coinType: data[i].coinType,
          erc20token: data[i].erc20token,
          bep20token: data[i].bep20token,
          trc20token: data[i].trc20token,
          rptc20token: data[i].rptc20token,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const Merchant = async () => {
    try {
      validate(formValue);
      formValue["logo"] = logoImage;
      if (
        NameErrref.current == false &&
        DescriptionErrref.current == false &&
        TermsErrref.current == false &&
        FixedErrref.current == false
      ) {
        setbuttonLoader(true);

        var data = {
          apiUrl: apiService.merchantdetail,
          payload: formValue,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-resp=--=-=");
        if (resp.status == true) {
          setbuttonLoader(false);
          setFormValue(cleardata);
          navigate("/checkout");
          toast.success(resp.Message);
          setlogoImage("");
        } else {
          toast.error(resp.Message);
        }
      } else {
        // toast.error("Please fill all the require  fields");
        console.log("ALL FIELD NEED");
      }
    } catch (error) { }
  };

  return (
    <div className="header">
      <Header />
      <div className="css-1wr4jig">
        <div className="css-1o43t2y">
          <div className="css-1t8cbiy">
            <Sidebar />
          </div>
          <div className="css-1h690ep">
            <main className="main_heinght">
              {loader == true ? (
                <div class="spinner css-ezm1fk">
                  z<div class="css-1qoumk2"></div>
                  <div class="css-1v19680"></div>
                  <div class="css-1v90jie"></div>
                  <div class="css-19p0rvp"></div>
                </div>
              ) : (
                <>
                  <section className="deposut_section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 p-3 ">
                          <div className="headin_deposur_s ">
                            <h3>
                              <i
                                class="ri-arrow-left-line cusonr"
                                onClick={() => navigate("/checkout")}
                              ></i>
                              <span className="pl-3">Create a checkout</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div>
                    <section className="form-deposit">
                      <div className="container">
                        <div className="row login_form cardb p10 justify-content-center payment_burron p-0">
                          <div className="col-lg-6 card_login">
                            <h3>
                              Provide details about your checkout
                            </h3>
                            <div className="qr_section_class">
                              <div className="crypto_coin_selec form_login_input">
                                <label>Checkout Name</label>

                                <div className="">
                                  <input
                                    type="text"
                                    placeholder=" Checkout Name"
                                    class="form-control"
                                    name="Name"
                                    minLength={3}
                                    maxLength={25}
                                    value={Name}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="">
                                  {NameErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.Name}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="crypto_coin_selec form_login_input">
                                <label>Description</label>
                                <div className="">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Description"
                                    name="Description"
                                    minLength={5}
                                    maxLength={50}
                                    value={Description}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {DescriptionErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.Description}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="crypto_coin_selec form_login_input mt-4">
                                <label>
                                  Terms of service or other information
                                </label>
                                <div className=" textarea">
                                  <textarea
                                    placeholder="Terms of service or other information"
                                    class="form-control"
                                    name="Terms"
                                    minLength={5}
                                    maxLength={250}
                                    value={Terms}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {TermsErrref.current == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.Terms}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>



                              <div className="crypto_coin_selec form_login_input  mt-4">
                                <label>Price (USD)</label>

                                <div className="">
                                  <input
                                    type="number"
                                    placeholder="Price per item"
                                    name="Fixed"
                                    class="form-control"
                                    min="0"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    max="100000"
                                    value={Fixed}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {FixedErr == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.Fixed}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="crypto_coin_selec form_login_input mt-4">
                                <label>Image</label>

                                {logoImage ? <img
                                  width="50"
                                  src={logoImage}
                                  className="mb-3"
                                /> : ""}

                                <div className="flex_coin_copy inut_file">
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      class="custom-file-input form-control"
                                      id="customFile"
                                      onChange={(e) =>
                                        imageUpload(e.target.files[0])
                                      }
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {imagelable == ""
                                        ? "Choose File"
                                        : imagelable}
                                    </label>
                                  </div>
                                </div>
                                <div></div>
                                {logoImageErr == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {validationnErr.logoImage}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="mt-4 submitbtn">
                                {buttonLoader == false ? (
                                  <div class="form_login_input">
                                    <Button className="mt-5" width="50%" onClick={Merchant}>
                                      Submit
                                    </Button>
                                  </div>
                                ) : (
                                  <div class="form_login_input pading_foem">
                                    <Button className="mt-5">Loading...</Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                
                  </div>
                </>
              )}
            </main>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
