import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Home() {

  const initialFormValue = {
    type: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    isTerms: "",
    RefferedBy: "",
  };
  const [Valids, setValids, Validsref] = useState(false);

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate, nameValidateref] = useState(false);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(false);
  const [confirmPasswordValidate, setconfirmPasswordValidate, confirmPasswordValidateref] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [refValidation, setRefValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  //const [RefferedBy, setRefferedBy] = useState("");

  const navigate = useNavigate();

  const { type, name, email, isTerms, password, confirmPassword, RefferedBy } =
    formValue;

  useEffect(() => {
    window.scrollTo(0, 0);
    var currURL = window.location.href;
    var refferalId = currURL.split("invite/")[1];
    //setRefferedBy(refferalId);
    formValue.RefferedBy = refferalId;
  }, []);

  const handleChange = async (e) => {

    setValids(true);

    e.preventDefault();
    const { name, value } = e.target;

    console.log(name, value)

    if (name == "name") {

      console.log("inside")
      const Trimdata = value.trimLeft();
      let formData = { ...formValue, ...{ [name]: Trimdata } };
      setFormValue(formData);
      validate(formData);

    } else {
      const sanitizedValue = value.replace(/\s/g, '');
      let formData = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(formData);
      validate(formData);
    }

  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };

  const validate = async (values) => {
    const errors = {};

  const username = values.email.split('@')[0];


    if (!values.name) {
      errors.name = "Name is a required field ";
      setnameValidate(true);
    } else if (!/^[a-zA-Z]/.test(values.name)) {
      errors.name = "Username must start with a letter ";
      setnameValidate(true);
    } else if (values.name.length < 3 || values.name.length > 25) {
      errors.name = "Name must have an  3 to 25 characters ";
      setnameValidate(true);
    } else if (!/^[a-zA-Z0-9_ ]+$/.test(values.name)) {
      errors.name =
        "Username can only contain letters, numbers, underscores, and spaces";
      setnameValidate(true);
    } else if (!values.email) {
      setnameValidate(false);
      errors.email = "Email is a required field";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address format";
      setemailValidate(true);
    } else if (username.length < 6 || username.length > 30) {
      errors.email = "Email Username must be between 6-30 characters long";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email = " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setemailValidate(true);
    }  else if (values.email.length > 50) {
      errors.email = "Email address is too long";
      setemailValidate(true);
    } else if (values.password === "") {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "Password is required ";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = "Password should not be below 5 or above 25 letters ";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one lowercase character ";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one uppercase character ";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one digit ";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character ";
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and Confirm password do not match";
      setconfirmPasswordValidate(true);
    } else if (!Terms) {
      setconfirmPasswordValidate(false);
      errors.terms = "Terms is a required field ";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setconfirmPasswordValidate(false);
      setpasswordValidate(false);
      setemailValidate(false);
      setnameValidate(false);

      setTermsValidation(false);
    }
    setvalidationnErr(errors);
    return errors;
  };


  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };


  const formSubmit = async (payload) => {

    if (Validsref.current == true) {
      formValue["type"] = "User";
      validate(formValue);
      if (
        nameValidateref.current === false &&
        emailValidateref.current === false &&
        passwordValidateref.current === false &&
        confirmPasswordValidateref.current === false &&
        Terms === true
      ) {
        console.log(formValue);
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setFormValue(initialFormValue);
        localStorage.setItem("useremail", resp.email);
        setbuttonLoader(false);
        setIschecked(false);
        setTerms(false);
        // setTermsValidation(true);

        if (resp.status) {
          toast.success(resp.Message);
          navigate("/otp");
        } else {
          toast.error(resp.Message);
        }
      }
    }

  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6 mb-5">
                <div className="instruction_content">
                  <div className="steps_section">
                    <h2>
                      <span>Step 1:</span> Confirm your email address
                    </h2>
                    <p>
                      We will send a confirmation link to your email
                      address. Please check your inbox and confirm it.
                    </p>
                  </div>
                  <div className="steps_section">
                    <h2>Step 2: Complete account verification</h2>
                    <p>
                      For complete access to our payment processing system, you must verify your account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_login shadow">
                  <h3>Create Account</h3>
                  <div>
                    <div class="form_login_input">
                      <label>User Name</label>
                      <input
                        type="text"
                        class="form-control"
                        minLength="3"
                        maxLength="25"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                      />

                      <div>
                        {nameValidate == true ? (
                          <p className="text-danger"> {validationnErr.name} </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>Personal Email</label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        maxLength="50"

                        name="email"
                        value={email}
                        onChange={handleChange}
                      />

                      <div>
                        {emailValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.email}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>Password</label>

                      <input
                        type={inputType}
                        class="form-control"
                        placeholder="Password"
                        name="password"
                        minLength="5"
                        maxLength="25"
                        value={password}
                        onChange={handleChange}
                      />

                      <div className="input-group-addon ">
                        {passHide == false ? (
                          <i
                            className="bi bi-eye-slash-fill eyeicons"
                            onClick={() => passwordHide("hide")}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-eye-fill eyeicons"
                            onClick={() => passwordHide("show")}
                          ></i>
                        )}
                      </div>

                      <div>
                        {passwordValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input">
                      <label>Confirm Password</label>
                      <input
                        type={inputTypeconf}
                        class="form-control"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        minLength="5"
                        maxLength="25"
                        value={confirmPassword}
                        onChange={handleChange}
                      />

                      <div className="input-group-addon">
                        {passHidconf == false ? (
                          <i
                            className="bi bi-eye-slash-fill eyeicons"
                            onClick={() => passwordHideconf("hide")}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-eye-fill eyeicons"
                            onClick={() => passwordHideconf("show")}
                          ></i>
                        )}
                      </div>

                      <div>
                        {confirmPasswordValidateref.current == true ? (
                          <p className="text-danger">
                            {validationnErr.confirmPassword}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group form-check mt-5">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        onChange={handleTerms}
                        checked={isChecked}
                      />
                      <label className="form-check-label" htmlFor="exampleCheck1">
                        I have read and agree to Beleaf's{" "}
                        <a href="/terms" target="_blank">Terms of Service</a> and{" "}
                        <a href="/privacy" target="_blank">Privacy Policy</a>.
                      </label>

                      <div className="form_login_input">
                        {termsValidation ? (
                          <p className="text-danger top_0">
                            Terms and Conditions are required
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form_login_input mt-5">
                      {buttonLoader == false ? (
                        <Button onClick={formSubmit}>Sign up</Button>
                      ) : (
                        <Button>Loading...</Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
