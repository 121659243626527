import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination,Navigation } from "swiper/modules";

export default function App() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        navigation={true} 
        modules={[Navigation]}
        pagination={{
          clickable: true,
        }}
        infinite ={true}
        breakpoints={{
          
          520:{
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
       
        className="mySwiper"
      >
        <SwiperSlide className="feature-slider-card1">
          <img src={require("../Image/New-Home/Invoice.png")} />
          <h3> Generate Invoice</h3>
          <p>
            Generate and send cryptocurrency invoices
            <br /> to your customers or clients, and receive
            <br /> payments in your preferred cryptocurrency.
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/Link.png")} />
          <h3> Generate Payment Link</h3>
          <p>
            Create a payment link effortlessly and accept cryptocurrency
            payments from various cryptocurrencies.
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/Integration.png")} />
          <h3> Integration</h3>
          <p>
            Integrate cryptocurrency payments into your business effortlessly
            using our seamless API, plugins, and widgets. Enhance your business
            operations with crypto.
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/DataSolution.png")} />
          <h3> OnRamp Solutions</h3>
          <p>
            Easily buy any type of cryptocurrency using different global fiat
            currencies with the lowest processing fees. Enjoy limitless
            transactions!
          </p>
        </SwiperSlide>
        <SwiperSlide className="feature-slider-card1">
          {" "}
          <img src={require("../Image/New-Home/dex-wallet.png")} />
          <h3> Dex Wallet </h3>
          <p>
            Select a Metamask wallet option and generate the checkout link to
            send to your clients. This allows them to make payments from their
            dex wallets.
          </p>
        </SwiperSlide>
        <SwiperSlide>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
